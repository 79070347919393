import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_AGENCY_AGENT_COUNT = "GET_AGENCY_AGENT_COUNT";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const agencyAgentsCountsSlice = createSlice({
  name: "agencyAgentsCounts",
  initialState,
  reducers: {
    agencyAgentsCountsStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    agencyAgentsCountsSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    agencyAgentsCountsError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetagencyAgentsCountsState: () => initialState,
  },
});

export const {
  agencyAgentsCountsStart,
  agencyAgentsCountsSuccess,
  agencyAgentsCountsError,
  resetagencyAgentsCountsState,
} = agencyAgentsCountsSlice.actions;

export const agencyAgentsCounts = (state: RootState) => state.agencyAgentsCounts;
export default agencyAgentsCountsSlice.reducer;
