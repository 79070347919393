import {
  sendProposalStart,
  sendProposalSuccess,
  sendProposalError,
  SEND_PROPOSAL,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* sendProposalRequest(actions: FinancialInfoActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(sendProposalStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(sendProposalSuccess(response.data));
  } catch (error: any) {
    yield put(sendProposalError(error));
  }
}
export default function* sendProposalSaga() {
  yield all([takeLatest(SEND_PROPOSAL, sendProposalRequest)]);
}
