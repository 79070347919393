import { payload } from "app/utils/types";

import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  GET_TRANSACTION_LEDGER,
  POST_TRANSACTION_LEDGER,
  fetchTransactionLedgerError,
  fetchTransactionLedgerLoading,
  fetchTransactionLedgerSuccess,
  postTransactionLedgerError,
  postTransactionLedgerLoading,
  postTransactionLedgerSuccess,
} from "app/redux/features/policyReview";

function* getTransactionLedger(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchTransactionLedgerLoading());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchTransactionLedgerSuccess(response));
  } catch (error: any) {
    yield put(fetchTransactionLedgerError(error));
  }
}

function* postTransactionLedger(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;
    yield put(postTransactionLedgerLoading());
    const response = yield call(() => makeRequest(path).post(body));
    yield put(postTransactionLedgerSuccess(response));
  } catch (error: any) {
    yield put(postTransactionLedgerError(error));
  }
}

function* watchPendingSagas() {
  yield takeLatest(GET_TRANSACTION_LEDGER, getTransactionLedger);
  yield takeLatest(POST_TRANSACTION_LEDGER, postTransactionLedger);
}

export default function* policyReviewSaga() {
  yield all([watchPendingSagas()]);
}
