import { put, takeLatest } from "redux-saga/effects";
import { TRIGGER_STORE_DASHBOARD_DATA, setAllDasboardSuccess } from "app/redux/features/dashboard";

function* storeDashboardData(action: any) {
  const { body } = action.payload;
  yield put(setAllDasboardSuccess(body));
}
export function* watchStoreDashboard() {
  yield takeLatest(TRIGGER_STORE_DASHBOARD_DATA, storeDashboardData);
}
