import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const PUT_VIEW_LOAN_DETAILS = "PUT_VIEW_LOAN_DETAILS";

interface LoanDetailsPutInterface {
  data?: object | any;
  loading?: boolean;
  error?: object | any;
}

const initialState: LoanDetailsPutInterface = {
  data: {},
  loading: false,
  error: {},
};

export const putViewLoanDetailsSlice = createSlice({
  name: "putViewLoanDetails",
  initialState,
  reducers: {
    putViewLoanDetailsStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    putViewLoanDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    putViewLoanDetailsError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetPutViewLoanDetailsState: () => initialState,
  },
});

export const {
  putViewLoanDetailsStart,
  putViewLoanDetailsSuccess,
  putViewLoanDetailsError,
  resetPutViewLoanDetailsState,
} = putViewLoanDetailsSlice.actions;

export const putViewLoanDetails = (state: RootState) => state.putViewLoanDetails;
export default putViewLoanDetailsSlice.reducer;
