import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface State {
  data: any;
  error: any;
  loading: any;
}

const initialState: State = {
  data: "",
  error: "",
  loading: "",
};

export const getAllPendingAgents = createSlice({
  name: "getAllPendingAgents",
  initialState,
  reducers: {
    setGetAllPendingAgentsSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = false;
    },
    setGetAllPendingAgentsFailure: (state, action: PayloadAction<string>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = true;
    },
    setGetAllPendingAgentsOngoing: (state) => {
      state.data = null;
      state.loading = true;
      state.error = false;
    },
  },
});

export const {
  setGetAllPendingAgentsSuccess,
  setGetAllPendingAgentsFailure,
  setGetAllPendingAgentsOngoing,
} = getAllPendingAgents.actions;
export const getAllPendingAgentsStatus = (state: RootState) => state.getAllPendingAgents;

export const pendingAgentsReducer = getAllPendingAgents.reducer;
