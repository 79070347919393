import {
  TRIGGER_GET_AGENCY_COMMISSION,
  TRIGGER_APPROVE_AGENCY_COMMISSION,
  agencyCommissionSuccess,
  agencyCommissionError,
  agencyCommissionLoading,
  agencyApproveCommissionSuccess,
  agencyApproveCommissionLoading,
  agencyApproveCommissionError,
  TRIGGER_GET_AGENCY_COMMISSION_USER,
  agencyCommissionUserLoading,
  agencyCommissionUserSuccess,
  agencyCommissionUserError,
  agencyDeleteCommissionLoading,
  agencyDeleteCommissionSuccess,
  agencyDeleteCommissionError,
  TRIGGER_DELETE_AGENCY_COMMISSION,
  agencyPostCommissionLoading,
  agencyPostCommissionSuccess,
  agencyPostCommissionError,
  TRIGGER_POST_AGENCY_COMMISSION,
} from "app/redux/features/agency-commission";
import { makeRequest } from "app/utils/makeRequest";
import { CommissionActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getAgencyCommission(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(agencyCommissionLoading());

    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));

    yield put(agencyCommissionSuccess(response.data));
  } catch (error: any) {
    yield put(agencyCommissionError(error));
  }
}

function* approveAgencyCommission(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(agencyApproveCommissionLoading());

    const response = yield call(() => makeRequest(path).post(requestBody));

    yield put(agencyApproveCommissionSuccess(response.data));
  } catch (error: any) {
    yield put(agencyApproveCommissionError(error));
  }
}

function* getAgencyCommissionUser(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(agencyCommissionUserLoading());

    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    const insuranceData = response.data.insuranceData.filter((el: any) => {
      const idx = el.product.findIndex((p: any) => {
        const jdx = p.commission.findIndex((c: any) =>
          ["pending", "updated"].includes(c.approvalStatus),
        );
        return jdx >= 0;
      });
      return idx >= 0;
    });

    yield put(agencyCommissionUserSuccess({ insuranceData }));
  } catch (error: any) {
    yield put(agencyCommissionUserError(error));
  }
}

function* deleteAgencyCommission(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(agencyDeleteCommissionLoading());

    const response = yield call(() => makeRequest(path).delete(key, requestParams, queryParams));

    yield put(agencyDeleteCommissionSuccess(response.data));
  } catch (error: any) {
    yield put(agencyDeleteCommissionError(error));
  }
}

function* postAgencyCommission(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body: requestBody, requestParams, queryParams },
    } = actions;
    yield put(agencyPostCommissionLoading());

    const response = yield call(() =>
      makeRequest(path).post(requestBody, key, requestParams, queryParams),
    );

    yield put(agencyPostCommissionSuccess(response.data));
  } catch (error: any) {
    yield put(agencyPostCommissionError(error));
  }
}

export default function* agencyCommissionSaga() {
  yield all([takeLatest(TRIGGER_GET_AGENCY_COMMISSION, getAgencyCommission)]);
  yield all([takeLatest(TRIGGER_APPROVE_AGENCY_COMMISSION, approveAgencyCommission)]);
  yield all([takeLatest(TRIGGER_GET_AGENCY_COMMISSION_USER, getAgencyCommissionUser)]);
  yield all([takeLatest(TRIGGER_DELETE_AGENCY_COMMISSION, deleteAgencyCommission)]);
  yield all([takeLatest(TRIGGER_POST_AGENCY_COMMISSION, postAgencyCommission)]);
}
