import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_SEND_FINANCIAL_INFO = "TRIGGER_SEND_FINANCIAL_INFO";

interface State {
  data: any;
  error: any;
  loading: any;
}

const initialState: State = {
  data: {},
  error: {},
  loading: false,
};

export const sendFinancialInfo = createSlice({
  name: "sendFinancialInfo",
  initialState,
  reducers: {
    setSendFinancialInfoSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = {};
    },
    setSendFinancialInfoFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.data = {};
    },
    setSendFinancialInfoOngoing: (state) => {
      state.data = {};
      state.loading = true;
      state.error = {};
    },
    resetSendFinancialInfo: (state) => {
      state.data = {};
      state.loading = false;
      state.error = {};
    },
  },
});

export const {
  setSendFinancialInfoSuccess,
  setSendFinancialInfoFailure,
  setSendFinancialInfoOngoing,
  resetSendFinancialInfo,
} = sendFinancialInfo.actions;
export const getSendFinancialInfoStatus = (state: RootState) => state.sendFinancialInfo;

export const sendFinancialInfoReducer = sendFinancialInfo.reducer;
