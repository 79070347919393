import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";
import { clearSessionStore } from "app/utils/sessionStore";

export const TRIGGER_LOGOUT_REQUEST = "TRIGGER_LOGOUT_REQUEST";

interface SignInInterface {
  logoutSuccess: object;
  logoutRequestError: object;
}

const initialState: SignInInterface = {
  logoutSuccess: {},
  logoutRequestError: {},
};

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutSucess: (state, action: PayloadAction<object>) => {
      state.logoutSuccess = action.payload;
      clearSessionStore();
    },
    logoutRequestError: (state, action: PayloadAction<object>) => {
      state.logoutRequestError = action.payload;
    },
    resetLogoutState: (state) => {
      state.logoutSuccess = {};
      state.logoutRequestError = {};
    },
  },
});

export const { logoutSucess, logoutRequestError, resetLogoutState } = logoutSlice.actions;
export const logoutResponseState = (state: RootState) => state.logout;

export default logoutSlice.reducer;
