import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_PLANS_SUMMARY_REQUEST = "TRIGGER_PLANS_SUMMARY_REQUEST";
export const TRIGGER_PLANS_SUMMARY_CARD_DATA = "TRIGGER_PLANS_SUMMARY_CARD_DATA";
export const TRIGGER_PLAN_DETAILS_EDIT = "TRIGGER_PLAN_DETAILS_EDIT";
export const TRIGGER_POLICY_DETAILS_EDIT = "TRIGGER_POLICY_DETAILS_EDIT";
export const TRIGGER_PLANS_EFFECTIVE_DATE = "TRIGGER_PLANS_EFFECTIVE_DATE";
export const TRIGGER_ADDITION_BENEFITS = "TRIGGER_ADDITION_BENEFITS";
export const TRIGGER_REQUEST_ADDITION_BENEFITS = "TRIGGER_REQUEST_ADDITION_BENEFITS";

interface CardData {
  data?: any;
  loading?: boolean;
  error?: string | null | object | any;
}

interface ResponseType {
  data: any;
  loading: boolean;
  error: string | null;
  fetchPlanSummaryCardDataStart: CardData;
  fetchPlanSummaryCardDataSuccess: CardData;
  fetchPlanSummaryCardDataFailure: CardData;
  postPlanInfoStart: CardData;
  postPlanInfoSuccess: CardData;
  postPlanInfoFailure: CardData;
  postPolicyInfoStart: CardData;
  postPolicyInfoSuccess: CardData;
  postPolicyInfoFailure: CardData;
  updatePlanEffectiveDateStart: CardData;
  updatePlanEffectiveDateSuccess: CardData;
  updatePlanEffectiveDateFailure: CardData;
  postAdditionBenefitsStart: CardData;
  postAdditionBenefitsSuccess: CardData;
  postAdditionBenefitsFailure: CardData;
  postRequestAdditionBenefitsStart: CardData;
  postRequestAdditionBenefitsSuccess: CardData;
  postRequestAdditionBenefitsFailure: CardData;
}

const initialState: ResponseType = {
  data: {},
  loading: false,
  error: null,
  fetchPlanSummaryCardDataStart: { data: {}, error: {}, loading: false },
  fetchPlanSummaryCardDataSuccess: { data: {}, error: {}, loading: false },
  fetchPlanSummaryCardDataFailure: { data: {}, error: {}, loading: false },
  postPlanInfoStart: { data: {}, error: {}, loading: false },
  postPlanInfoSuccess: { data: {}, error: {}, loading: false },
  postPlanInfoFailure: { data: {}, error: {}, loading: false },
  postPolicyInfoStart: { data: {}, error: {}, loading: false },
  postPolicyInfoSuccess: { data: {}, error: {}, loading: false },
  postPolicyInfoFailure: { data: {}, error: {}, loading: false },
  updatePlanEffectiveDateStart: { data: {}, error: {}, loading: false },
  updatePlanEffectiveDateSuccess: { data: {}, error: {}, loading: false },
  updatePlanEffectiveDateFailure: { data: {}, error: {}, loading: false },
  postAdditionBenefitsStart: { data: {}, error: {}, loading: false },
  postAdditionBenefitsSuccess: { data: {}, error: {}, loading: false },
  postAdditionBenefitsFailure: { data: {}, error: {}, loading: false },
  postRequestAdditionBenefitsStart: { data: {}, error: {}, loading: false },
  postRequestAdditionBenefitsSuccess: { data: {}, error: {}, loading: false },
  postRequestAdditionBenefitsFailure: { data: {}, error: {}, loading: false },
};

export const plansSummaryListSlice = createSlice({
  name: "plansummary",
  initialState,
  reducers: {
    fetchPlanSummaryDataStart: (state) => {
      state.data = {};
      state.loading = true;
      state.error = null;
    },
    fetchPlanSummaryDataSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchPlanSummaryDataFailure: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.loading = false;
      state.error = action.payload;
    },
    fetchPlanSummaryCardDataStart: (state) => {
      state.fetchPlanSummaryCardDataStart = { data: {}, error: {}, loading: true };
    },
    fetchPlanSummaryCardDataSuccess: (state, action: PayloadAction<any>) => {
      state.fetchPlanSummaryCardDataSuccess = { data: action.payload, error: {}, loading: false };
    },
    fetchPlanSummaryCardDataFailure: (state, action: PayloadAction<any>) => {
      state.fetchPlanSummaryCardDataFailure = { data: {}, error: action.payload, loading: false };
    },
    postPlanInfoStart: (state) => {
      state.postPlanInfoStart = { data: {}, error: {}, loading: true };
    },
    postPlanInfoSuccess: (state, action: PayloadAction<any>) => {
      state.postPlanInfoSuccess = { data: action.payload, error: {}, loading: false };
    },
    postPlanInfoFailure: (state, action: PayloadAction<any>) => {
      state.postPlanInfoFailure = { data: {}, error: action.payload, loading: false };
    },
    postPolicyInfoStart: (state) => {
      state.postPolicyInfoStart = { data: {}, error: {}, loading: true };
    },
    postPolicyInfoSuccess: (state, action: PayloadAction<any>) => {
      state.postPolicyInfoSuccess = { data: action.payload, error: {}, loading: false };
    },
    postPolicyInfoFailure: (state, action: PayloadAction<any>) => {
      state.postPolicyInfoFailure = { data: {}, error: action.payload, loading: false };
    },
    updatePlanEffectiveDateStart: (state) => {
      state.updatePlanEffectiveDateStart = { data: {}, error: {}, loading: true };
    },
    updatePlanEffectiveDateSuccess: (state, action: PayloadAction<any>) => {
      state.updatePlanEffectiveDateSuccess = { data: action.payload, error: {}, loading: false };
    },
    updatePlanEffectiveDateFailure: (state, action: PayloadAction<any>) => {
      state.updatePlanEffectiveDateFailure = { data: {}, error: action.payload, loading: false };
    },
    resetPlanSummaryData: (state) => {
      state.data = {};
      state.loading = false;
      state.error = null;
    },
    resetPlanSummaryCardData: (state) => {
      state.fetchPlanSummaryCardDataStart = { data: {}, error: {}, loading: false };
      state.fetchPlanSummaryCardDataSuccess = { data: {}, error: {}, loading: false };
      state.fetchPlanSummaryCardDataFailure = { data: {}, error: {}, loading: false };
    },
    resetPlanUpdatedStore: (state) => {
      state.postPlanInfoStart = { data: {}, error: {}, loading: false };
      state.postPlanInfoSuccess = { data: {}, error: {}, loading: false };
      state.postPlanInfoFailure = { data: {}, error: {}, loading: false };
    },
    resetPolicyUpdatedStore: (state) => {
      state.postPolicyInfoStart = { data: {}, error: {}, loading: false };
      state.postPolicyInfoSuccess = { data: {}, error: {}, loading: false };
      state.postPolicyInfoFailure = { data: {}, error: {}, loading: false };
    },
    updatePlanEffectiveDateStore: (state) => {
      state.updatePlanEffectiveDateStart = { data: {}, error: {}, loading: false };
      state.updatePlanEffectiveDateSuccess = { data: {}, error: {}, loading: false };
      state.updatePlanEffectiveDateFailure = { data: {}, error: {}, loading: false };
    },
    postAdditionBenefitsStart: (state) => {
      state.postAdditionBenefitsStart = { data: {}, error: {}, loading: true };
    },
    postAdditionBenefitsSuccess: (state, action: PayloadAction<any>) => {
      state.postAdditionBenefitsSuccess = { data: action.payload, error: {}, loading: false };
    },
    postAdditionBenefitsFailure: (state, action: PayloadAction<any>) => {
      state.postAdditionBenefitsFailure = { data: {}, error: action.payload, loading: false };
    },
    postRequestAdditionBenefitsStart: (state) => {
      state.postRequestAdditionBenefitsStart = { data: {}, error: {}, loading: true };
    },
    postRequestAdditionBenefitsSuccess: (state, action: PayloadAction<any>) => {
      state.postRequestAdditionBenefitsSuccess = {
        data: action.payload,
        error: {},
        loading: false,
      };
    },
    postRequestAdditionBenefitsFailure: (state, action: PayloadAction<any>) => {
      state.postRequestAdditionBenefitsFailure = {
        data: {},
        error: action.payload,
        loading: false,
      };
    },
    resetAddedBenefitsState: (state) => {
      state.postRequestAdditionBenefitsStart = { data: {}, error: {}, loading: false };
      state.postRequestAdditionBenefitsSuccess = { data: {}, error: {}, loading: false };
      state.postRequestAdditionBenefitsFailure = { data: {}, error: {}, loading: false };
    },
    resetMarkedBenefitsState: (state) => {
      state.postAdditionBenefitsStart = { data: {}, error: {}, loading: false };
      state.postAdditionBenefitsSuccess = { data: {}, error: {}, loading: false };
      state.postAdditionBenefitsFailure = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchPlanSummaryDataStart,
  fetchPlanSummaryDataSuccess,
  fetchPlanSummaryDataFailure,
  fetchPlanSummaryCardDataStart,
  fetchPlanSummaryCardDataSuccess,
  fetchPlanSummaryCardDataFailure,
  updatePlanEffectiveDateStart,
  updatePlanEffectiveDateSuccess,
  updatePlanEffectiveDateFailure,
  updatePlanEffectiveDateStore,
  postPlanInfoStart,
  postPlanInfoSuccess,
  postPlanInfoFailure,
  postPolicyInfoStart,
  postPolicyInfoSuccess,
  postPolicyInfoFailure,
  resetPolicyUpdatedStore,
  resetPlanUpdatedStore,
  resetPlanSummaryData,
  resetPlanSummaryCardData,
  postAdditionBenefitsFailure,
  postAdditionBenefitsStart,
  postAdditionBenefitsSuccess,
  postRequestAdditionBenefitsStart,
  postRequestAdditionBenefitsSuccess,
  postRequestAdditionBenefitsFailure,
  resetAddedBenefitsState,
  resetMarkedBenefitsState,
} = plansSummaryListSlice.actions;

export const getPlanSummary = (state: RootState) => state.planSummary;

export default plansSummaryListSlice.reducer;
