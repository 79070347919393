import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_LOAN_PLAN_PROJECTIONS = "GET_LOAN_PLAN_PROJECTIONS";
interface LoanPlanProjectionInterface {
  loading: boolean;
  data: any;
  error: Record<string, string | number | object> | null;
}

const initialState: LoanPlanProjectionInterface = {
  loading: false,
  data: [],
  error: {},
};

export const LoanPlanProjectionSlice = createSlice({
  name: "loanPlanProjection",
  initialState,
  reducers: {
    getLoanPlanProjectionStart: (state) => {
      state.data = [];
      state.error = {};
      state.loading = true;
    },
    getLoanPlanProjectionSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    getLoanPlanProjectionError: (state, action: PayloadAction<any>) => {
      state.data = [];
      state.error = action.payload;
      state.loading = false;
    },
    resetgetLoanPlanProjectionState: () => initialState,
  },
});

export const {
  getLoanPlanProjectionStart,
  getLoanPlanProjectionSuccess,
  getLoanPlanProjectionError,
  resetgetLoanPlanProjectionState,
} = LoanPlanProjectionSlice.actions;

export const getLoanPlanProjectionData = (state: RootState) => state.LoanPlanProjection;

export default LoanPlanProjectionSlice.reducer;
