import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const CREATE_HEALTH_INFO = "CREATE_HEALTH_INFO";
export const UPDATE_HEALTH_INFO = "UPDATE_HEALTH_INFO";

interface HealthInfoType {
  data: object;
  error: object;
  pending: boolean;
}

const initialState: HealthInfoType = {
  data: {},
  error: {},
  pending: false,
};

export const contactHealthInfo = createSlice({
  name: "contactHealthInfo",
  initialState,
  reducers: {
    setContactHealthInfo: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.pending = false;
    },
    healthInfoError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.pending = false;
    },
    healthApiPending: (state) => {
      state.data = {};
      state.error = {};
      state.pending = true;
    },
    resetHealthState: () => initialState,
  },
});

export const { setContactHealthInfo, healthInfoError, healthApiPending, resetHealthState } =
  contactHealthInfo.actions;
export const createContactHealthInfo = (state: RootState) => state.contactHealthInfo;

export const contactHealthInfoReducer = contactHealthInfo.reducer;
