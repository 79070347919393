import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const DELETE_UPLOADED_DOCUMENT = "DELETE_UPLOADED_DOCUMENT";

interface initialStateInterface {
  loading?: boolean;
  error?: any;
  data?: any;
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const deleteUploadDocumentSlice = createSlice({
  name: "deleteUploadDocument",
  initialState,
  reducers: {
    deleteUploadDocumentStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    deleteUploadDocumentSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    deleteUploadDocumentError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetdeleteUploadDocumentState: () => initialState,
  },
});

export const {
  deleteUploadDocumentStart,
  deleteUploadDocumentSuccess,
  deleteUploadDocumentError,
  resetdeleteUploadDocumentState,
} = deleteUploadDocumentSlice.actions;

export const deleteUploadDocument = (state: RootState) => state.deleteUploadDocument;

export default deleteUploadDocumentSlice.reducer;
