import {
  TRIGGER_GET_ADMIN_AGENTS,
  fetchAdminAgentsStart,
  fetchAdminAgentsSuccess,
  fetchAdminAgentsFailure,
} from "app/redux/features/admin-agents-list";
import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* adminAgentsListRequest(actions: FinancialInfoActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(fetchAdminAgentsStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(fetchAdminAgentsSuccess(response.data));
  } catch (error: any) {
    yield put(fetchAdminAgentsFailure(error));
  }
}

export default function* adminAgentsSaga() {
  yield all([takeLatest(TRIGGER_GET_ADMIN_AGENTS, adminAgentsListRequest)]);
}
