import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_AGENCY_COMMISSION_REPORT_REQUEST = "TRIGGER_AGENCY_COMMISSION_REPORT_REQUEST";

export const triggerGetReport = (
  path: string,
  key?: string,
  isRequestParams?: boolean,
  isQueryParams?: boolean,
) => ({
  type: TRIGGER_AGENCY_COMMISSION_REPORT_REQUEST,
  payload: {
    address: path,
    key,
    isRequestParams,
    isQueryParams,
  },
});

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface RequestReportInterface {
  requestAgencyCommissionReports: InitialStateInterface;
}

const initialState: RequestReportInterface = {
  requestAgencyCommissionReports: { data: {}, error: {}, loading: false },
};

export const agencyCommissionReportSlice = createSlice({
  name: "agencyCommissionReport",
  initialState,
  reducers: {
    fetchAgencyCommissionReportStart: (state) => {
      state.requestAgencyCommissionReports = { data: {}, error: {}, loading: true };
    },
    fetchAgencyCommissionReportSuccess: (state, action: PayloadAction<any>) => {
      state.requestAgencyCommissionReports = { data: action.payload, error: {}, loading: false };
    },
    fetchAgencyCommissionReportError: (state, action: PayloadAction<any>) => {
      state.requestAgencyCommissionReports = { data: {}, error: action.payload, loading: false };
    },
    resetRequestReportState: () => initialState,
  },
});

export const {
  fetchAgencyCommissionReportStart,
  fetchAgencyCommissionReportSuccess,
  fetchAgencyCommissionReportError,
  resetRequestReportState,
} = agencyCommissionReportSlice.actions;
export const agencyCommissionReport = (state: RootState) => state.agencyCommissionReport;
export default agencyCommissionReportSlice.reducer;
