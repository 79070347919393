import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_SHARHOLDER_LEDGER = "GET_SHARHOLDER_LEDGER";
export const DELETE_SHARHOLDER_LEDGER = "DELETE_SHARHOLDER_LEDGER";
export const POST_SHARHOLDER_LEDGER = "POST_SHARHOLDER_LEDGER";
export const PUT_SHARHOLDER_LEDGER = "PUT_SHARHOLDER_LEDGER";
export const GET_PROPOSAL_PROGRESS_DATA = "GET_PROPOSAL_PROGRESS_DATA";

interface shareHolderResponse {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface responseType {
  getShareHolderResponse?: shareHolderResponse;
  deleteShareHolderResponse?: shareHolderResponse;
  postShareHolderResponse?: shareHolderResponse;
  putShareHolderResponse?: shareHolderResponse;
  getProgressResponse?: shareHolderResponse;
}

const initialState: responseType = {
  getShareHolderResponse: { data: {}, error: {}, loading: true },
  deleteShareHolderResponse: { data: {}, error: {}, loading: true },
  postShareHolderResponse: { data: {}, error: {}, loading: true },
  putShareHolderResponse: { data: {}, error: {}, loading: true },
  getProgressResponse: { data: {}, error: {}, loading: true },
};

export const shareHolderSlice = createSlice({
  name: "shareHolder",
  initialState,
  reducers: {
    getShareHolderStart: (state) => {
      state.getShareHolderResponse = { data: {}, error: {}, loading: true };
    },
    getShareHolderSuccess: (state, action: PayloadAction<responseType>) => {
      state.getShareHolderResponse = { data: action.payload, error: {}, loading: true };
    },
    getShareHolderError: (state, action: PayloadAction<responseType>) => {
      state.getShareHolderResponse = { data: {}, error: action.payload, loading: true };
    },
    resetGetShareHolder: (state) => {
      state.getShareHolderResponse = { data: {}, error: {}, loading: true };
    },
    postShareHolderStart: (state) => {
      state.postShareHolderResponse = { data: {}, error: {}, loading: true };
    },
    postShareHolderSuccess: (state, action: PayloadAction<responseType>) => {
      state.postShareHolderResponse = { data: action.payload, error: {}, loading: true };
    },
    postShareHolderError: (state, action: PayloadAction<responseType>) => {
      state.postShareHolderResponse = { data: {}, error: action.payload, loading: true };
    },
    resetpostShareHolder: (state) => {
      state.postShareHolderResponse = { data: {}, error: {}, loading: true };
    },
    putShareHolderStart: (state) => {
      state.putShareHolderResponse = { data: {}, error: {}, loading: true };
    },
    putShareHolderSuccess: (state, action: PayloadAction<responseType>) => {
      state.putShareHolderResponse = { data: action.payload, error: {}, loading: true };
    },
    putShareHolderError: (state, action: PayloadAction<responseType>) => {
      state.putShareHolderResponse = { data: {}, error: action.payload, loading: true };
    },
    resetPutShareHolder: (state) => {
      state.putShareHolderResponse = { data: {}, error: {}, loading: true };
    },
    deleteShareHolderStart: (state) => {
      state.deleteShareHolderResponse = { data: {}, error: {}, loading: true };
    },
    deleteShareHolderSuccess: (state, action: PayloadAction<responseType>) => {
      state.deleteShareHolderResponse = { data: action.payload, error: {}, loading: true };
    },
    deleteShareHolderError: (state, action: PayloadAction<responseType>) => {
      state.deleteShareHolderResponse = { data: {}, error: action.payload, loading: true };
    },
    resetDeleteShareHolder: (state) => {
      state.deleteShareHolderResponse = { data: {}, error: {}, loading: true };
    },
    // addtional to fetch propsoal progress and sharesholder data
    getProgressStart: (state) => {
      state.getProgressResponse = { data: {}, error: {}, loading: true };
    },
    getProgressSuccess: (state, action: PayloadAction<responseType>) => {
      state.getProgressResponse = { data: action.payload, error: {}, loading: true };
    },
    getProgressError: (state, action: PayloadAction<responseType>) => {
      state.getProgressResponse = { data: {}, error: action.payload, loading: true };
    },
    resetProgress: (state) => {
      state.getProgressResponse = { data: {}, error: {}, loading: true };
    },
  },
});

export const {
  getShareHolderError,
  getShareHolderStart,
  getShareHolderSuccess,
  postShareHolderError,
  postShareHolderStart,
  postShareHolderSuccess,
  putShareHolderError,
  putShareHolderStart,
  putShareHolderSuccess,
  deleteShareHolderStart,
  deleteShareHolderError,
  deleteShareHolderSuccess,
  resetpostShareHolder,
  resetDeleteShareHolder,
  resetPutShareHolder,
  resetGetShareHolder,
  getProgressError,
  getProgressStart,
  getProgressSuccess,
  resetProgress,
} = shareHolderSlice.actions;

export const shareHolderLedgerStore = (state: RootState) => state.shareholderLedgerSaga;

export default shareHolderSlice.reducer;
