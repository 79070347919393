import { makeRequest } from "app/utils/makeRequest";
import { payload } from "app/utils/types";
import {
  TRIGGER_AGENCY_COMMISSION_REPORT_REQUEST,
  fetchAgencyCommissionReportStart,
  fetchAgencyCommissionReportSuccess,
  fetchAgencyCommissionReportError,
  GET_REPORT,
  reportError,
  reportStart,
  reportSuccess,
} from "app/redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* reportRequest({ requestBody }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = requestBody;
    yield put(reportStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(reportSuccess(response.data));
  } catch (error: any) {
    yield put(reportError(error));
  }
}

function* agencyCommissionFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchAgencyCommissionReportStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchAgencyCommissionReportSuccess(response.data));
  } catch (error: any) {
    yield put(fetchAgencyCommissionReportError(error));
  }
}

function* watchReportSagas() {
  yield takeLatest(GET_REPORT, reportRequest);
  yield takeLatest(TRIGGER_AGENCY_COMMISSION_REPORT_REQUEST, agencyCommissionFetchRequest);
}

export default function* reportSaga() {
  yield all([watchReportSagas()]);
}
