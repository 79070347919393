import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const PENDING_DOCUMENT_LIST = "PENDING_DOCUMENT_LIST";

interface initialStateInterface {
  loading?: boolean;
  error?: any;
  data?: any;
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const pendingDocumentListSlice = createSlice({
  name: "pendingDocumentList",
  initialState,
  reducers: {
    pendingDocumentListStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    pendingDocumentListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    pendingDocumentListError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetpendingDocumentListState: () => initialState,
  },
});

export const {
  pendingDocumentListStart,
  pendingDocumentListSuccess,
  pendingDocumentListError,
  resetpendingDocumentListState,
} = pendingDocumentListSlice.actions;

export const pendingDocumentList = (state: RootState) => state.pendingDocumentList;

export default pendingDocumentListSlice.reducer;
