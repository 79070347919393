import { put, takeLatest } from "redux-saga/effects";
import {
  BS_BASICINFO_STORE_DATA,
  BS_DESIGN_DATA,
  BS_SHAREHOLDER_STORE_DATA,
  getBSBasicInfo,
  getShareholderInfo,
  getDesignInfo,
} from "@reducers";

function* storeBSBasicInfoSaga(action: any) {
  const { body } = action.payload;
  yield put(getBSBasicInfo(body));
}

function* storeBSShareholderInfoSaga(action: any) {
  const { body } = action.payload;
  yield put(getShareholderInfo(body));
}

function* storeBSDesignInfo(action: any) {
  const { body } = action.payload;
  yield put(getDesignInfo(body));
}

function* requestProposalSaga() {
  yield takeLatest(BS_BASICINFO_STORE_DATA, storeBSBasicInfoSaga);
  yield takeLatest(BS_DESIGN_DATA, storeBSDesignInfo);
  yield takeLatest(BS_SHAREHOLDER_STORE_DATA, storeBSShareholderInfoSaga);
}

export default requestProposalSaga;
