import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const TRIGGER_REVOKE_CONTACT_REQUEST = "TRIGGER_REVOKE_CONTACT_REQUEST";
export const TRIGGER_REVOKE_CONTACT_SUCCESS = "TRIGGER_REVOKE_CONTACT_SUCCESS";
export const TRIGGER_REVOKE_CONTACT_FAILURE = "TRIGGER_REVOKE_CONTACT_FAILURE";

interface RevokeData {
  loading: boolean;
  data: any;
  error: any;
}

const initialState: RevokeData = {
  loading: false,
  data: {},
  error: {},
};

export const revokeProposalSlice = createSlice({
  name: "revokeProposal",
  initialState,
  reducers: {
    revokeProposalSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    revokeProposalError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    revokeProposalsOngoing: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    resetsRevokeState: () => initialState,
  },
});

export const {
  revokeProposalSuccess,
  resetsRevokeState,
  revokeProposalError,
  revokeProposalsOngoing,
} = revokeProposalSlice.actions;
export const revokeProposalContactById = (state: RootState) => state.revokeContactById;

export default revokeProposalSlice.reducer;
