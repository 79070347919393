import { ThemeProvider } from "styled-components";
import React, { ReactNode } from "react";

interface ThemeProps {
  children: ReactNode;
}

const iAppTheme = {
  colors: {
    primaryBlue: "#3B82F6",
    secondayBlue: "#1581ED",
    lightBlue: "#BFDBFE",

    primaryGreen: "#188A42",
    secondayGreen: "#1DA750",
    logoColor: "#0A3343",

    primaryRed: "#E24C4C",
    primaryOrange: "#FF9F42",

    primaryBlack: "#212121",
    secondaryBlack: "#000000",
    textColorDark: "#495057",
    textColorLight: "#6C757D",
    lightGrey: "#CED4DA",
    lightGreyDisabled: "#BDBDBD",
  },
  opacity: {
    disabled: 0.6,
  },
  fontWeight: {
    extraBold: 800,
    bold: 700,
    semiBold: 600,
    normal: 400,
  },
  fonstSize: {
    large: "35px",
    heading: "28px",
    big: "24.5px",
    small: "14px",
    extraSmall: "10.5px",
  },
};

function Theme({ children }: ThemeProps) {
  return <ThemeProvider theme={iAppTheme}>{children}</ThemeProvider>;
}

export default Theme;
