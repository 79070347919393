import {
  COPY_ROW_DATA,
  editRowData,
  fetchDataFailure,
  fetchDataStart,
  fetchDataSuccess,
  getPlanAdminSplitDollarList,
  RESET_SAVE_DATA_RESPONSE,
  resetSaveDataResponseState,
  saveData,
  TRIGGER_EDIT_PLAN_ADMIN_SPLIT_DOLLAR_CELL,
  TRIGGER_PLAN_ADMIN_SPLIT_DOLLAR_LIST_REQUEST,
  TRIGGER_SAVE_PLAN_ADMIN_SPLIT_DOLLAR,
  updateCopiedData,
} from "app/redux/features/plan-admin-split-dollar";
import {
  AdminSplitDollarActionPayload,
  EditCellAdminSplitDollarActionPayload,
  FinancialInfoActionPayload,
} from "app/utils/types";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { prepareRows } from "app/Data/PlanAdminSplitDollarDataJson";
import { getPolicyInputBaseCSVData } from "app/redux/features/policy-input-base-csv-data";
import { clone } from "ramda";
import { getCreateProposalContactInfo } from "app/redux/features/createProposal";

function* planAdminSplitDollarListRequest(
  actions: FinancialInfoActionPayload,
): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(fetchDataStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(fetchDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchDataFailure(error));
  }
}

function* savePlanAdminSplitDollarData(
  actions: AdminSplitDollarActionPayload,
): Generator<any, void, any> {
  const store = yield select(getPlanAdminSplitDollarList);
  try {
    const {
      payload: { address: path, body },
    } = actions;
    const response = yield call(() =>
      makeRequest(path).put("", {
        ...body,
        planAdminSDRows: JSON.stringify(store.data?.planAdminSDData?.rows ?? []),
        planSDRows: JSON.stringify(store.newSDDataArr ?? []),
      }),
    );
    yield put(saveData(response.data));
  } catch (error: any) {
    yield put(saveData(error));
  }
}

function* editCellPlanAdminSplitDollar(
  actions: EditCellAdminSplitDollarActionPayload,
): Generator<any, void, any> {
  const {
    payload: { key, rowIdx, value },
  } = actions;
  const store = yield select(getPlanAdminSplitDollarList);
  const policyInputCSVData = yield select(getPolicyInputBaseCSVData);
  const createProposalContactData = yield select(getCreateProposalContactInfo);
  const { createProposalOtherDetailsSuccess } = createProposalContactData;
  const { splitDollarRowArr, updatedRows } = prepareRows(
    clone(store.data?.planAdminSDData?.rows),
    key,
    value,
    rowIdx,
    store.data.planSDSchema,
    policyInputCSVData.data?.rows,
    createProposalOtherDetailsSuccess.data.data.accrueInterest,
  );

  yield put(
    editRowData({
      rowIdx,
      splitDollarRowArr,
      updatedRows,
    }),
  );
}

function* resetSaveDataState() {
  yield put(resetSaveDataResponseState());
}

function* copyRowData(actions: {
  type: string;
  data: { [key: string]: string | number };
}): Generator<any, void, any> {
  yield put(updateCopiedData(actions.data));
}

export default function* planAdminSplitDollarSaga() {
  yield all([
    takeLatest(TRIGGER_PLAN_ADMIN_SPLIT_DOLLAR_LIST_REQUEST, planAdminSplitDollarListRequest),
    takeLatest(TRIGGER_SAVE_PLAN_ADMIN_SPLIT_DOLLAR, savePlanAdminSplitDollarData),
    takeLatest(TRIGGER_EDIT_PLAN_ADMIN_SPLIT_DOLLAR_CELL, editCellPlanAdminSplitDollar),
    takeLatest(RESET_SAVE_DATA_RESPONSE, resetSaveDataState),
    takeLatest(COPY_ROW_DATA, copyRowData),
  ]);
}
