import {
  setGetResendResetPassOngoing,
  setGetResendResetPassSuccess,
  setGetResendResetPassFailure,
} from "app/redux";
import { TRIGGER_RESEND_RESET_PASSWORD } from "app/redux/features/resend-reset-password/action";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* resendResetPass(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setGetResendResetPassOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setGetResendResetPassSuccess(response.data));
  } catch (e: any) {
    yield put(setGetResendResetPassFailure(e.message));
  }
}

function* watchResendResetPass() {
  yield takeLatest(TRIGGER_RESEND_RESET_PASSWORD, resendResetPass);
}

export default function* resendResetPassSaga() {
  yield all([watchResendResetPass()]);
}
