import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const POLICY_INPUTBASE_CSVUPLOAD = "POLICY_INPUTBASE_CSVUPLOAD";
interface intialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

const initialState: intialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

export const patchCSVUpload = createSlice({
  name: "patchCSVUpload",
  initialState,
  reducers: {
    patchCSVUploadStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    patchCSVUploadSucess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    patchCSVUploadError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetPatchCSVUploadState: () => initialState,
  },
});

export const {
  patchCSVUploadStart,
  patchCSVUploadSucess,
  patchCSVUploadError,
  resetPatchCSVUploadState,
} = patchCSVUpload.actions;

export const postPolicyInputBaseCSVUpload = (state: RootState) => state.policyInputBaseCSVUpload;

export default patchCSVUpload.reducer;
