import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_SIGNIN_REQUEST = "TRIGGER_API_REQUEST_POST";
export const TRIGGER_SIGNUP_REQUEST = "TRIGGER_SIGNUP_API_REQUEST_POST";

interface LoginSuccess {
  access_Token?: string;
  verifyToken?: string;
  status?: string;
  error?: string;
  message?: string;
  token?: string;
}

interface SignInInterface {
  verifyToken?: LoginSuccess;
  signInError?: any;
  loginCred?: LoginCred;
}

const initialState: SignInInterface = {
  verifyToken: {},
  signInError: {},
  loginCred: { email: "", password: "" },
};

interface LoginCred {
  email: string;
  password: string;
}

const tokenSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<LoginSuccess>) => {
      state.verifyToken = action.payload;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.signInError = action.payload;
    },
    resetLoginState: (state) => {
      state.verifyToken = {};
      state.signInError = {};
    },
    resetLoginCred: (state) => {
      state.loginCred = { email: "", password: "" };
    },
    setLoginState: (state, action: PayloadAction<LoginCred>) => {
      state.loginCred = { email: action.payload.email, password: action.payload.password };
    },
  },
});

export const { setToken, setError, resetLoginState, setLoginState, resetLoginCred } =
  tokenSlice.actions;
export const getToken = (state: RootState) => state.token;

export default tokenSlice.reducer;
