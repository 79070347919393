import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface State {
  data: any;
  error: any;
  loading: any;
}

const initialState: State = {
  data: {},
  error: {},
  loading: "",
};

export const getAllAgencies = createSlice({
  name: "getAllAgencies",
  initialState,
  reducers: {
    setGetAllAgenciesSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
    },
    setGetAllAgenciesFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setGetAllAgenciesOngoing: (state) => {
      state.data = {};
      state.loading = true;
      state.error = {};
    },
  },
});

export const { setGetAllAgenciesSuccess, setGetAllAgenciesFailure, setGetAllAgenciesOngoing } =
  getAllAgencies.actions;
export const getAllAgenciesStatus = (state: RootState) => state.getAllAgencies;

export const allAgenciesReducer = getAllAgencies.reducer;
