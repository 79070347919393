import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const TRIGGER_GET_PROVISION_DETAILS = "TRIGGER_GET_PROVISION_DETAILS";
export const TRIGGER_SAVE_PROVISION_DETAILS = "TRIGGER_SAVE_PROVISION_DETAILS";

interface ProvisionData {
  provisionLoading: boolean;
  provisionData: any;
  provisionError: any;
  saveProvisionloading: boolean;
  saveProvisionData: any;
  saveProvisionError: any;
}

const initialState: ProvisionData = {
  provisionLoading: false,
  provisionData: {},
  provisionError: {},
  saveProvisionloading: false,
  saveProvisionData: {},
  saveProvisionError: {},
};

export const provisionSlice = createSlice({
  name: "provisionDetails",
  initialState,
  reducers: {
    getProvisionSuccess: (state, action: PayloadAction<any>) => {
      state.provisionData = action.payload;
      state.provisionError = {};
      state.provisionLoading = false;
    },
    getProvisionError: (state, action: PayloadAction<any>) => {
      state.provisionData = {};
      state.provisionError = action.payload;
      state.provisionLoading = false;
    },
    getProvisionOngoing: (state) => {
      state.provisionData = {};
      state.provisionError = {};
      state.provisionLoading = true;
    },
    saveProvisionSuccess: (state, action: PayloadAction<any>) => {
      state.saveProvisionData = action.payload;
      state.saveProvisionError = {};
      state.saveProvisionloading = false;
    },
    saveProvisionError: (state, action: PayloadAction<any>) => {
      state.saveProvisionData = {};
      state.saveProvisionError = action.payload;
      state.saveProvisionloading = false;
    },
    saveProvisionOngoing: (state) => {
      state.saveProvisionData = {};
      state.saveProvisionError = {};
      state.saveProvisionloading = true;
    },
    resetsProvision: () => initialState,
  },
});

export const {
  resetsProvision,
  getProvisionSuccess,
  getProvisionError,
  getProvisionOngoing,
  saveProvisionOngoing,
  saveProvisionError,
  saveProvisionSuccess,
} = provisionSlice.actions;
export const fetchProvisionDetails = (state: RootState) => state.provisionDetails;

export default provisionSlice.reducer;
