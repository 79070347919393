import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const TRIGGER_GET_AFR_DETAILS = "TRIGGER_GET_AFR_DETAILS";
export const TRIGGER_SAVE_AFR_DETAILS = "TRIGGER_SAVE_AFR_DETAILS";

interface AfrData {
  loading: boolean;
  data: any;
  error: any;
  saveAfrloading: boolean;
  saveAfrData: any;
  saveAfrError: any;
}

const initialState: AfrData = {
  loading: false,
  data: {},
  error: {},
  saveAfrloading: false,
  saveAfrData: {},
  saveAfrError: {},
};

export const afrSlice = createSlice({
  name: "afrDetails",
  initialState,
  reducers: {
    getAfrSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    getAfrError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    getAfrOngoing: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    saveAfrSuccess: (state, action: PayloadAction<any>) => {
      state.saveAfrData = action.payload;
      state.saveAfrError = {};
      state.saveAfrloading = false;
    },
    saveAfrError: (state, action: PayloadAction<any>) => {
      state.saveAfrData = {};
      state.saveAfrError = action.payload;
      state.saveAfrloading = false;
    },
    saveAfrOngoing: (state) => {
      state.saveAfrData = {};
      state.saveAfrError = {};
      state.saveAfrloading = true;
    },
    resetsAfr: () => initialState,
  },
});

export const {
  resetsAfr,
  getAfrSuccess,
  getAfrError,
  getAfrOngoing,
  saveAfrOngoing,
  saveAfrError,
  saveAfrSuccess,
} = afrSlice.actions;
export const fetchAfrDetails = (state: RootState) => state.afrdetails;

export default afrSlice.reducer;
