import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import {
  TRIGGER_BASE_LOAN_TERMS,
  TRIGGER_LOANS_SUMMARY_REQUEST,
  fetchLoanSummaryDataFailure,
  fetchLoanSummaryDataStart,
  fetchLoanSummaryDataSuccess,
  postBaseLoanTermFailure,
  postBaseLoanTermStart,
  postBaseLoanTermSuccess,
} from "app/redux";

interface ActionPayload {
  type: string;
  payload: {
    address: string;
    key: string;
    body?: any;
    requestParams?: boolean;
    queryParams?: boolean;
  };
}

function* getloanSummary(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, requestParams, queryParams },
    } = actions;
    yield put(fetchLoanSummaryDataStart());
    const response = yield call(() => makeRequest(path).get(type, requestParams, queryParams));
    yield put(fetchLoanSummaryDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchLoanSummaryDataFailure(error));
  }
}

function* putBasedLoanTermsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(postBaseLoanTermStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(postBaseLoanTermSuccess(response.data));
  } catch (error: any) {
    yield put(postBaseLoanTermFailure(error));
  }
}

function* watchFetchAllLoanSummary() {
  yield takeLatest(TRIGGER_LOANS_SUMMARY_REQUEST, getloanSummary);
  yield takeLatest(TRIGGER_BASE_LOAN_TERMS, putBasedLoanTermsRequest);
}

export default function* watchAllLoanSummarySaga() {
  yield all([watchFetchAllLoanSummary()]);
}
