import { payload } from "app/utils/types";
import {
  GET_PENDING_PROPOSALS,
  GET_INPROGRESS_PENDING_PROPOSALS,
  fetchPendingInProgressProposalsOngoing,
  fetchPendingInProgressProposalsSuccess,
  fetchPendingInProgressProposalsError,
  fetchPendingProposalsSuccess,
  fetchPendingProposalsOngoing,
  fetchPendingProposalsError,
  GET_BASIC_INFO_PENDING_PROPOSALS,
  fetchBasicInfoStart,
  fetchBasicInfoSuccess,
  fetchBasicInfoError,
  SAVE_BASIC_INFO_PENDING_PROPOSALS,
  saveBasicInfoError,
  saveBasicInfo,
  saveBasicInfoStart,
  VERIFY_DOCUMENT,
  verifyDocStart,
  verifyDocSuccess,
  verifyDocError,
  GET_DOCUMENT,
  getDocStart,
  getDocSuccess,
  getDocError,
  updateOverallProgress,
  generateInvoiceStart,
  generateInvoiceSuccess,
  generateInvoiceError,
  GET_BUY_SELL_BUSINESS_VALUATION_PENDING_PROPOSALS,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  GENERATE_BASIC_INFO_INVOICE,
  fetchBusinessValuationError,
  fetchBusinessValuationStart,
  fetchBusinessValuationSuccess,
} from "../../redux/features/pending-proposals/index";
import {
  DELETE_ADDITION_REQ_TASK,
  GET_INSURANCE_UNDERWRITING_PENDING_PROPOSALS,
  GET_PLAN_FINALIZE_PENDING_PROPOSALS,
  deleteTaskError,
  deleteTaskStart,
  deleteTaskSuccess,
  fetchInsuranceUnderwritingError,
  fetchInsuranceUnderwritingStart,
  fetchInsuranceUnderwritingSuccess,
  fetchPlanFinalizationError,
  fetchPlanFinalizationStart,
  fetchPlanFinalizationSuccess,
} from "app/redux/features/pending-proposals";

function* proposalPendingFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchPendingProposalsOngoing());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchPendingProposalsSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPendingProposalsError(error));
  }
}

function* proposalPendingInProgressFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchPendingInProgressProposalsOngoing());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchPendingInProgressProposalsSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPendingInProgressProposalsError(error));
  }
}

function* basicInfo(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchBasicInfoStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchBasicInfoSuccess(response.data));
    yield put(updateOverallProgress(response.data?.overallProgress));
  } catch (error: any) {
    yield put(fetchBasicInfoError(error));
  }
}

function* getPlanFinalization(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchPlanFinalizationStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchPlanFinalizationSuccess(response.data));
    yield put(updateOverallProgress(response.data?.overallProgress));
  } catch (error: any) {
    yield put(fetchPlanFinalizationError(error));
  }
}

function* getInsuranceUnderwriting(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchInsuranceUnderwritingStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchInsuranceUnderwritingSuccess(response.data));
    yield put(updateOverallProgress(response.data?.overallProgress));
  } catch (error: any) {
    yield put(fetchInsuranceUnderwritingError(error));
  }
}

function* saveBasicInformation(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams, body },
    } = actions;
    yield put(saveBasicInfoStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, body, requestParams, queryParams),
    );
    yield put(saveBasicInfo(response.data));
  } catch (error: any) {
    yield put(saveBasicInfoError(error));
  }
}

function* businessValuation(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchBusinessValuationStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchBusinessValuationSuccess(response.data));
    yield put(updateOverallProgress(response.data?.overallProgress));
  } catch (error: any) {
    yield put(fetchBusinessValuationError(error));
  }
}

function* verifyDocument(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams, body },
    } = actions;
    yield put(verifyDocStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, body, requestParams, queryParams),
    );
    yield put(verifyDocSuccess(response.data));
  } catch (error: any) {
    yield put(verifyDocError(error));
  }
}

function* getDocument(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(getDocStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(getDocSuccess(response.data));
  } catch (error: any) {
    yield put(getDocError(error));
  }
}

function* deleteAdditionalReqTask(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(deleteTaskStart());
    const response = yield call(() =>
      makeRequest(path).delete(queryString, requestParams, queryParams),
    );
    yield put(deleteTaskSuccess(response.data));
  } catch (error: any) {
    yield put(deleteTaskError(error));
  }
}

function* basicInfoInvoiceGenerateProposal(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(generateInvoiceStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, {}, requestParams, queryParams),
    );
    yield put(generateInvoiceSuccess(response.data));
  } catch (error: any) {
    yield put(generateInvoiceError(error));
  }
}

function* watchPendingSagas() {
  yield takeLatest(GET_PENDING_PROPOSALS, proposalPendingFetchRequest);
  yield takeLatest(GET_INPROGRESS_PENDING_PROPOSALS, proposalPendingInProgressFetchRequest);
  yield takeLatest(GET_BASIC_INFO_PENDING_PROPOSALS, basicInfo);
  yield takeLatest(GET_INSURANCE_UNDERWRITING_PENDING_PROPOSALS, getInsuranceUnderwriting);
  yield takeLatest(GET_PLAN_FINALIZE_PENDING_PROPOSALS, getPlanFinalization);
  yield takeLatest(SAVE_BASIC_INFO_PENDING_PROPOSALS, saveBasicInformation);
  yield takeLatest(GET_BUY_SELL_BUSINESS_VALUATION_PENDING_PROPOSALS, businessValuation);
  yield takeLatest(VERIFY_DOCUMENT, verifyDocument);
  yield takeLatest(GET_DOCUMENT, getDocument);
  yield takeLatest(DELETE_ADDITION_REQ_TASK, deleteAdditionalReqTask);
  yield takeLatest(GENERATE_BASIC_INFO_INVOICE, basicInfoInvoiceGenerateProposal);
}

export default function* proposalPendingSaga() {
  yield all([watchPendingSagas()]);
}
