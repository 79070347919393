import ReactDOM from "react-dom/client";
import App from "./App";
import { store } from "@store";
import { Provider } from "react-redux";
import { StrictMode } from "react";
import { PersistGate } from "redux-persist/integration/react";
// import { persistor } from "app/store/configureStore";
import { persistStore } from "redux-persist";

const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </StrictMode>,
);

// NOTE If you want to start measuring performance in your app, pass a function
// NOTE to log results (for example: reportWebVitals(console.log))
// NOTE or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
