import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_STORE_DASHBOARD_DATA = "TRIGGER_STORE_DASHBOARD_DATA";

interface State {
  data: any;
}

const initialState: State = {
  data: false,
};

export const getAllDashboard = createSlice({
  name: "setDashBoardData",
  initialState,
  reducers: {
    setAllDasboardSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
  },
});

export const { setAllDasboardSuccess } = getAllDashboard.actions;
export const getDashboardData = (state: RootState) => state.storedashboardData;

export const allDashboardReducer = getAllDashboard.reducer;
