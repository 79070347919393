import { reqSignStart, reqSignSuccess, reqSignError, GET_REQ_SIGN } from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
//   import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getReqSign(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(reqSignStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(reqSignSuccess(response.data));
  } catch (error: any) {
    yield put(reqSignError(error));
  }
}
export default function* reqSignSaga() {
  yield all([takeLatest(GET_REQ_SIGN, getReqSign)]);
}
