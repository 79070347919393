import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface State {
  data: any;
  error: any;
  loading: any;
}

const initialState: State = {
  data: {},
  error: {},
  loading: "",
};

export const getAllAgents = createSlice({
  name: "getAllAgents",
  initialState,
  reducers: {
    setGetAllAgentsSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
    },
    setGetAllAgentsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setGetAllAgentsOngoing: (state) => {
      state.data = {};
      state.loading = true;
      state.error = {};
    },
  },
});

export const { setGetAllAgentsSuccess, setGetAllAgentsFailure, setGetAllAgentsOngoing } =
  getAllAgents.actions;
export const getAllAgentsStatus = (state: RootState) => state.getAllAgents;

export const allAgentsReducer = getAllAgents.reducer;
