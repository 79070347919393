import {
  TRIGGER_AGENT_BRANDING_PRIMARY_LOGO_UPLOAD,
  TRIGGER_AGENT_BRANDING_SECONDARY_LOGO_UPLOAD,
  TRIGGER_GET_AGENT_BRANDING_DOMAIN,
  TRIGGER_AGENT_BRANDING_SAVE,
  TRIGGER_GET_AGENT_BRANDING,
  setAgentBrandingFailure,
  setAgentBrandingOngoing,
  setAgentBrandingSuccess,
  setGetAgentBrandingDetailsSuccess,
  setGetAgentBrandingDetailsFailure,
  setGetAgentBrandingDetailsOngoing,
  setUploadFailure,
  setUploadOngoing,
  setUploadSuccess,
  setSubDomainSuccess,
  setSubDomainFailure,
  setSubDomainOngoing,
  setAllBrandingOngoing,
  setAllBrandingFailure,
  setAllBrandingSuccess,
  setActiveBrandingSuccess,
  setActiveBrandingFailure,
  setActiveBrandingOngoing,
} from "@reducers";
import { takeLatest, all, call, put, takeEvery } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import {
  TRIGGER_ACTIVE_BRANDING,
  TRIGGER_AGENT_DELETE_LOGO,
  TRIGGER_GET_ALL_BRANDING,
  setDeleteLogoFailure,
  setDeleteLogoOngoing,
  setDeleteLogoSuccess,
  setSecondaryUploadFailure,
  setSecondaryUploadOngoing,
  setSecondaryUploadSuccess,
} from "app/redux/features/agent-branding";
import { payload } from "app/utils/types";

interface VerifyTokenActionPayload {
  type: string;
  payload: {
    address: string;
    body: string;
  };
}

function* agentBrandingLogoUpload(actions: VerifyTokenActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;
    yield put(setUploadOngoing());
    const response = yield call(() =>
      makeRequest(path, { "Content-Type": "multipart/form-data" }).post(body),
    );
    yield put(setUploadSuccess(response.data));
  } catch (error: any) {
    yield put(setUploadFailure(error));
  }
}

function* agentBrandingSecondaryLogoUpload(
  actions: VerifyTokenActionPayload,
): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;
    yield put(setSecondaryUploadOngoing());
    const response = yield call(() =>
      makeRequest(path, { "Content-Type": "multipart/form-data" }).post(body),
    );
    yield put(setSecondaryUploadSuccess(response.data));
  } catch (error: any) {
    yield put(setSecondaryUploadFailure(error));
  }
}

function* getAgentBrandingDetails(actions: VerifyTokenActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;
    yield put(setGetAgentBrandingDetailsOngoing());
    const response = yield call(() => makeRequest(path).get());
    yield put(setGetAgentBrandingDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(setGetAgentBrandingDetailsFailure(error));
  }
}

function* agentBrandingSave(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: {
        address: path,
        body: { requestBody },
      },
    } = actions;

    yield put(setAgentBrandingOngoing());
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(setAgentBrandingSuccess(response.data));
  } catch (error: any) {
    yield put(setAgentBrandingFailure(error));
  }
}

function* setSubDomain(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;

    yield put(setSubDomainOngoing());
    const response = yield call(() => makeRequest(path).post(body));
    if (response.data.status === true) {
      yield put(setSubDomainSuccess(response.data));
    } else {
      yield put(setSubDomainFailure(response.data));
    }
  } catch (error: any) {
    yield put(setSubDomainFailure(error));
  }
}

function* getAllBrandingDetails(actions: VerifyTokenActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;
    yield put(setAllBrandingOngoing());
    const response = yield call(() => makeRequest(path).get());
    yield put(setAllBrandingSuccess(response.data));
  } catch (error: any) {
    yield put(setAllBrandingFailure(error));
  }
}

function* deleteBrandingLogo(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams },
    } = actions;
    yield put(setDeleteLogoOngoing());
    const response = yield call(() => makeRequest(path).delete(queryString, requestParams));
    yield put(setDeleteLogoSuccess(response.data));
  } catch (error: any) {
    yield put(setDeleteLogoFailure(error));
  }
}

function* setActiveBranding(actions: VerifyTokenActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;

    yield put(setActiveBrandingOngoing());
    const response = yield call(() => makeRequest(path).post(body));
    yield put(setActiveBrandingSuccess(response.data));
  } catch (error: any) {
    yield put(setActiveBrandingFailure(error));
  }
}

export default function* agentBrandingSaga() {
  yield all([
    takeEvery(TRIGGER_AGENT_BRANDING_PRIMARY_LOGO_UPLOAD, agentBrandingLogoUpload),
    takeEvery(TRIGGER_AGENT_BRANDING_SECONDARY_LOGO_UPLOAD, agentBrandingSecondaryLogoUpload),
    takeLatest(TRIGGER_AGENT_BRANDING_SAVE, agentBrandingSave),
    takeLatest(TRIGGER_GET_AGENT_BRANDING, getAgentBrandingDetails),
    takeLatest(TRIGGER_GET_AGENT_BRANDING_DOMAIN, setSubDomain),
    takeLatest(TRIGGER_GET_ALL_BRANDING, getAllBrandingDetails),
    takeLatest(TRIGGER_ACTIVE_BRANDING, setActiveBranding),
    takeLatest(TRIGGER_AGENT_DELETE_LOGO, deleteBrandingLogo),
  ]);
}
