import {
  blockVerifyToken,
  blockVerifyTokenError,
  TRIGGER_BLOCK_VERIFY_API_REQUEST,
} from "@reducers";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import { BlockTokenRequestBody } from "app/redux/features/blockVerify/action";

interface BlockVerifyTokenActionPayload {
  type: string;
  payload: {
    address: string;
    body: BlockTokenRequestBody;
  };
}

function* blockVerifyTokenRequest(
  actions: BlockVerifyTokenActionPayload,
): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(blockVerifyToken(response.data));
  } catch (error: any) {
    yield put(blockVerifyTokenError(error));
  }
}
export default function* blockVerifySaga() {
  yield all([takeLatest(TRIGGER_BLOCK_VERIFY_API_REQUEST, blockVerifyTokenRequest)]);
}
