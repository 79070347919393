import {
  TRIGGER_PLAN_SPLIT_DOLLAR_LIST_REQUEST,
  fetchDataFailure,
  fetchDataStart,
  fetchDataSuccess,
} from "app/redux/features/planSplitDollar";
import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* planSplitDollarListRequest(
  actions: FinancialInfoActionPayload,
): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(fetchDataStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(fetchDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchDataFailure(error));
  }
}

export default function* planSplitDollarSaga() {
  yield all([takeLatest(TRIGGER_PLAN_SPLIT_DOLLAR_LIST_REQUEST, planSplitDollarListRequest)]);
}
