import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_TRANSACTION_LEDGER = "GET_TRANSACTION_LEDGER";
export const POST_TRANSACTION_LEDGER = "POST_TRANSACTION_LEDGER";

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface pendingPolicySummaryResponseType {
  fetchTransactionLedger?: InitialStateInterface;
  postTransactionLedger?: InitialStateInterface;
}

const initialState: pendingPolicySummaryResponseType = {
  fetchTransactionLedger: { data: {}, error: {}, loading: false },
  postTransactionLedger: { data: {}, error: {}, loading: false },
};

export const transactionLedgerSlice = createSlice({
  name: "policySummary",
  initialState,
  reducers: {
    fetchTransactionLedgerLoading: (state) => {
      state.fetchTransactionLedger = { data: {}, error: {}, loading: true };
    },
    fetchTransactionLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.fetchTransactionLedger = { data: action.payload.data, error: {}, loading: false };
    },
    fetchTransactionLedgerError: (state, action: PayloadAction<any>) => {
      state.fetchTransactionLedger = { data: {}, error: action.payload, loading: false };
    },
    postTransactionLedgerLoading: (state) => {
      state.postTransactionLedger = { data: {}, error: {}, loading: true };
    },
    postTransactionLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.postTransactionLedger = { data: action.payload.data, error: {}, loading: false };
    },
    postTransactionLedgerError: (state, action: PayloadAction<any>) => {
      state.postTransactionLedger = { data: {}, error: action.payload, loading: false };
    },
    resetPostTransactionLedgerState: (state) => {
      state.postTransactionLedger = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchTransactionLedgerLoading,
  fetchTransactionLedgerSuccess,
  fetchTransactionLedgerError,
  postTransactionLedgerLoading,
  postTransactionLedgerSuccess,
  postTransactionLedgerError,
  resetPostTransactionLedgerState,
} = transactionLedgerSlice.actions;
export const getPolicyReview = (state: RootState) => state.policyReview;

export default transactionLedgerSlice.reducer;
