import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_LOANS_SUMMARY_REQUEST = "TRIGGER_LOANS_SUMMARY_REQUEST";
export const TRIGGER_BASE_LOAN_TERMS = "TRIGGER_BASE_LOAN_TERMS";

interface CardData {
  data?: any;
  loading?: boolean;
  error?: string | null | object | any;
}

interface ResponseType {
  fetchLoanSummaryDataSuccess: CardData;
  postBaseLoanTermSuccess: CardData;
}

const initialState: ResponseType = {
  fetchLoanSummaryDataSuccess: { data: {}, error: {}, loading: false },
  postBaseLoanTermSuccess: { data: {}, error: {}, loading: false },
};

export const LoansSummaryListSlice = createSlice({
  name: "Loansummary",
  initialState,
  reducers: {
    fetchLoanSummaryDataStart: (state) => {
      state.fetchLoanSummaryDataSuccess = { data: {}, error: {}, loading: true };
    },
    fetchLoanSummaryDataSuccess: (state, action: PayloadAction<any>) => {
      state.fetchLoanSummaryDataSuccess = { data: action.payload, error: {}, loading: false };
    },
    fetchLoanSummaryDataFailure: (state, action: PayloadAction<any>) => {
      state.fetchLoanSummaryDataSuccess = { data: {}, error: action.payload, loading: false };
    },
    postBaseLoanTermStart: (state) => {
      state.postBaseLoanTermSuccess = { data: {}, error: {}, loading: true };
    },
    postBaseLoanTermSuccess: (state, action: PayloadAction<any>) => {
      state.postBaseLoanTermSuccess = { data: action.payload, error: {}, loading: false };
    },
    postBaseLoanTermFailure: (state, action: PayloadAction<any>) => {
      state.postBaseLoanTermSuccess = { data: {}, error: action.payload, loading: false };
    },
    resetLoanSummaryData: (state) => {
      state.fetchLoanSummaryDataSuccess = { data: {}, error: {}, loading: false };
    },
    resetBaseLoanTermData: (state) => {
      state.postBaseLoanTermSuccess = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchLoanSummaryDataStart,
  fetchLoanSummaryDataSuccess,
  fetchLoanSummaryDataFailure,
  postBaseLoanTermFailure,
  postBaseLoanTermStart,
  postBaseLoanTermSuccess,
  resetLoanSummaryData,
  resetBaseLoanTermData,
} = LoansSummaryListSlice.actions;

export const getLoanSummary = (state: RootState) => state.loanSummary;

export default LoansSummaryListSlice.reducer;
