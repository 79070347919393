import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_COMMISSION_PENDING_APPROVAL = "GET_COMMISSION_PENDING_APPROVAL";

interface PendingApprovalInterface {
  data: any;
  loading: boolean;
  error: Record<string, string | number | object> | null;
}

const initialState: PendingApprovalInterface = {
  loading: false,
  data: [],
  error: {},
};

export const commissionPendingApprovalSlice = createSlice({
  name: "commissionPendingApproval",
  initialState,
  reducers: {
    getCommissionPendingApprovalStart: (state) => {
      state.error = {};
      state.loading = true;
    },
    getCommissionPendingApprovalSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
    },
    getCommissionPendingApprovalError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetCommissionPendingApprovalState: () => initialState,
  },
});

export const {
  getCommissionPendingApprovalStart,
  getCommissionPendingApprovalSuccess,
  getCommissionPendingApprovalError,
  resetCommissionPendingApprovalState,
} = commissionPendingApprovalSlice.actions;

export const getCommissionPendingApprovalList = (state: RootState) =>
  state.commissionPendingApproval;
export default commissionPendingApprovalSlice.reducer;
