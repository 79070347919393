import {
  PENDING_DOCUMENT_LIST,
  pendingDocumentListError,
  pendingDocumentListStart,
  pendingDocumentListSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* pendingDocumentListRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(pendingDocumentListStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(pendingDocumentListSuccess(response.data));
  } catch (error: any) {
    yield put(pendingDocumentListError(error));
  }
}

export default function* pendingDocumentListSaga() {
  yield all([takeLatest(PENDING_DOCUMENT_LIST, pendingDocumentListRequest)]);
}
