import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_REPORT = "GET_REPORT";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    reportStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    reportSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    reportError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetReportState: () => initialState,
  },
});

export const { reportStart, reportSuccess, reportError, resetReportState } = reportSlice.actions;

export const report = (state: RootState) => state.report;
export default reportSlice.reducer;
