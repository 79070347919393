import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const CREATE_FINANCIAL_INFO = "CREATE_FINANCIAL_INFO";

interface FinancialInfoType {
  data: object;
  error: object;
  pending: boolean;
}

const initialState: FinancialInfoType = {
  data: {},
  error: {},
  pending: false,
};

export const contactfinancialInfo = createSlice({
  name: "contactfinancialInfo",
  initialState,
  reducers: {
    setContactFinancialInfo: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.pending = false;
    },
    financialInfoError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.pending = false;
    },
    financialApiPending: (state) => {
      state.data = {};
      state.error = {};
      state.pending = true;
    },
    resetFinancialState: () => initialState,
  },
});

export const {
  setContactFinancialInfo,
  financialInfoError,
  financialApiPending,
  resetFinancialState,
} = contactfinancialInfo.actions;
export const createContactFinancialInfo = (state: RootState) => state.contactFinancialInfo;

export const contactfinancialInfoReducer = contactfinancialInfo.reducer;
