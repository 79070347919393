import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_PLANS_LIST_REQUEST = "TRIGGER_PLANS_LIST_REQUEST";
export const TRIGGER_PLAN_CHANGE_REQUEST = "TRIGGER_PLAN_CHANGE_REQUEST";
export const TRIGGER_PAYMENT_STATUS_UPDATE_REQUEST = "TRIGGER_PAYMENT_STATUS_UPDATE_REQUEST";

interface PlansInterface {
  data: any;
  loading: boolean;
  error: string | null;
}

const initialState: PlansInterface = {
  data: [],
  loading: false,
  error: null,
};

export const plansListSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchDataFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = plansListSlice.actions;

export const getPlans = (state: RootState) => state.getPlansList;

export default plansListSlice.reducer;
