import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  TRIGGER_PROPOSAL_REQUEST,
  TRIGGER_PROPOSAL_REQUEST_DRAFT,
  TRIGGER_PROPOSAL_PENDING,
  TRIGGER_INACTIVE_PROPOSALS,
  proposalsError,
  requestedProposals,
  requestedProposalsDraft,
  proposalsErrorDraft,
  requestedProposalsOngoingDraft,
  requestedProposalsOngoing,
  requestedProposalsPendingSuccess,
  requestedProposalsPendingError,
  requestedProposalsPendingStart,
  TRIGGER_PROPOSAL_PENDING_APPROVAL,
  inActiveProposalsSuccess,
  inActiveProposalsError,
  inActiveProposalsStart,
  requestedProposalsPendingApprovalSuccess,
  requestedProposalsPendingApprovalStart,
  requestedProposalsPendingApprovalError,
} from "app/redux/features/proposals";
import { makeRequest } from "../../utils/makeRequest";

interface ProposalRequestParams {
  type: string;
  payload: {
    address: string;
    key: string;
    isRequestParams?: boolean;
    isQueryParams?: boolean;
  };
}

function* getProposalRequestList(actions: ProposalRequestParams): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, isRequestParams, isQueryParams },
    } = actions;
    yield put(requestedProposalsOngoing());
    const response = yield call(() => makeRequest(path).get(key, isRequestParams, isQueryParams));
    yield put(requestedProposals(response.data));
  } catch (error: any) {
    yield put(proposalsError(error));
  }
}
function* getProposalRequestListDraft({ payload }: any): Generator<any, void, any> {
  try {
    yield put(requestedProposalsOngoingDraft());
    const { path, key, isRequestParams, isQueryParams } = payload;
    const response = yield call(() => makeRequest(path).get(key, isRequestParams, isQueryParams));
    yield put(requestedProposalsDraft(response.data));
  } catch (error: any) {
    yield put(proposalsErrorDraft(error));
  }
}
function* getProposalRequestPendingDraft({ payload }: any): Generator<any, void, any> {
  try {
    yield put(requestedProposalsPendingStart());
    const { path, key, isRequestParams, isQueryParams } = payload;
    const response = yield call(() => makeRequest(path).get(key, isRequestParams, isQueryParams));
    yield put(requestedProposalsPendingSuccess(response.data));
  } catch (error: any) {
    yield put(requestedProposalsPendingError(error));
  }
}

function* getPendingApproval({ payload }: any): Generator<any, void, any> {
  try {
    yield put(requestedProposalsPendingApprovalStart());
    const { path, key, isRequestParams, isQueryParams } = payload;
    const response = yield call(() => makeRequest(path).get(key, isRequestParams, isQueryParams));
    yield put(requestedProposalsPendingApprovalSuccess(response.data));
  } catch (error: any) {
    yield put(requestedProposalsPendingApprovalError(error));
  }
}

function* getInactiveProposals({ payload }: any): Generator<any, void, any> {
  try {
    yield put(inActiveProposalsStart());
    const { path, key, isRequestParams, isQueryParams } = payload;
    const response = yield call(() => makeRequest(path).get(key, isRequestParams, isQueryParams));
    yield put(inActiveProposalsSuccess(response.data));
  } catch (error: any) {
    yield put(inActiveProposalsError(error));
  }
}

export default function* proposalsSaga() {
  yield all([
    takeLatest(TRIGGER_PROPOSAL_REQUEST, getProposalRequestList),
    takeLatest(TRIGGER_PROPOSAL_REQUEST_DRAFT, getProposalRequestListDraft),
    takeLatest(TRIGGER_PROPOSAL_PENDING, getProposalRequestPendingDraft),
    takeLatest(TRIGGER_PROPOSAL_PENDING_APPROVAL, getPendingApproval),
    takeLatest(TRIGGER_INACTIVE_PROPOSALS, getInactiveProposals),
  ]);
}
