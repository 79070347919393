import {
  DELETE_SHARHOLDER_LEDGER,
  GET_PROPOSAL_PROGRESS_DATA,
  GET_SHARHOLDER_LEDGER,
  POST_SHARHOLDER_LEDGER,
  PUT_SHARHOLDER_LEDGER,
  deleteShareHolderError,
  deleteShareHolderStart,
  deleteShareHolderSuccess,
  getProgressError,
  getProgressStart,
  getProgressSuccess,
  getShareHolderError,
  getShareHolderStart,
  getShareHolderSuccess,
  postShareHolderError,
  postShareHolderSuccess,
  putShareHolderError,
  putShareHolderStart,
  putShareHolderSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { payload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* shareHolderFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(getShareHolderStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(getShareHolderSuccess(response.data));
  } catch (error: any) {
    yield put(getShareHolderError(error));
  }
}

function* shareHolderPostRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody, key, requestParams));
    yield put(postShareHolderSuccess(response.data));
  } catch (error: any) {
    yield put(postShareHolderError(error));
  }
}

function* shareHolderPutRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, body, requestParams },
    } = actions;
    yield put(putShareHolderStart());
    const response = yield call(() => makeRequest(path).put(type, body, requestParams));
    yield put(putShareHolderSuccess(response.data));
  } catch (error: any) {
    yield put(putShareHolderError(error));
  }
}

function* shareHolderDeleteRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(deleteShareHolderStart());
    const response = yield call(() =>
      makeRequest(path).delete(queryString, requestParams, queryParams),
    );
    yield put(deleteShareHolderSuccess(response.data));
  } catch (error: any) {
    yield put(deleteShareHolderError(error));
  }
}

function* proposalProgressFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(getProgressStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(getProgressSuccess(response.data));
  } catch (error: any) {
    yield put(getProgressError(error));
  }
}

function* watchReviewSagas() {
  yield takeLatest(GET_SHARHOLDER_LEDGER, shareHolderFetchRequest);
  yield takeLatest(POST_SHARHOLDER_LEDGER, shareHolderPostRequest);
  yield takeLatest(PUT_SHARHOLDER_LEDGER, shareHolderPutRequest);
  yield takeLatest(DELETE_SHARHOLDER_LEDGER, shareHolderDeleteRequest);
  yield takeLatest(GET_PROPOSAL_PROGRESS_DATA, proposalProgressFetchRequest);
}

export default function* shareHolderSaga() {
  yield all([watchReviewSagas()]);
}
