import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  insuredPlanDetailsStore: {},
  insuredPlanDetailsStoreComplete: {},
};

const requestProposalDesignFormSlice = createSlice({
  name: "requestPropsalDesignForm",
  initialState,
  reducers: {
    setInsuredPlanDetailsStore: (state, action: PayloadAction<any>) => {
      state.insuredPlanDetailsStore = action.payload;
    },
    setInsuredPlanDetailsCompleteStore: (state, action: PayloadAction<any>) => {
      state.insuredPlanDetailsStoreComplete = action.payload;
    },
  },
});

export const { setInsuredPlanDetailsStore, setInsuredPlanDetailsCompleteStore } =
  requestProposalDesignFormSlice.actions;

export default requestProposalDesignFormSlice.reducer;
