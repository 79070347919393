export const TRIGGER_DELETE_AGENTS = "TRIGGER_DELETE_AGENTS";

// Action creators
export const triggerFetchAgentsReq = (
  path: string,
  key?: string,
  requestParams?: boolean,
  queryParams?: boolean,
) => ({
  type: "TRIGGER_FETCH_AGENTS",
  payload: {
    address: path,
    key,
    requestParams,
    queryParams,
  },
});

export const triggerFetchAdminsReq = (
  path: string,
  key?: string,
  requestParams?: boolean,
  queryParams?: boolean,
) => ({
  type: "TRIGGER_FETCH_ADMINS",
  payload: {
    address: path,
    key,
    requestParams,
    queryParams,
  },
});

export const triggerDeleteAgent = (
  path: string,
  key?: string,
  requestParams?: boolean,
  queryParams?: boolean,
) => ({
  type: TRIGGER_DELETE_AGENTS,
  payload: {
    address: path,
    key,
    requestParams,
    queryParams,
  },
});
