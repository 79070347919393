import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const PROPOSALS_APPROVED_PENDING = "PROPOSALS_APPROVED_PENDING";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const proposalsApprovedPendingSlice = createSlice({
  name: "proposalsApprovedPending",
  initialState,
  reducers: {
    proposalsApprovedPendingStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    proposalsApprovedPendingSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    proposalsApprovedPendingError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetProposalsApprovedPendingState: () => initialState,
  },
});

export const {
  proposalsApprovedPendingStart,
  proposalsApprovedPendingSuccess,
  proposalsApprovedPendingError,
  resetProposalsApprovedPendingState,
} = proposalsApprovedPendingSlice.actions;

export const proposalsApprovedPending = (state: RootState) => state.proposalsApprovedPending;
export default proposalsApprovedPendingSlice.reducer;
