import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_VIEW_POLICY_DETAILS = "GET_VIEW_POLICY_DETAILS";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const getViewPolicyDetailsSlice = createSlice({
  name: "getViewPolicyDetails",
  initialState,
  reducers: {
    getViewPolicyDetailsStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    getViewPolicyDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    getViewPolicyDetailsError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetGetViewPolicyDetailsState: () => initialState,
  },
});

export const {
  getViewPolicyDetailsStart,
  getViewPolicyDetailsSuccess,
  getViewPolicyDetailsError,
  resetGetViewPolicyDetailsState,
} = getViewPolicyDetailsSlice.actions;

export const getViewPolicyDetails = (state: RootState) => state.getViewPolicyDetails;
export default getViewPolicyDetailsSlice.reducer;
