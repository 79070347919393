import {
  TRIGGER_GET_AGENT_COMMISSION,
  TRIGGER_POST_AGENT_COMMISSION,
  agentCommissionSuccess,
  agentCommissionError,
  agentCommissionLoading,
  agentPostCommissionSuccess,
  agentPostCommissionLoading,
  agentPostCommissionError,
  TRIGGER_DELETE_AGENT_COMMISSION,
  agentDeleteCommissionLoading,
  agentDeleteCommissionSuccess,
  agentDeleteCommissionError,
} from "app/redux/features/agent-commission";
import { makeRequest } from "app/utils/makeRequest";
import { CommissionActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getAgentCommission(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(agentCommissionLoading());

    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));

    yield put(agentCommissionSuccess(response.data));
  } catch (error: any) {
    yield put(agentCommissionError(error));
  }
}

function* postAgentCommission(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body: requestBody, requestParams, queryParams },
    } = actions;
    yield put(agentPostCommissionLoading());

    const response = yield call(() =>
      makeRequest(path).post(requestBody, key, requestParams, queryParams),
    );

    yield put(agentPostCommissionSuccess(response.data));
  } catch (error: any) {
    yield put(agentPostCommissionError(error));
  }
}

function* deleteAgentCommission(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(agentDeleteCommissionLoading());

    const response = yield call(() => makeRequest(path).delete(key, requestParams, queryParams));

    yield put(agentDeleteCommissionSuccess(response.data));
  } catch (error: any) {
    yield put(agentDeleteCommissionError(error));
  }
}

export default function* agentCommissionSaga() {
  yield all([takeLatest(TRIGGER_GET_AGENT_COMMISSION, getAgentCommission)]);
  yield all([takeLatest(TRIGGER_POST_AGENT_COMMISSION, postAgentCommission)]);
  yield all([takeLatest(TRIGGER_DELETE_AGENT_COMMISSION, deleteAgentCommission)]);
}
