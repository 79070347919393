import {
  TRIGGER_STORE_IS_FORM_ACTIVE_DATA,
  setIsFormActiveSuccess,
} from "app/redux/features/IsFormActive";
import { put, takeLatest } from "redux-saga/effects";

function* storeIsFormActiveData(action: any) {
  const { body } = action.payload;
  yield put(setIsFormActiveSuccess(body));
}
export function* watchStoreIsFormActive() {
  yield takeLatest(TRIGGER_STORE_IS_FORM_ACTIVE_DATA, storeIsFormActiveData);
}
