import { put, call, takeLatest } from "redux-saga/effects";

import {
  TRIGGER_REVOKE_CONTACT_REQUEST,
  revokeProposalSuccess,
  revokeProposalError,
} from "../../redux/features/revokeContactById/index";

import { makeRequest } from "../../utils/makeRequest";

function* revokeProposalSaga({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, isRequestParams, isQueryParams } = payload;
    const response = yield call(() =>
      makeRequest(address).put(key, body, isRequestParams, isQueryParams),
    );
    yield put(revokeProposalSuccess(response.data));
  } catch (error: any) {
    yield put(revokeProposalError(error));
  }
}

export default function* revokeContactByIdSaga() {
  yield takeLatest(TRIGGER_REVOKE_CONTACT_REQUEST, revokeProposalSaga);
}
