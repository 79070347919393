import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_PDF_REPORT = "GET_PDF_REPORT";

interface initialStateInterface {
  loading?: boolean;
  error?: { status?: string; message?: string; error?: string };
  data?: { status?: string; message?: string };
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const getPDFReportSlice = createSlice({
  name: "getPDFReport",
  initialState,
  reducers: {
    getPDFReportStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    getPDFReportSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    getPDFReportError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetPDFReportState: () => initialState,
  },
});

export const { getPDFReportStart, getPDFReportSuccess, getPDFReportError } =
  getPDFReportSlice.actions;

export const getCreateProposalPdf = (state: RootState) => state.getCreateProposalPdf;

export default getPDFReportSlice.reducer;
