import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const CREATE_PROPOSAL = "CREATE_PROPOSAL";

interface initialStateInterface {
  loading?: boolean;
  error?: any;
  data?: any;
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const createProposalSlice = createSlice({
  name: "createProposal",
  initialState,
  reducers: {
    createProposalStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    createProposalSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    createProposalError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetPDFReportState: () => initialState,
  },
});

export const {
  createProposalStart,
  createProposalSuccess,
  createProposalError,
  resetPDFReportState,
} = createProposalSlice.actions;

export const createProposal = (state: RootState) => state.createProposal;

export default createProposalSlice.reducer;
