import {
  getPDFReportStart,
  getPDFReportSuccess,
  getPDFReportError,
  GET_PDF_REPORT,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getPDFReportRequest(actions: FinancialInfoActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(getPDFReportStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(getPDFReportSuccess(response.data));
  } catch (error: any) {
    yield put(getPDFReportError(error));
  }
}
export default function* getPDFReportSaga() {
  yield all([takeLatest(GET_PDF_REPORT, getPDFReportRequest)]);
}
