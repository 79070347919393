import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_PLAN_REVIEW_DETAILS = "GET_PLAN_REVIEW_DETAILS";
export const PUT_PLAN_REVIEW_DETAILS = "PUT_PLAN_REVIEW_DETAILS";

interface BASESTAETINTERFACE {
  loading: boolean;
  data: any;
  error: any;
}
interface INITIALSTATEINTERFACE {
  getPlanReviewDetails: BASESTAETINTERFACE;
  putPlanReviewDetails: BASESTAETINTERFACE;
}

const initialState = {
  getPlanReviewDetails: { loading: false, data: {} as any, error: {} as any },
  putPlanReviewDetails: { loading: false, data: {} as any, error: {} as any },
};

export const planReviewDetailsSlice = createSlice({
  name: "PlanReviewDetails",
  initialState,
  reducers: {
    getPlanReviewDetailsStart: (state: INITIALSTATEINTERFACE) => {
      state.getPlanReviewDetails.data = {};
      state.getPlanReviewDetails.error = {};
      state.getPlanReviewDetails.loading = true;
    },
    getPlanReviewDetailsSuccess: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.getPlanReviewDetails.data = action.payload;
      state.getPlanReviewDetails.error = {};
      state.getPlanReviewDetails.loading = false;
    },
    getPlanReviewDetailsError: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.getPlanReviewDetails.data = {};
      state.getPlanReviewDetails.error = action.payload;
      state.getPlanReviewDetails.loading = false;
    },
    resetGetPlanReviewDetailsState: (state: INITIALSTATEINTERFACE) => ({
      ...state,
      getPlanReviewDetails: initialState.getPlanReviewDetails,
    }),

    putPlanReviewDetailsStart: (state: INITIALSTATEINTERFACE) => {
      state.putPlanReviewDetails.data = {};
      state.putPlanReviewDetails.error = {};
      state.putPlanReviewDetails.loading = true;
    },
    putPlanReviewDetailsSuccess: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.putPlanReviewDetails.data = action.payload;
      state.putPlanReviewDetails.error = {};
      state.putPlanReviewDetails.loading = false;
    },
    putPlanReviewDetailsError: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.putPlanReviewDetails.data = {};
      state.putPlanReviewDetails.error = action.payload;
      state.putPlanReviewDetails.loading = false;
    },
    resetPutPlanReviewDetailsState: (state: INITIALSTATEINTERFACE) => ({
      ...state,
      putPlanReviewDetails: initialState.putPlanReviewDetails,
    }),
  },
});

export const {
  getPlanReviewDetailsStart,
  getPlanReviewDetailsSuccess,
  getPlanReviewDetailsError,
  resetGetPlanReviewDetailsState,
  putPlanReviewDetailsStart,
  putPlanReviewDetailsSuccess,
  putPlanReviewDetailsError,
  resetPutPlanReviewDetailsState,
} = planReviewDetailsSlice.actions;

export const planReviewDetails = (state: RootState) => state.planReview;
export default planReviewDetailsSlice.reducer;
