import {
  TRIGGER_STORE_IS_AGENCY_MANAGER_ACTIVE_DATA,
  setIsAgencyManagerActiveSuccess,
} from "app/redux/features/isAgencyActive";
import { put, takeLatest } from "redux-saga/effects";

function* storeIsAgencyManagerData(action: any) {
  const { body } = action.payload;
  yield put(setIsAgencyManagerActiveSuccess(body));
}
export function* watchStoreIsAgencyManager() {
  yield takeLatest(TRIGGER_STORE_IS_AGENCY_MANAGER_ACTIVE_DATA, storeIsAgencyManagerData);
}
