import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  navigatedFrom: "" as string,
};

const navigatedFromSlice: any = createSlice({
  name: "navigatedFromLocation",
  initialState,
  reducers: {
    setNavigatedFrom: (state, action: PayloadAction<any>) => {
      state.navigatedFrom = action.payload;
    },
  },
});

export const { setNavigatedFrom } = navigatedFromSlice.actions;

export default navigatedFromSlice.reducer;
