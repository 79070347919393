import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_BLOCK_API_REQUEST = "TRIGGER_BLOCK_API_REQUEST";

interface BlockApiInterface {
  blockApiData: any;
  blockApiError: string | null;
}

const initialState: BlockApiInterface = {
  blockApiData: "",
  blockApiError: null,
};

export const blockTokenSlice = createSlice({
  name: "block",
  initialState,
  reducers: {
    blockToken: (state, action: PayloadAction<any[]>) => {
      state.blockApiData = action.payload;
    },
    blockTokenError: (state, action: PayloadAction<string>) => {
      state.blockApiError = action.payload;
    },
    resetBlockTokenState: (state) => {
      state.blockApiData = "";
      state.blockApiError = "";
    },
  },
});

export const { blockToken, blockTokenError, resetBlockTokenState } = blockTokenSlice.actions;
export const getBlockToken = (state: RootState) => state.blockToken;

export default blockTokenSlice.reducer;
