import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const SEND_PROPOSAL = "SEND_PROPOSAL";

interface initialStateInterface {
  loading?: boolean;
  error?: { status?: string; message?: string; error?: string };
  data?: { status?: string; message?: string };
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const sendProposalSlice = createSlice({
  name: "sendProposal",
  initialState,
  reducers: {
    sendProposalStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    sendProposalSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    sendProposalError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetSendProposalState: () => initialState,
  },
});

export const { sendProposalStart, sendProposalSuccess, sendProposalError, resetSendProposalState } =
  sendProposalSlice.actions;

export const sendProposal = (state: RootState) => state.sendProposal;

export default sendProposalSlice.reducer;
