import {
  TRIGGER_CREATE_PROPOSAL_CONTACT_FETCH_REQUEST,
  TRIGGER_CREATE_PROPOSAL_CONTACT_PUT_REQUEST,
  TRIGGER_CREATE_PROPOSAL_OTHER_DETAILS_FETCH_REQUEST,
  TRIGGER_CREATE_PROPOSAL_OTHER_DETAILS_SAVE_REQUEST,
  createProposalOtherDetailsSaveSuccess,
  createProposalOtherDetailsSaveError,
  createProposalContactInfoFetchError,
  createProposalContactInfoFetchSucess,
  createProposalContactInfoPutSuccess,
  createProposalContactInfoPutError,
  createProposalOtherDetailsSuccess,
  createProposalOtherDetailsError,
  resetpostCSVDataState,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* createProposalContactInfoRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(createProposalContactInfoFetchSucess(response.data));
  } catch (error: any) {
    yield put(createProposalContactInfoFetchError(error));
  }
}
function* createProposalContactInfoPutRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).put(key, requestBody, requestParams));
    yield put(createProposalContactInfoPutSuccess(response.data));
  } catch (error: any) {
    yield put(createProposalContactInfoPutError(error));
  }
}

function* createProposalOtherDetailsFetchRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(createProposalOtherDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(createProposalOtherDetailsError(error));
  }
}

function* createProposalOtherDetailsSaveRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(resetpostCSVDataState());
    yield put(createProposalOtherDetailsSaveSuccess(response.data));
  } catch (error: any) {
    yield put(createProposalOtherDetailsSaveError(error));
  }
}

function* createProposal() {
  yield takeLatest(TRIGGER_CREATE_PROPOSAL_CONTACT_FETCH_REQUEST, createProposalContactInfoRequest);
  yield takeLatest(
    TRIGGER_CREATE_PROPOSAL_CONTACT_PUT_REQUEST,
    createProposalContactInfoPutRequest,
  );
  yield takeLatest(
    TRIGGER_CREATE_PROPOSAL_OTHER_DETAILS_FETCH_REQUEST,
    createProposalOtherDetailsFetchRequest,
  );
  yield takeLatest(
    TRIGGER_CREATE_PROPOSAL_OTHER_DETAILS_SAVE_REQUEST,
    createProposalOtherDetailsSaveRequest,
  );
}

export default function* createProposalSaga() {
  yield all([createProposal()]);
}
