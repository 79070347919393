import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface InviteEmailInterface {
  data: any;
  error: any;
  loading: boolean;
}

const initialState: InviteEmailInterface = {
  data: {},
  error: {},
  loading: false,
};

export const inviteEmail = createSlice({
  name: "inviteEmail",
  initialState,
  reducers: {
    setInviteSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = {};
    },
    setInviteFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setInviteOngoing: (state) => {
      state.data = {};
      state.loading = true;
      state.error = {};
    },
    resetState: () => initialState,
  },
});

export const { setInviteSuccess, setInviteFailure, setInviteOngoing, resetState } =
  inviteEmail.actions;
export const getInviteStatus = (state: RootState) => state.inviteEmail;

export const inviteEmailReducer = inviteEmail.reducer;
