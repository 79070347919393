import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_PUT_INSURANCE_PRODUCT = "TRIGGER_PUT_INSURANCE_PRODUCT";
export const TRIGGER_POST_INSURANCE_PRODUCT = "TRIGGER_POST_INSURANCE_PRODUCT";

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface insuranceProductResponseType {
  insuranceProductPutData?: InitialStateInterface;
  insuranceProductPostData?: InitialStateInterface;
}

const initialState: insuranceProductResponseType = {
  insuranceProductPutData: { data: {}, error: {}, loading: false },
};

export const insuranceProductSlice = createSlice({
  name: "insuranceProduct",
  initialState,
  reducers: {
    insuranceProductPutStart: (state) => {
      state.insuranceProductPutData = { data: {}, error: {}, loading: true };
    },
    insuranceProductPutSuccess: (state, action: PayloadAction<any[]>) => {
      state.insuranceProductPutData = { data: action.payload, error: {}, loading: false };
    },
    insuranceProductPutError: (state, action: PayloadAction<any[]>) => {
      state.insuranceProductPutData = { data: {}, error: action.payload, loading: false };
    },
    insuranceProductPostStart: (state) => {
      state.insuranceProductPostData = { data: {}, error: {}, loading: true };
    },
    insuranceProductPostSuccess: (state, action: PayloadAction<any[]>) => {
      state.insuranceProductPostData = { data: action.payload, error: {}, loading: false };
    },
    insuranceProductPostError: (state, action: PayloadAction<any[]>) => {
      state.insuranceProductPostData = { data: {}, error: action.payload, loading: false };
    },
    resetProductData: (state) => {
      state.insuranceProductPutData = { data: {}, error: {}, loading: false };
      state.insuranceProductPostData = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  insuranceProductPutStart,
  insuranceProductPutSuccess,
  insuranceProductPutError,
  insuranceProductPostStart,
  insuranceProductPostSuccess,
  insuranceProductPostError,
  resetProductData,
} = insuranceProductSlice.actions;
export const getInsuranceProductState = (state: RootState) => state.insuranceProduct;

export default insuranceProductSlice.reducer;
