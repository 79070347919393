import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_GET_AGENT_COMMISSION = "TRIGGER_GET_AGENT_COMMISSION";
export const TRIGGER_POST_AGENT_COMMISSION = "TRIGGER_POST_AGENT_COMMISSION";
export const TRIGGER_DELETE_AGENT_COMMISSION = "TRIGGER_DELETE_AGENT_COMMISSION";

interface agentCommissionInterface {
  agentCommissionData: any;
  agentCommissionLoading: boolean;
  agentCommissionError: any;
  agentPostCommissionData: any;
  agentPostCommissionError: any;
  agentPostCommissionLoading: boolean;
  agentDeleteCommissionData: any;
  agentDeleteCommissionError: any;
  agentDeleteCommissionLoading: boolean;
}

const initialState: agentCommissionInterface = {
  agentCommissionData: {},
  agentCommissionLoading: false,
  agentCommissionError: "",
  agentPostCommissionData: {},
  agentPostCommissionError: "",
  agentPostCommissionLoading: false,
  agentDeleteCommissionData: {},
  agentDeleteCommissionError: "",
  agentDeleteCommissionLoading: false,
};

export const agentCommissionSlice = createSlice({
  name: "agentCommission",
  initialState,
  reducers: {
    agentCommissionSuccess: (state, action: PayloadAction<any[]>) => {
      state.agentCommissionData = action.payload;
      state.agentCommissionError = "";
      state.agentCommissionLoading = false;
    },
    agentCommissionError: (state, action: PayloadAction<any[]>) => {
      state.agentCommissionData = {};
      state.agentCommissionError = action.payload;
      state.agentCommissionLoading = false;
    },
    agentCommissionLoading: (state) => {
      state.agentCommissionData = {};
      state.agentCommissionError = {};
      state.agentCommissionLoading = true;
    },
    agentPostCommissionSuccess: (state, action: PayloadAction<any[]>) => {
      state.agentPostCommissionData = action.payload;
      state.agentPostCommissionError = {};
      state.agentPostCommissionLoading = false;
    },
    agentPostCommissionError: (state, action: PayloadAction<any[]>) => {
      state.agentPostCommissionData = {};
      state.agentPostCommissionError = action.payload;
      state.agentPostCommissionLoading = false;
    },
    agentPostCommissionLoading: (state) => {
      state.agentPostCommissionData = {};
      state.agentPostCommissionError = {};
      state.agentPostCommissionLoading = true;
    },
    agentDeleteCommissionSuccess: (state, action: PayloadAction<any[]>) => {
      state.agentDeleteCommissionData = action.payload;
      state.agentDeleteCommissionError = {};
      state.agentDeleteCommissionLoading = false;
    },
    agentDeleteCommissionError: (state, action: PayloadAction<any[]>) => {
      state.agentDeleteCommissionData = {};
      state.agentDeleteCommissionError = action.payload;
      state.agentDeleteCommissionLoading = false;
    },
    agentDeleteCommissionLoading: (state) => {
      state.agentDeleteCommissionData = {};
      state.agentDeleteCommissionError = {};
      state.agentDeleteCommissionLoading = true;
    },
    resetAgentCommission: (state) => {
      state.agentPostCommissionData = {};
      state.agentPostCommissionError = "";
      state.agentPostCommissionLoading = false;
      state.agentDeleteCommissionData = {};
      state.agentDeleteCommissionError = "";
      state.agentDeleteCommissionLoading = false;
    },
  },
});

export const {
  agentCommissionSuccess,
  agentCommissionError,
  agentCommissionLoading,
  agentPostCommissionSuccess,
  agentPostCommissionError,
  agentPostCommissionLoading,
  agentDeleteCommissionError,
  agentDeleteCommissionLoading,
  agentDeleteCommissionSuccess,
  resetAgentCommission,
} = agentCommissionSlice.actions;
export const getAgentCommisionState = (state: RootState) => state.agentCommission;

export default agentCommissionSlice.reducer;
