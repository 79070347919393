import { put, call, takeLatest } from "redux-saga/effects";
import {
  BASICINFO_STORE_DATA,
  NOT_INSURED_PERSON_STORE_DATA,
  PROPOSAL_TYPES_STORE_DATA,
  getNotInsuredPersonInfo,
  getProposalTypeSuccess,
  getProposalTypeFailure,
  getBasicInfo,
} from "@reducers";
import { makeRequest } from "app/utils/makeRequest";

function* storeBasicInfoSaga(action: any) {
  const { body } = action.payload;
  yield put(getBasicInfo(body));
}

function* storeNotInsuredInfoSaga(action: any) {
  const { body } = action.payload;
  yield put(getNotInsuredPersonInfo(body));
}

function* proposalTypeInfoSaga(action: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = action;
    const response = yield call(() => makeRequest(path).get());
    yield put(getProposalTypeSuccess(response.data));
  } catch (error: any) {
    yield put(getProposalTypeFailure(error));
  }
}

function* requestProposalSaga() {
  yield takeLatest(BASICINFO_STORE_DATA, storeBasicInfoSaga);
  yield takeLatest(NOT_INSURED_PERSON_STORE_DATA, storeNotInsuredInfoSaga);
  yield takeLatest(PROPOSAL_TYPES_STORE_DATA, proposalTypeInfoSaga);
}

export default requestProposalSaga;
