import { put, call, takeLatest } from "redux-saga/effects";

import { makeRequest } from "../../utils/makeRequest";
import {
  TRIGGER_DUPLICATE_PROPOSAL_ID_REQUEST,
  reviewDuplicateProposalIdSuccess,
  reviewDuplicateProposalIdError,
  reviewDuplicateProposalIdsOngoing,
} from "app/redux/features/duplicateProposalId";

function* reviewProposalTimelineSaga({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body, key, isRequestParams } = payload;
    yield put(reviewDuplicateProposalIdsOngoing());
    const response = yield call(() => makeRequest(address).post(body, key, isRequestParams));
    yield put(reviewDuplicateProposalIdSuccess(response.data));
  } catch (error: any) {
    yield put(reviewDuplicateProposalIdError(error));
  }
}

export default function* reviewDuplicateProposalIdSaga() {
  yield takeLatest(TRIGGER_DUPLICATE_PROPOSAL_ID_REQUEST, reviewProposalTimelineSaga);
}
