import {
  getAgentProfileStart,
  getAgentProfileSuccess,
  getAgentProfileError,
  GET_AGENT_PROFILE,
} from "@reducers";
import { makeRequest } from "app/utils/makeRequest";
import { call, put, takeLatest } from "redux-saga/effects";

function* getAgentProfile(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(getAgentProfileStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(getAgentProfileSuccess(response.data));
  } catch (error: any) {
    yield put(getAgentProfileError(error));
  }
}

export default function* watchAgentProfile() {
  yield takeLatest(GET_AGENT_PROFILE, getAgentProfile);
}
