import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import {
  TRIGGER_GET_AFR_DETAILS,
  TRIGGER_SAVE_AFR_DETAILS,
  getAfrError,
  getAfrOngoing,
  getAfrSuccess,
  saveAfrOngoing,
  saveAfrError,
  saveAfrSuccess,
} from "app/redux/features/afr";
import { payload } from "app/utils/types";

function* getAfrRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(getAfrOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(getAfrSuccess(response.data));
  } catch (error: any) {
    yield put(getAfrError(error));
  }
}

function* saveAfrRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;
    yield put(saveAfrOngoing());
    const response = yield call(() => makeRequest(path).post(body));
    yield put(saveAfrSuccess(response.data));
  } catch (error: any) {
    yield put(saveAfrError(error));
  }
}

function* watchAfrRequest() {
  yield takeLatest(TRIGGER_GET_AFR_DETAILS, getAfrRequest);
  yield takeLatest(TRIGGER_SAVE_AFR_DETAILS, saveAfrRequest);
}

export default function* afrSaga() {
  yield all([watchAfrRequest()]);
}
