import { isEmpty } from "ramda";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import apiConfig from "../../config";
import {
  getObjectFromLocalStore,
  setObjectInLocalStore,
  removeLocalStore,
  clearLocalStore,
} from "../utils/localStore";
import { resetLoginState, resetLogoutState } from "app/redux";
import { clearSessionStore } from "app/utils/sessionStore";
import { store } from "@store";
import { persistStore } from "redux-persist";
import Cookies from "js-cookie";

interface RefreshTokenResponse {
  access_token: string;
  refresh_token: string;
  userType: string;
}
const { baseURL, paths, headers, excludeToken } = apiConfig;
const instance: AxiosInstance = axios.create({
  baseURL,
  headers: {
    ...headers,
  },
});
function urlBuild(endpoint: string, key?: string, requestParams?: boolean, queryParams?: boolean) {
  if (paths[endpoint] == null) {
    throw new Error(`Endpoint is not present in ${Object.keys(apiConfig.paths)}`);
  }

  return `${instance.defaults.baseURL}${paths[endpoint]}${
    requestParams ? `/${key}` : queryParams ? `?${key}` : ""
  }`;
}
async function requestRefreshToken() {
  const { refresh_token } = getObjectFromLocalStore("useraccess");
  const header: any = {
    headers: {
      Authorization: `Bearer ${refresh_token}`,
    },
  };
  try {
    // NOTE update the API URL according to need
    const { data } = await instance.post<RefreshTokenResponse>(
      urlBuild("refreshToken"),
      {},
      header,
    );
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

let isRefreshing = false;
// const refreshPromiseQueue: Array<{
//   resolve: (value?: unknown) => void;
//   reject: (reason?: any) => void;
// }> = [];
// NOTE : GET - await MakeRequest("/api/path/12").get({querry1: 1, querry2: 2})
// NOTE : POST - await MakeRequest("/api/path").post({param1: 1, param2: 2})
// NOTE : PUT - await MakeRequest("/api/path/12").get({querry1: 1, querry2: 2})
// NOTE : DELETE - await MakeRequest("/api/path/12").delete({querry1: 1, querry2: 2})
// NOTE : we can even further add file download feature in it
instance.interceptors.request.use(
  async (config) => {
    const path = instance.defaults.baseURL
      ? config?.url?.replace(instance.defaults.baseURL, "").split("?")[0]
      : "";
    const userData = getObjectFromLocalStore("useraccess");

    // Add authorization header with access token
    if (path && !excludeToken?.includes(path) && !isEmpty(config?.headers) && userData) {
      config.headers.Authorization = `Bearer ${userData.access_token}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response: AxiosResponse) =>
    // Do something with successful response
    response,
  async (error: any) => {
    const { response, config: originalRequest } = error;
    const persistor = persistStore(store);
    if (
      response &&
      response.status === 401 &&
      response.data.type === "OAuthException" &&
      originalRequest
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const userAccess: any = await requestRefreshToken();
          if (userAccess) {
            setObjectInLocalStore("useraccess", userAccess);
            // Process all the queued requests
            return await instance(originalRequest);
          }
          removeLocalStore("useraccess");
          isRefreshing = false;
        } catch (errorResponse) {
          // Handle refresh token error
          /* If the refresh token is expired or we are getting an unauthorized
          access error clean the refresh token and reload the window */
          removeLocalStore("useraccess");
          setTimeout(() => window.location.reload(), 300);
          // return Promise.reject(errorResponse);
        }
      }
    }
    if (
      originalRequest?.url?.includes(paths.logout) ||
      (response && response.status === 401 && response.data.type === "OAuthInvalid")
    ) {
      Cookies.remove("authData", {
        path: "/",
        domain: `.iapp.cool`,
      });
      removeLocalStore("useraccess");
      persistor.purge();
      clearSessionStore();
      store.dispatch(resetLogoutState());
      store.dispatch(resetLoginState());
      clearLocalStore();
      window.location.replace("/");
    }
    const errorDetails = { ...response?.data, statusCode: response?.status };
    return Promise.reject(errorDetails);
  },
);
export function makeRequest(path: string, headerData?: Record<string, any>) {
  const header: any = {
    headers: {
      ...instance.defaults.headers,
      ...headerData,
    },
  };
  const request = {
    get: async (key?: string, requestParams?: boolean, queryParams?: boolean) => {
      try {
        return await instance.get(urlBuild(path, key, requestParams, queryParams), header);
      } catch (error: any) {
        error.infoStatus = "error";
        throw error;
      }
    },

    post: async (body?: any, key?: string, requestParams?: boolean, queryParams?: boolean) => {
      try {
        return await instance.post(urlBuild(path, key, requestParams, queryParams), body, header);
      } catch (error: any) {
        error.infoStatus = "error";
        throw error;
      }
    },

    put: async (key?: string, body: any = {}, requestParams?: boolean, queryParams?: boolean) => {
      try {
        return await instance.put(urlBuild(path, key, requestParams, queryParams), body, header);
      } catch (error: any) {
        if (error) {
          error.infoStatus = "error";
        }
        throw error;
      }
    },
    patch: async (body?: any, key?: string, requestParams?: boolean, queryParams?: boolean) => {
      try {
        return await instance.patch(urlBuild(path, key, requestParams, queryParams), body, header);
      } catch (error: any) {
        error.infoStatus = "error";
        throw error;
      }
    },

    delete: async (key?: string, requestParams?: boolean, queryParams?: boolean) => {
      try {
        return await instance.delete(urlBuild(path, key, requestParams, queryParams), header);
      } catch (error: any) {
        error.infoStatus = "error";
        throw error;
      }
    },
  };

  return request;
}
