import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_TRANSACTIONS_LEDGER = "GET_TRANSACTIONS_LEDGER";
export const POST_TRANSACTIONS_LEDGER = "POST_TRANSACTIONS_LEDGER";

interface transactionLedgerResponse {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface responseType {
  gettransactionLedgerResponse?: transactionLedgerResponse;
  postTransactionLedgerResponse?: transactionLedgerResponse;
}

const initialState: responseType = {
  gettransactionLedgerResponse: { data: {}, error: {}, loading: false },
  postTransactionLedgerResponse: { data: {}, error: {}, loading: false },
};

export const transactionLedgerSlice = createSlice({
  name: "transactionLedger",
  initialState,
  reducers: {
    gettransactionLedgerStart: (state) => {
      state.gettransactionLedgerResponse = { data: {}, error: {}, loading: true };
    },
    gettransactionLedgerSuccess: (state, action: PayloadAction<responseType>) => {
      state.gettransactionLedgerResponse = { data: action.payload, error: {}, loading: false };
    },
    gettransactionLedgerError: (state, action: PayloadAction<responseType>) => {
      state.gettransactionLedgerResponse = { data: {}, error: action.payload, loading: false };
    },
    resetGettransactionLedger: (state) => {
      state.gettransactionLedgerResponse = { data: {}, error: {}, loading: false };
    },
    postTransactionLedgerStart: (state) => {
      state.postTransactionLedgerResponse = { data: {}, error: {}, loading: true };
    },
    postTransactionLedgerSuccess: (state, action: PayloadAction<responseType>) => {
      state.postTransactionLedgerResponse = { data: action.payload, error: {}, loading: false };
    },
    postTransactionLedgerError: (state, action: PayloadAction<responseType>) => {
      state.postTransactionLedgerResponse = { data: {}, error: action.payload, loading: false };
    },
    resetpostTransactionLedger: (state) => {
      state.postTransactionLedgerResponse = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  gettransactionLedgerError,
  gettransactionLedgerStart,
  gettransactionLedgerSuccess,
  postTransactionLedgerError,
  postTransactionLedgerStart,
  postTransactionLedgerSuccess,
  resetGettransactionLedger,
  resetpostTransactionLedger,
} = transactionLedgerSlice.actions;

export const transactionLedgerLedgerStore = (state: RootState) => state.transactionLedgerSaga;

export default transactionLedgerSlice.reducer;
