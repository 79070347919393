import {
  insuranceCompanySuccess,
  insuranceCompanyError,
  insuranceCompanyLoading,
  insuranceCompanyDeleteError,
  insuranceCompanyPostSuccess,
  insuranceCompanyPostError,
  insuranceCompanyPostLoading,
  insuranceCompanyDeleteSuccess,
  insuranceCompanyDeleteLoading,
  insuranceCompanyPutStart,
  insuranceCompanyPutSuccess,
  insuranceCompanyPutError,
  TRIGGER_GET_INSURANCE_COMPANY,
  TRIGGER_POST_INSURANCE_COMPANY,
  TRIGGER_DELETE_INSURANCE_COMPANY,
  TRIGGER_PUT_INSURANCE_COMPANY,
} from "app/redux/features/insurance-company";
import { makeRequest } from "app/utils/makeRequest";
import { CommissionActionPayload, InsuranceCompanyActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getInsuranceCompany(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(insuranceCompanyLoading());

    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));

    yield put(insuranceCompanySuccess(response.data));
  } catch (error: any) {
    yield put(insuranceCompanyError(error));
  }
}

function* postInsuranceCompany(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body: requestBody, requestParams, queryParams },
    } = actions;
    yield put(insuranceCompanyPostLoading());

    const response = yield call(() =>
      makeRequest(path).post(requestBody, key, requestParams, queryParams),
    );

    yield put(insuranceCompanyPostSuccess(response.data));
  } catch (error: any) {
    yield put(insuranceCompanyPostError(error));
  }
}

function* deleteInsuranceCompany(actions: CommissionActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(insuranceCompanyDeleteLoading());

    const response = yield call(() => makeRequest(path).delete(key, requestParams, queryParams));

    yield put(insuranceCompanyDeleteSuccess(response.data));
  } catch (error: any) {
    yield put(insuranceCompanyDeleteError(error));
  }
}

function* putInsuranceCompany(actions: InsuranceCompanyActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams, body },
    } = actions;
    yield put(insuranceCompanyPutStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, body, requestParams, queryParams),
    );
    yield put(insuranceCompanyPutSuccess(response.data));
  } catch (error: any) {
    yield put(insuranceCompanyPutError(error));
  }
}

export default function* insuranceCompanySaga() {
  yield all([takeLatest(TRIGGER_GET_INSURANCE_COMPANY, getInsuranceCompany)]);
  yield all([takeLatest(TRIGGER_POST_INSURANCE_COMPANY, postInsuranceCompany)]);
  yield all([takeLatest(TRIGGER_DELETE_INSURANCE_COMPANY, deleteInsuranceCompany)]);
  yield all([takeLatest(TRIGGER_PUT_INSURANCE_COMPANY, putInsuranceCompany)]);
}
