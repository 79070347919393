import {
  uploadAdditionalReqDocumentStart,
  uploadAdditionalReqDocumentSuccess,
  uploadAdditionalReqDocumentError,
  UPLOAD_ADDITIONAL_REQ_DOCUMENT,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* uploadDocumentRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, body: requestBody },
    } = actions;
    yield put(uploadAdditionalReqDocumentStart());
    const response = yield call(() =>
      makeRequest(path, { "Content-Type": "multipart/form-data" }).put(
        key,
        requestBody,
        requestParams,
      ),
    );

    yield put(uploadAdditionalReqDocumentSuccess(response.data));
  } catch (error: any) {
    yield put(uploadAdditionalReqDocumentError(error));
  }
}

export default function* UploadDocumentSaga() {
  yield all([takeLatest(UPLOAD_ADDITIONAL_REQ_DOCUMENT, uploadDocumentRequest)]);
}
