import {
  createProposalStart,
  createProposalSuccess,
  createProposalError,
  CREATE_PROPOSAL,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* createProposalRequest(actions: FinancialInfoActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(createProposalStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(createProposalSuccess(response.data));
  } catch (error: any) {
    yield put(createProposalError(error));
  }
}
export default function* createRequestProposalSaga() {
  yield all([takeLatest(CREATE_PROPOSAL, createProposalRequest)]);
}
