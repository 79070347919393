import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const PAST_DUES = "PAST_DUES";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const planPastDuesSlice = createSlice({
  name: "planPastDues",
  initialState,
  reducers: {
    planPastDuesStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    planPastDuesSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    planPastDuesError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetPlanPastDuesState: () => initialState,
  },
});

export const { planPastDuesStart, planPastDuesSuccess, planPastDuesError, resetPlanPastDuesState } =
  planPastDuesSlice.actions;

export const planPastDues = (state: RootState) => state.planPastDues;
export default planPastDuesSlice.reducer;
