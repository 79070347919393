import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_PROPOSAL_REQUEST = "TRIGGER_PROPOSAL_REQUEST";
export const TRIGGER_PROPOSAL_REQUEST_DRAFT = "TRIGGER_PROPOSAL_REQUEST_DRAFT";
export const TRIGGER_PROPOSAL_PENDING = "TRIGGER_PROPOSAL_PENDING";
export const TRIGGER_PROPOSAL_PENDING_APPROVAL = "TRIGGER_PROPOSAL_PENDING_APPROVAL";
export const TRIGGER_AGENT_PROPOSAL_RECIEVED = "TRIGGER_PROPOSAL_RECIEVED";
export const TRIGGER_INACTIVE_PROPOSALS = "TRIGGER_INACTIVE_PROPOSALS";

export const triggerGetProposal = (
  path: string,
  key?: string,
  isRequestParams?: boolean,
  isQueryParams?: boolean,
) => ({
  type: TRIGGER_PROPOSAL_REQUEST,
  payload: {
    address: path,
    key,
    isRequestParams,
    isQueryParams,
  },
});

export interface RequestProposalType {
  id: any;
  proposalNo: string;
  sortOrderNo: string;
  contactName: string;
  agentName: string;
  status: string;
  proposalType: string;
  dateSubmitted: string;
}

export interface RequestProposalTypeDraft {
  firstName: string;
  lastName: string;
  email: string;
  onboarded: string;
  profileProgress: 0;
  id: string;
  contactName: string;
}

export interface RequestProposalsDraft {
  data: {
    content: RequestProposalTypeDraft[];
    totalCount: number;
  };
  state: boolean;
}

export interface RequestProposals {
  data: RequestProposalType[];
  totalContent: number;
  state: boolean;
}

interface RequestProposalInterface {
  requestProposalData: RequestProposals | null;
  requestProposalError: object;
  requestProposalLoading: boolean;
  requestProposalDataDraft: any;
  requestProposalErrorDraft: object;
  requestProposalLoadingDraft: boolean;
  requestProposalPendingData: any;
  requestProposalPendingError: any;
  requestProposalPendingLoading: boolean;
  agentProposalRecievedData?: object;
  agentProposalRecievedLoading: boolean;
  agentProposalRecievedError: object;
  pendingApprovalData: RequestProposals | null;
  pendingApprovalError: object;
  pendingApprovalLoading: boolean;
  inactiveProposalData: any | null;
  inactiveProposalError: object;
  inactiveProposalLoading: boolean;
}

const initialState: RequestProposalInterface = {
  requestProposalData: null,
  requestProposalError: {},
  requestProposalLoading: false,
  requestProposalDataDraft: null,
  requestProposalErrorDraft: {},
  requestProposalLoadingDraft: false,
  requestProposalPendingData: {},
  requestProposalPendingError: {},
  requestProposalPendingLoading: false,
  agentProposalRecievedData: {},
  agentProposalRecievedLoading: false,
  agentProposalRecievedError: {},
  pendingApprovalData: null,
  pendingApprovalError: {},
  pendingApprovalLoading: false,
  inactiveProposalData: null,
  inactiveProposalError: {},
  inactiveProposalLoading: false,
};

export const proposalSlice = createSlice({
  name: "proposals",
  initialState,
  reducers: {
    requestedProposals: (state, action: PayloadAction<RequestProposals>) => {
      state.requestProposalData = action.payload;
      state.requestProposalError = {};
      state.requestProposalLoading = false;
    },
    proposalsError: (state, action: PayloadAction<any>) => {
      state.requestProposalData = null;
      state.requestProposalError = action.payload;
      state.requestProposalLoading = false;
    },
    requestedProposalsOngoing: (state) => {
      state.requestProposalData = null;
      state.requestProposalError = {};
      state.requestProposalLoading = true;
    },
    requestedProposalsDraft: (state, action: PayloadAction<RequestProposalsDraft>) => {
      state.requestProposalDataDraft = action.payload;
      state.requestProposalErrorDraft = {};
      state.requestProposalLoadingDraft = false;
    },
    proposalsErrorDraft: (state, action: PayloadAction<any>) => {
      state.requestProposalDataDraft = null;
      state.requestProposalErrorDraft = action.payload;
      state.requestProposalLoadingDraft = false;
    },
    requestedProposalsOngoingDraft: (state) => {
      state.requestProposalDataDraft = null;
      state.requestProposalErrorDraft = {};
      state.requestProposalLoadingDraft = true;
    },
    requestedProposalsPendingSuccess: (state, action: PayloadAction<RequestProposalsDraft>) => {
      state.requestProposalPendingData = action.payload;
      state.requestProposalPendingError = {};
      state.requestProposalPendingLoading = false;
    },
    requestedProposalsPendingError: (state, action: PayloadAction<any>) => {
      state.requestProposalPendingData = {};
      state.requestProposalPendingError = action.payload;
      state.requestProposalPendingLoading = false;
    },
    requestedProposalsPendingStart: (state) => {
      state.requestProposalPendingData = {};
      state.requestProposalPendingError = {};
      state.requestProposalPendingLoading = true;
    },
    requestedProposalsPendingApprovalSuccess: (state, action: PayloadAction<RequestProposals>) => {
      state.pendingApprovalData = action.payload;
      state.pendingApprovalError = {};
      state.pendingApprovalLoading = false;
    },
    requestedProposalsPendingApprovalError: (state, action: PayloadAction<any>) => {
      state.pendingApprovalData = null;
      state.pendingApprovalError = action.payload;
      state.pendingApprovalLoading = false;
    },
    requestedProposalsPendingApprovalStart: (state) => {
      state.pendingApprovalData = null;
      state.pendingApprovalError = {};
      state.pendingApprovalLoading = true;
    },
    inActiveProposalsSuccess: (state, action: PayloadAction<any>) => {
      state.inactiveProposalData = action.payload;
      state.inactiveProposalError = {};
      state.inactiveProposalLoading = false;
    },
    inActiveProposalsError: (state, action: PayloadAction<any>) => {
      state.inactiveProposalData = null;
      state.inactiveProposalError = action.payload;
      state.inactiveProposalLoading = false;
    },
    inActiveProposalsStart: (state) => {
      state.inactiveProposalData = null;
      state.inactiveProposalError = {};
      state.inactiveProposalLoading = true;
    },
    resetRequestProposalsState: () => initialState,
  },
});

export const {
  requestedProposals,
  proposalsError,
  resetRequestProposalsState,
  requestedProposalsOngoing,
  requestedProposalsDraft,
  proposalsErrorDraft,
  requestedProposalsOngoingDraft,
  requestedProposalsPendingSuccess,
  requestedProposalsPendingError,
  requestedProposalsPendingStart,
  requestedProposalsPendingApprovalError,
  requestedProposalsPendingApprovalStart,
  requestedProposalsPendingApprovalSuccess,
  inActiveProposalsSuccess,
  inActiveProposalsError,
  inActiveProposalsStart,
} = proposalSlice.actions;
export const getAllProposals = (state: RootState) => state.proposals;

export default proposalSlice.reducer;
