import {
  TRIGGER_GET_CONCIERGE_SERVICE,
  fetchConciergeServiceStart,
  fetchConciergeServiceSuccess,
  fetchConciergeServiceFailure,
} from "app/redux/features/agent-concierge-service";
import { makeRequest } from "app/utils/makeRequest";
// import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* conciergeServiceRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body, key, requestParams, queryParams },
    } = actions;
    yield put(fetchConciergeServiceStart());
    const response = yield call(() =>
      makeRequest(path).post(body, key, requestParams, queryParams),
    );
    yield put(fetchConciergeServiceSuccess(response.data));
  } catch (error: any) {
    yield put(fetchConciergeServiceFailure(error));
  }
}

export default function* conciergeServiceSaga() {
  yield all([takeLatest(TRIGGER_GET_CONCIERGE_SERVICE, conciergeServiceRequest)]);
}
