import {
  uploadDocumentStart,
  uploadDocumentSuccess,
  uploadDocumentError,
  UPLOAD_DOCUMENT,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* uploadDocumentRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body: requestBody, requestParams },
    } = actions;
    yield put(uploadDocumentStart());
    const response = yield call(() =>
      makeRequest(path, { "Content-Type": "multipart/form-data" }).post(
        requestBody,
        key,
        requestParams,
      ),
    );
    yield put(uploadDocumentSuccess(response.data));
  } catch (error: any) {
    yield put(uploadDocumentError(error));
  }
}

export default function* UploadDocumentSaga() {
  yield all([takeLatest(UPLOAD_DOCUMENT, uploadDocumentRequest)]);
}
