import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_VIEW_LOAN_DETAILS = "GET_VIEW_LOAN_DETAILS";

const initialState = {
  data: {} as any,
  loading: false,
  error: {} as any,
};

export const getViewLoanDetailsSlice = createSlice({
  name: "getViewLoanDetails",
  initialState,
  reducers: {
    getViewLoanDetailsStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    getViewLoanDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    getViewLoanDetailsError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetGetViewLoanDetailsState: () => initialState,
  },
});

export const {
  getViewLoanDetailsStart,
  getViewLoanDetailsSuccess,
  getViewLoanDetailsError,
  resetGetViewLoanDetailsState,
} = getViewLoanDetailsSlice.actions;

export const getViewLoanDetails = (state: RootState) => state.getViewLoanDetails;
export default getViewLoanDetailsSlice.reducer;
