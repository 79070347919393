import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import {
  TRIGGER_GET_DISABILITY_INSURANCE,
  setDisabilityInsuranceSuccess,
  setDisabilityInsuranceError,
  setDisabilityInsuranceLoading,
  TRIGGER_SAVE_DISABILITY_INSURANCE,
  setSaveDisabilityInsuranceLoading,
  setSaveDisabilityInsuranceSuccess,
  setSaveDisabilityInsuranceError,
  setShareholderError,
  setShareholderLoading,
  setShareholderSuccess,
  TRIGGER_GET_SHAREHOLDER,
  TRIGGER_SAVE_SHAREHOLDER,
  setSaveShareholderLoading,
  setSaveShareholderSuccess,
  setSaveShareholderError,
  GENERATE_DISABILITY_INSURANCE_INVOICE,
  generateDisabilityInsuranceInvoiceSuccess,
  generateDisabilityInsuranceInvoiceError,
  generateDisabilityInsuranceInvoiceLoading,
  TRIGGER_CREATE_PLAN_DISABILITY_INSURANCE,
  createPlanLoading,
  createPlanSuccess,
  createPlanError,
} from "app/redux/features/disability-insurance";
import { payload } from "app/utils/types";

function* createPlanDisabilityInsurance(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(createPlanLoading());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(createPlanSuccess(response.data));
  } catch (error: any) {
    yield put(createPlanError(error));
  }
}

function* getDisabilityInsurance(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setDisabilityInsuranceLoading());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setDisabilityInsuranceSuccess(response.data));
  } catch (error: any) {
    yield put(setDisabilityInsuranceError(error));
  }
}

function* saveDisabilityInsurance(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body, requestParams, queryParams },
    } = actions;
    yield put(setSaveDisabilityInsuranceLoading());
    const response = yield call(() => makeRequest(path).put(key, body, requestParams, queryParams));
    yield put(setSaveDisabilityInsuranceSuccess(response.data));
  } catch (error: any) {
    yield put(setSaveDisabilityInsuranceError(error));
  }
}

function* getShareholdersList(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;

    yield put(setShareholderLoading());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setShareholderSuccess(response.data));
  } catch (error: any) {
    yield put(setShareholderError(error));
  }
}

function* saveDisabilityInsuranceShareholder(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body: requestBody, requestParams, queryParams },
    } = actions;
    yield put(setSaveShareholderLoading());
    const response = yield call(() =>
      makeRequest(path).post(requestBody, key, requestParams, queryParams),
    );
    yield put(setSaveShareholderSuccess(response.data));
  } catch (error: any) {
    yield put(setSaveShareholderError(error));
  }
}

function* generateDisabilityInsuranceInvoice(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(generateDisabilityInsuranceInvoiceLoading());
    const response = yield call(() =>
      makeRequest(path).put(queryString, {}, requestParams, queryParams),
    );
    yield put(generateDisabilityInsuranceInvoiceSuccess(response.data));
  } catch (error: any) {
    yield put(generateDisabilityInsuranceInvoiceError(error));
  }
}

function* watchDisabilityInsuranceRequest() {
  yield takeLatest(TRIGGER_GET_DISABILITY_INSURANCE, getDisabilityInsurance);
  yield takeLatest(TRIGGER_SAVE_DISABILITY_INSURANCE, saveDisabilityInsurance);
  yield takeLatest(TRIGGER_GET_SHAREHOLDER, getShareholdersList);
  yield takeLatest(TRIGGER_SAVE_SHAREHOLDER, saveDisabilityInsuranceShareholder);
  yield takeLatest(GENERATE_DISABILITY_INSURANCE_INVOICE, generateDisabilityInsuranceInvoice);
  yield takeLatest(TRIGGER_CREATE_PLAN_DISABILITY_INSURANCE, createPlanDisabilityInsurance);
}

export default function* disabilityInsuranceSaga() {
  yield all([watchDisabilityInsuranceRequest()]);
}
