import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const NEXT_45_DAYS = "NEXT_45_DAYS";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const planNext45DaysSlice = createSlice({
  name: "planNext45Days",
  initialState,
  reducers: {
    planNext45DaysStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    planNext45DaysSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    planNext45DaysError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetPlanNext45DaysState: () => initialState,
  },
});

export const {
  planNext45DaysStart,
  planNext45DaysSuccess,
  planNext45DaysError,
  resetPlanNext45DaysState,
} = planNext45DaysSlice.actions;

export const planNext45Days = (state: RootState) => state.planNext45Days;
export default planNext45DaysSlice.reducer;
