import {
  getLoanPlanProjectionStart,
  getLoanPlanProjectionSuccess,
  getLoanPlanProjectionError,
  GET_LOAN_PLAN_PROJECTIONS,
} from "@reducers";
import { makeRequest } from "app/utils/makeRequest";
import { call, put, takeLatest } from "redux-saga/effects";

function* getLoanPlanProjection(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { path, key, isRequestParams },
    } = actions;

    yield put(getLoanPlanProjectionStart());
    const response = yield call(() => makeRequest(path).get(key, isRequestParams));
    if (response.statusCode >= 400) yield put(getLoanPlanProjectionError(response));
    else {
      yield put(getLoanPlanProjectionSuccess(response.data));
    }
  } catch (error: any) {
    yield put(getLoanPlanProjectionError(error));
  }
}

export default function* watchLoanPlanProjection() {
  yield takeLatest(GET_LOAN_PLAN_PROJECTIONS, getLoanPlanProjection);
}
