import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_GET_DISABILITY_INSURANCE = "TRIGGER_GET_DISABILITY_INSURANCE";
export const TRIGGER_SAVE_DISABILITY_INSURANCE = "TRIGGER_SAVE_DISABILITY_INSURANCE";
export const TRIGGER_GET_SHAREHOLDER = "TRIGGER_GET_SHAREHOLDER";
export const TRIGGER_SAVE_SHAREHOLDER = "TRIGGER_SAVE_SHAREHOLDER";
export const GENERATE_DISABILITY_INSURANCE_INVOICE = "GENERATE_DISABILITY_INSURANCE_INVOICE";
export const TRIGGER_CREATE_PLAN_DISABILITY_INSURANCE = "TRIGGER_CREATE_PLAN_DISABILITY_INSURANCE";

interface State {
  data: any;
  error: any;
  loading: boolean;
  saveData: any;
  saveError: any;
  saveLoading: boolean;
  shareholderData: any;
  shareholderError: any;
  shareholderLoading: boolean;
  saveShareholderData: any;
  saveShareholderError: any;
  saveShareholderLoading: boolean;
  generateInvoiceData: any;
  generateInvoiceError: any;
  generateInvoiceLoading: boolean;
  createPlanData: any;
  createPlanError: any;
  createPlanLoading: boolean;
}

const initialState: State = {
  data: {},
  error: {},
  loading: false,
  saveData: {},
  saveError: {},
  saveLoading: false,
  shareholderData: {},
  shareholderError: {},
  shareholderLoading: false,
  saveShareholderData: {},
  saveShareholderError: {},
  saveShareholderLoading: false,
  generateInvoiceData: {},
  generateInvoiceError: {},
  generateInvoiceLoading: false,
  createPlanData: {},
  createPlanError: {},
  createPlanLoading: false,
};

export const getDisabilityInsurance = createSlice({
  name: "setDashBoardData",
  initialState,
  reducers: {
    setDisabilityInsuranceSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    setDisabilityInsuranceError: (state, action: PayloadAction<any[]>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    setDisabilityInsuranceLoading: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    setSaveDisabilityInsuranceSuccess: (state, action: PayloadAction<any[]>) => {
      state.saveData = action.payload;
      state.saveError = {};
      state.saveLoading = false;
    },
    setSaveDisabilityInsuranceError: (state, action: PayloadAction<any[]>) => {
      state.saveData = {};
      state.saveError = action.payload;
      state.saveLoading = false;
    },
    setSaveDisabilityInsuranceLoading: (state) => {
      state.saveData = {};
      state.saveError = {};
      state.saveLoading = true;
    },
    setShareholderSuccess: (state, action: PayloadAction<any[]>) => {
      state.shareholderData = action.payload;
      state.shareholderError = {};
      state.shareholderLoading = false;
    },
    setShareholderError: (state, action: PayloadAction<any[]>) => {
      state.shareholderData = {};
      state.shareholderError = action.payload;
      state.shareholderLoading = false;
    },
    setShareholderLoading: (state) => {
      state.shareholderData = {};
      state.shareholderError = {};
      state.shareholderLoading = true;
    },
    setSaveShareholderSuccess: (state, action: PayloadAction<any[]>) => {
      state.saveShareholderData = action.payload;
      state.saveShareholderError = {};
      state.saveShareholderLoading = false;
    },
    setSaveShareholderError: (state, action: PayloadAction<any[]>) => {
      state.saveShareholderData = {};
      state.saveShareholderError = action.payload;
      state.saveShareholderLoading = false;
    },
    setSaveShareholderLoading: (state) => {
      state.saveShareholderData = {};
      state.saveShareholderError = {};
      state.saveShareholderLoading = true;
    },
    generateDisabilityInsuranceInvoiceSuccess: (state, action: PayloadAction<any[]>) => {
      state.generateInvoiceData = action.payload;
      state.generateInvoiceError = {};
      state.generateInvoiceLoading = false;
    },
    generateDisabilityInsuranceInvoiceError: (state, action: PayloadAction<any[]>) => {
      state.generateInvoiceData = {};
      state.generateInvoiceError = action.payload;
      state.generateInvoiceLoading = false;
    },
    generateDisabilityInsuranceInvoiceLoading: (state) => {
      state.generateInvoiceData = {};
      state.generateInvoiceError = {};
      state.generateInvoiceLoading = true;
    },
    createPlanSuccess: (state, action: PayloadAction<any[]>) => {
      state.createPlanData = action.payload;
      state.createPlanError = {};
      state.createPlanLoading = false;
    },
    createPlanError: (state, action: PayloadAction<any[]>) => {
      state.createPlanData = {};
      state.createPlanError = action.payload;
      state.createPlanLoading = false;
    },
    createPlanLoading: (state) => {
      state.createPlanData = {};
      state.createPlanError = {};
      state.createPlanLoading = true;
    },
    resetAdditionalTaskState: (state) => {
      state.saveData = {};
    },
  },
});

export const {
  setDisabilityInsuranceSuccess,
  setDisabilityInsuranceLoading,
  setDisabilityInsuranceError,
  setSaveDisabilityInsuranceSuccess,
  setSaveDisabilityInsuranceError,
  setSaveDisabilityInsuranceLoading,
  setShareholderSuccess,
  setShareholderError,
  setShareholderLoading,
  setSaveShareholderSuccess,
  setSaveShareholderError,
  setSaveShareholderLoading,
  generateDisabilityInsuranceInvoiceSuccess,
  generateDisabilityInsuranceInvoiceError,
  generateDisabilityInsuranceInvoiceLoading,
  createPlanSuccess,
  createPlanError,
  createPlanLoading,
  resetAdditionalTaskState,
} = getDisabilityInsurance.actions;
export const getDisabilityInsuranceData = (state: RootState) => state.disabilityInsuranceData;

export const disabilityInsuranceReducer = getDisabilityInsurance.reducer;
