import {
  requestNewSignatureStart,
  requestNewSignatureSuccess,
  requestNewSignatureError,
  approveProposalStart,
  approveProposalSuccess,
  approveProposalError,
  REQUEST_NEW_SIGNATURE,
  APPROVE_PROPOSAL,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* requestNewSignatureRequest(
  actions: FinancialInfoActionPayload,
): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(requestNewSignatureStart());
    const response = yield call(() => makeRequest(path).put(key, {}, requestParams));
    yield put(requestNewSignatureSuccess(response.data));
  } catch (error: any) {
    yield put(requestNewSignatureError(error));
  }
}

function* approveProposalRequest(actions: FinancialInfoActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(approveProposalStart());
    const response = yield call(() => makeRequest(path).put(key, {}, requestParams));
    yield put(approveProposalSuccess(response.data));
  } catch (error: any) {
    yield put(approveProposalError(error));
  }
}

export default function* adminPendingApprovalSaga() {
  yield all([takeLatest(REQUEST_NEW_SIGNATURE, requestNewSignatureRequest)]);
  yield all([takeLatest(APPROVE_PROPOSAL, approveProposalRequest)]);
}
