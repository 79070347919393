import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface State {
  data: any;
  error: any;
  loading: any;
}

const initialState: State = {
  data: {},
  error: {},
  loading: false,
};

export const getResendResetPassword = createSlice({
  name: "resendResetPassword",
  initialState,
  reducers: {
    setGetResendResetPassSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
    },
    setGetResendResetPassFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setGetResendResetPassOngoing: (state) => {
      state.data = {};
      state.loading = true;
      state.error = {};
    },
    resetState: () => initialState,
  },
});

export const {
  setGetResendResetPassSuccess,
  setGetResendResetPassFailure,
  setGetResendResetPassOngoing,
  resetState,
} = getResendResetPassword.actions;
export const getResendResetPswdStatus = (state: RootState) => state.resetPasswordResend;

export const resendResetPassReducer = getResendResetPassword.reducer;
