import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const SEND_FILE_TO_CONTACT = "SEND_FILE_TO_CONTACT";

interface initialStateInterface {
  loading?: boolean;
  error?: any;
  data?: any;
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const sendFileToContactSlice = createSlice({
  name: "sendFileToContact",
  initialState,
  reducers: {
    sendFileToContactStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    sendFileToContactSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    sendFileToContactError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetsendFileToContactState: () => initialState,
  },
});

export const {
  sendFileToContactStart,
  sendFileToContactSuccess,
  sendFileToContactError,
  resetsendFileToContactState,
} = sendFileToContactSlice.actions;

export const sendFileToContact = (state: RootState) => state.sendFileToContact;

export default sendFileToContactSlice.reducer;
