export const TRIGGER_RESEND_RESET_PASSWORD = "TRIGGER_RESEND_RESET_PASSWORD";
// Action creators
export const triggerResendResetPassword = (
  path: string,
  key?: string,
  requestParams?: boolean,
  queryParams?: boolean,
) => ({
  type: TRIGGER_RESEND_RESET_PASSWORD,
  payload: {
    address: path,
    key,
    requestParams,
    queryParams,
  },
});
