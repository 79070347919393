import {
  patchCSVUploadStart,
  patchCSVUploadSucess,
  patchCSVUploadError,
  POLICY_INPUTBASE_CSVUPLOAD,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* patchPolicyInputBaseCSVUpload(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(patchCSVUploadStart());
    const response = yield call(() =>
      makeRequest(path, { "Content-Type": "multipart/form-data" }).patch(requestBody),
    );
    yield put(patchCSVUploadSucess(response.data));
  } catch (error: any) {
    yield put(patchCSVUploadError(error));
  }
}
function* watchPatchPolicyInputBaseCSVUpload() {
  yield takeLatest(POLICY_INPUTBASE_CSVUPLOAD, patchPolicyInputBaseCSVUpload);
}

export default function* watchPatchPolicyInputBaseCSVUploadSaga() {
  yield all([watchPatchPolicyInputBaseCSVUpload()]);
}
