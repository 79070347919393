import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface State {
  data: any;
  error: any;
  loading: boolean;
}

const initialState: State = {
  data: {},
  error: {},
  loading: false,
};

export const inviteAgency = createSlice({
  name: "inviteAgency",
  initialState,
  reducers: {
    setInviteAgencySuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = {};
    },
    setInviteAgencyFailure: (state, action: PayloadAction<string>) => {
      state.data = {};
      state.loading = false;
      state.error = action.payload;
    },
    setInviteAgencyOngoing: (state) => {
      state.data = {};
      state.loading = true;
      state.error = {};
    },
    resetState: () => initialState,
  },
});

export const {
  setInviteAgencySuccess,
  setInviteAgencyFailure,
  setInviteAgencyOngoing,
  resetState,
} = inviteAgency.actions;
export const getInviteAgency = (state: RootState) => state.inviteAgency;

export const inviteAgencyReducer = inviteAgency.reducer;
