import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const PAST_DUES_30_DAYS = "PAST_DUES_30_DAYS";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const planPast30DaysSlice = createSlice({
  name: "planPast30Days",
  initialState,
  reducers: {
    planPast30DaysStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    planPast30DaysSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    planPast30DaysError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetPlanPast30DaysState: () => initialState,
  },
});

export const {
  planPast30DaysStart,
  planPast30DaysSuccess,
  planPast30DaysError,
  resetPlanPast30DaysState,
} = planPast30DaysSlice.actions;

export const planPast30Days = (state: RootState) => state.planPast30Days;
export default planPast30DaysSlice.reducer;
