import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const NEXT_30_DAYS = "NEXT_30_DAYS";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const planNext30DaysSlice = createSlice({
  name: "planNext30Days",
  initialState,
  reducers: {
    planNext30DaysStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    planNext30DaysSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    planNext30DaysError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetPlanNext30DaysState: () => initialState,
  },
});

export const {
  planNext30DaysStart,
  planNext30DaysSuccess,
  planNext30DaysError,
  resetPlanNext30DaysState,
} = planNext30DaysSlice.actions;

export const planNext30Days = (state: RootState) => state.planNext30Days;
export default planNext30DaysSlice.reducer;
