import {} from "@reducers";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import {
  TRIGGER_AGENT_REVIEW_BLOCK_REQUEST,
  TRIGGER_AGENT_REVIEW_CONTACT_REQUEST,
  agentReviewContactError,
  agentReviewContactSuccess,
  reviewContactBlockError,
  reviewContactBlockSuccess,
} from "app/redux/features/reviewContactById";

interface VerifyTokenActionPayload {
  type: string;
  payload: {
    address: string;
    key: string;
    requestParams: boolean;
    queryParams: boolean;
  };
}

function* agentReviewContactRequest(actions: VerifyTokenActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(agentReviewContactSuccess(response.data));
  } catch (error: any) {
    yield put(agentReviewContactError(error));
  }
}

function* agentReviewContactBlockRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(reviewContactBlockSuccess(response.data));
  } catch (error: any) {
    yield put(reviewContactBlockError(error));
  }
}

export default function* agentReviewContactSaga() {
  yield all([
    takeLatest(TRIGGER_AGENT_REVIEW_CONTACT_REQUEST, agentReviewContactRequest),
    takeLatest(TRIGGER_AGENT_REVIEW_BLOCK_REQUEST, agentReviewContactBlockRequest),
  ]);
}
