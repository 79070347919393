import {
  GET_TRANSACTIONS_LEDGER,
  POST_TRANSACTIONS_LEDGER,
  gettransactionLedgerError,
  gettransactionLedgerStart,
  gettransactionLedgerSuccess,
  postTransactionLedgerError,
  postTransactionLedgerStart,
  postTransactionLedgerSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { payload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* transactionLedgerFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(gettransactionLedgerStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(gettransactionLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(gettransactionLedgerError(error));
  }
}

function* transactionLedgerPostRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key, requestParams },
    } = actions;
    yield put(postTransactionLedgerStart());
    const response = yield call(() => makeRequest(path).post(requestBody, key, requestParams));
    yield put(postTransactionLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(postTransactionLedgerError(error));
  }
}

function* watchReviewSagas() {
  yield takeLatest(GET_TRANSACTIONS_LEDGER, transactionLedgerFetchRequest);
  yield takeLatest(POST_TRANSACTIONS_LEDGER, transactionLedgerPostRequest);
}

export default function* transactionLedgerSaga() {
  yield all([watchReviewSagas()]);
}
