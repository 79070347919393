import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const TRIGGER_REVOKE_CONTACT_TIMELINE_REQUEST = "TRIGGER_REVOKE_CONTACT_TIMELINE_REQUEST";
export const TRIGGER_REVOKE_CONTACT_TIMELINE_SUCCESS = "TRIGGER_REVOKE_CONTACT_TIMELINE_SUCCESS";
export const TRIGGER_REVOKE_CONTACT_TIMELINE_FAILURE = "TRIGGER_REVOKE_CONTACT_TIMELINE_FAILURE";

interface RevokeData {
  loading: boolean;
  data: any;
  error: any;
}

const initialState: RevokeData = {
  loading: false,
  data: {},
  error: {},
};

export const revokeProposalTimelineSlice = createSlice({
  name: "revokeProposalTimeline",
  initialState,
  reducers: {
    revokeProposalTimelineSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    revokeProposalTimelineError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    revokeProposalTimelinesOngoing: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    resetsStateTimeline: () => initialState,
  },
});

export const {
  resetsStateTimeline,
  revokeProposalTimelineSuccess,
  revokeProposalTimelineError,
  revokeProposalTimelinesOngoing,
} = revokeProposalTimelineSlice.actions;
export const revokeProposalTimelineContactById = (state: RootState) => state.revokeContactTimeline;

export default revokeProposalTimelineSlice.reducer;
