import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeLocalStore } from "../utils/localStore";
import { resetLoginState, resetLogoutState } from "app/redux";
import { clearSessionStore } from "app/utils/sessionStore";
import { AutoLogoutProps } from "app/utils/types";

const SESSION_IDEL_MINUTES = process.env.REACT_APP_LOGOUT_IDLE_MINUTES;
const AutoLogoutTimer = ({ ComposedClass }: any) => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const handleOnIdle = () => {
    removeLocalStore("useraccess");
    clearSessionStore();
    dispatch(resetLogoutState());
    dispatch(resetLoginState());
    nav("/", { replace: true });
  };

  useIdleTimer({
    timeout: 2 * 1000 * 60 * Number(SESSION_IDEL_MINUTES),
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return <ComposedClass />;
};

function areEqual(prevProps: Readonly<AutoLogoutProps>, nextProps: Readonly<AutoLogoutProps>) {
  if (prevProps?.location?.pathname === nextProps?.location?.pathname) {
    return true;
  }
  return false;
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
}

export default React.memo(AutoLogoutTimer, areEqual);
