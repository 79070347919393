import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const POST_CONFIRM_DETAILS = "POST_CONFIRM_DETAILS";

interface postConfirmDetailsInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

const initialState: postConfirmDetailsInterface = {
  data: {},
  error: {},
  loading: false,
};

export const postConfirmDetailsSlice = createSlice({
  name: "postConfirmDetails",
  initialState,
  reducers: {
    postConfirmDetailsStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    postConfirmDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    postConfirmDetailsFailure: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetState: () => initialState,
  },
});

export const {
  postConfirmDetailsStart,
  postConfirmDetailsSuccess,
  postConfirmDetailsFailure,
  resetState,
} = postConfirmDetailsSlice.actions;

export const postConfirmProfile = (state: RootState) => state.postConfirmDetails;

export default postConfirmDetailsSlice.reducer;
