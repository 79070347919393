import { payload } from "app/utils/types";
import {
  GET_POLICY_SUMMARY,
  fetchPolicySummaryStart,
  fetchPolicySummarySuccess,
  fetchPolicySummaryError,
  GET_POLICY_GRAPH,
  fetchPolicyGraphStart,
  fetchPolicyGraphSuccess,
  fetchPolicyGraphError,
  fetchBSPolicyShareholdersListStart,
  fetchBSPolicyShareholdersListSuccess,
  fetchBSPolicyShareholdersListError,
  fetchBSPolicySummaryStart,
  fetchBSPolicySummarySuccess,
  fetchBSPolicySummaryError,
  GET_BS_POLICY_SHAREHOLDERS_LIST,
  GET_BS_POLICY_SUMMARY,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* policySummaryFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchPolicySummaryStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchPolicySummarySuccess(response.data));
  } catch (error: any) {
    yield put(fetchPolicySummaryError(error));
  }
}

function* policyGraphFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchPolicyGraphStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchPolicyGraphSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPolicyGraphError(error));
  }
}

function* getBSPolicySummaryRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchBSPolicySummaryStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchBSPolicySummarySuccess(response.data));
  } catch (error: any) {
    yield put(fetchBSPolicySummaryError(error));
  }
}

function* getBSPolicyShareholdersListRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchBSPolicyShareholdersListStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchBSPolicyShareholdersListSuccess(response.data));
  } catch (error: any) {
    yield put(fetchBSPolicyShareholdersListError(error));
  }
}

function* watchPendingSagas() {
  yield takeLatest(GET_POLICY_SUMMARY, policySummaryFetchRequest);
  yield takeLatest(GET_POLICY_GRAPH, policyGraphFetchRequest);
  yield takeLatest(GET_BS_POLICY_SHAREHOLDERS_LIST, getBSPolicyShareholdersListRequest);
  yield takeLatest(GET_BS_POLICY_SUMMARY, getBSPolicySummaryRequest);
}

export default function* policySummarySaga() {
  yield all([watchPendingSagas()]);
}
