import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_ALL_PLANS_GRAPH_DATA = "TRIGGER_ALL_PLANS_GRAPH_DATA";
export const TRIGGER_PLANS_ASPER_TYPE = "TRIGGER_PLANS_ASPER_TYPE";
export const TRIGGER_PLANS_AGGREGATE = "TRIGGER_PLANS_AGGREGATE";
export const TRIGGER_ALL_PLANS_TABLE_DATA = "TRIGGER_ALL_PLANS_TABLE_DATA";
export const TRIGGER_PLANS_TYPE_STORAGE = "TRIGGER_PLANS_TYPE_STORAGE";
export const TRIGGER_PLANSBYQUATER_STORAGE = "TRIGGER_PLANSBYQUATER_STORAGE";
export const TRIGGER_PLANS_BY_AGGREGATE = "TRIGGER_PLANS_BY_AGGREGATE";

interface AllPlansGraphData {
  data: any;
  loading?: boolean;
  error?: Record<string, string | number | object> | any;
}

interface Planstype {
  planTypes: string;
}

interface PlansAggregate {
  planAggregate: string;
}

interface responseType {
  fetchAllPlansStart?: AllPlansGraphData;
  fetchAllPlansSuccess: AllPlansGraphData;
  fetchAllPlansFailure: AllPlansGraphData;
  fetchPlansAsperTypeStart?: AllPlansGraphData;
  fetchPlansAsperTypeSuccess: AllPlansGraphData;
  fetchPlansAsperTypeFailure: AllPlansGraphData;
  fetchPlansAggregateStart?: AllPlansGraphData;
  fetchPlansAggregateSuccess: AllPlansGraphData;
  fetchPlansAggregateFailure: AllPlansGraphData;
  allPlansDatafetchOnGoing: boolean;
  allPlansDatafetchSuccess: any;
  allPlansDatafetchError: any;
  planTypes: Planstype;
  plansByQuater: any;
  planAggregate: PlansAggregate;
}

const initialState: responseType = {
  fetchAllPlansStart: { data: {}, error: {} },
  fetchAllPlansSuccess: { data: {}, error: {} },
  fetchAllPlansFailure: { data: {}, error: {} },
  fetchPlansAsperTypeStart: { data: {}, error: {} },
  fetchPlansAsperTypeSuccess: { data: {}, error: {} },
  fetchPlansAsperTypeFailure: { data: {}, error: {} },
  fetchPlansAggregateStart: { data: {}, error: {} },
  fetchPlansAggregateSuccess: { data: {}, error: {} },
  fetchPlansAggregateFailure: { data: {}, error: {} },
  allPlansDatafetchOnGoing: false,
  allPlansDatafetchSuccess: {},
  allPlansDatafetchError: null,
  planTypes: { planTypes: "" },
  plansByQuater: {},
  planAggregate: { planAggregate: "" },
};

export const planGraphViewSlice = createSlice({
  name: "planGraphView",
  initialState,
  reducers: {
    fetchAllPlansStart: (state) => {
      state.fetchAllPlansStart = { data: {}, error: null, loading: true };
    },
    fetchAllPlansSuccess: (state, action: PayloadAction<responseType>) => {
      state.fetchAllPlansSuccess = { data: action.payload, error: {} };
    },
    fetchAllPlansFailure: (state, action: PayloadAction<any>) => {
      state.fetchAllPlansFailure = { data: {}, error: action.payload };
    },
    fetchPlansAsperTypeStart: (state) => {
      state.fetchPlansAsperTypeStart = { data: {}, error: null };
    },
    fetchPlansAsperTypeSuccess: (state, action: PayloadAction<responseType>) => {
      state.fetchPlansAsperTypeSuccess = { data: action.payload, error: {} };
    },
    fetchPlansAsperTypeFailure: (state, action: PayloadAction<any>) => {
      state.fetchPlansAsperTypeFailure = { data: {}, error: action.payload };
    },
    fetchPlansAggregateStart: (state) => {
      state.fetchPlansAggregateStart = { data: {}, error: null };
    },
    fetchPlansAggregateSuccess: (state, action: PayloadAction<responseType>) => {
      state.fetchPlansAggregateSuccess = { data: action.payload, error: {} };
    },
    fetchPlansAggregateFailure: (state, action: PayloadAction<any>) => {
      state.fetchPlansAggregateFailure = { data: {}, error: action.payload };
    },
    fetchPlansTableDataStart: (state) => {
      state.allPlansDatafetchOnGoing = true;
      state.allPlansDatafetchSuccess = {};
      state.allPlansDatafetchError = null;
    },
    fetchPlansTableDataSuccess: (state, action: PayloadAction<responseType>) => {
      state.allPlansDatafetchOnGoing = false;
      state.allPlansDatafetchSuccess = action.payload;
      state.allPlansDatafetchError = null;
    },
    fetchPlansTableDataFailure: (state, action: PayloadAction<any>) => {
      state.allPlansDatafetchOnGoing = false;
      state.allPlansDatafetchSuccess = {};
      state.allPlansDatafetchError = action.payload;
    },
    planTypes: (state, action: PayloadAction<any>) => {
      state.planTypes = { planTypes: action.payload };
    },
    plansAggregate: (state, action: PayloadAction<any>) => {
      state.planAggregate = { planAggregate: action.payload };
    },
    plansByQuater: (state, action: PayloadAction<any>) => {
      state.plansByQuater = { plansByQuater: action.payload };
    },
    resetFetchAllPlansState: (state) => {
      state.fetchAllPlansSuccess = { data: {}, error: null, loading: false };
      state.fetchAllPlansFailure = { data: {}, error: null, loading: false };
    },
    resetFetchAsPerTypeState: (state) => {
      state.fetchPlansAsperTypeSuccess = { data: {}, error: null, loading: false };
      state.fetchPlansAsperTypeFailure = { data: {}, error: null, loading: false };
    },
    resetFetchAggregateDataState: (state) => {
      state.fetchPlansAggregateSuccess = { data: {}, error: null, loading: false };
      state.fetchPlansAggregateFailure = { data: {}, error: null, loading: false };
    },
    resetPlanTypeState: (state) => {
      state.planTypes = { planTypes: "" };
    },
    resetPlanAggregateState: (state) => {
      state.planAggregate = { planAggregate: "" };
    },
  },
});

export const {
  fetchAllPlansStart,
  fetchAllPlansSuccess,
  fetchAllPlansFailure,
  fetchPlansAsperTypeStart,
  fetchPlansAsperTypeSuccess,
  fetchPlansAsperTypeFailure,
  fetchPlansAggregateStart,
  fetchPlansAggregateSuccess,
  fetchPlansAggregateFailure,
  fetchPlansTableDataStart,
  fetchPlansTableDataSuccess,
  fetchPlansTableDataFailure,
  planTypes,
  plansByQuater,
  plansAggregate,
  resetFetchAllPlansState,
  resetFetchAsPerTypeState,
  resetFetchAggregateDataState,
  resetPlanTypeState,
  resetPlanAggregateState,
} = planGraphViewSlice.actions;

export const getAllPlansGraphViewData = (state: RootState) => state.allPlansGraphViewData;

export default planGraphViewSlice.reducer;
