import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface State {
  userData: any;
  userError: any;
  userLoading: any;
  agentsData: any;
  agentsError: any;
  agentsLoading: any;
  delAgent: any;
  delAgentLoading: any;
  delAgentError: any;
}

const initialState: State = {
  userData: {},
  userError: {},
  userLoading: false,
  agentsData: {},
  agentsError: {},
  agentsLoading: false,
  delAgent: {},
  delAgentLoading: false,
  delAgentError: {},
};

export const fetchUsers = createSlice({
  name: "fetchUsers",
  initialState,
  reducers: {
    setFetchAgentsSuccess: (state, action: PayloadAction<any[]>) => {
      state.agentsData = action.payload;
      state.agentsLoading = false;
    },
    setFetchAgentsFailure: (state, action: PayloadAction<string>) => {
      state.agentsLoading = false;
      state.agentsError = action.payload;
    },
    setFetchAgentsOngoing: (state) => {
      state.agentsData = {};
      state.agentsLoading = true;
      state.agentsError = {};
    },
    setFetchUsersSuccess: (state, action: PayloadAction<any[]>) => {
      state.userData = action.payload;
      state.userLoading = false;
      state.userError = {};
    },
    setFetchUsersFailure: (state, action: PayloadAction<string>) => {
      state.userData = {};
      state.userLoading = false;
      state.userError = action.payload;
    },
    setFetchUsersOngoing: (state) => {
      state.userData = {};
      state.userLoading = true;
      state.userError = {};
    },
    setResetState: (state) => {
      state.userData = {};
      state.userLoading = false;
      state.userError = {};
    },
  },
});

export const delAgent = createSlice({
  name: "deleteAgent",
  initialState,
  reducers: {
    setDelAgentSuccess: (state, action: PayloadAction<any[]>) => {
      state.delAgent = action.payload;
      state.delAgentLoading = false;
      state.delAgentError = {};
    },
    setDelAgentError: (state, action: PayloadAction<string>) => {
      state.delAgent = {};
      state.delAgentLoading = false;
      state.delAgentError = action.payload;
    },
    setDelAgentOngoing: (state) => {
      state.delAgent = {};
      state.delAgentLoading = true;
      state.delAgentError = {};
    },
    setResetState: (state) => {
      state.delAgent = {};
      state.delAgentLoading = false;
      state.delAgentError = {};
    },
    resetState: () => initialState,
  },
});

export const {
  setFetchUsersSuccess,
  setFetchUsersFailure,
  setFetchUsersOngoing,
  setFetchAgentsOngoing,
  setFetchAgentsSuccess,
  setFetchAgentsFailure,
} = fetchUsers.actions;

export const { setDelAgentSuccess, setDelAgentError, setDelAgentOngoing, resetState } =
  delAgent.actions;

export const deleteAgent = (state: RootState) => state.delAgents;
export const getFetchUsers = (state: RootState) => state.fetchUsers;

export const delAgentReducer = delAgent.reducer;
export const fetchUsersReducer = fetchUsers.reducer;
