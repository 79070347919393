import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const CREATE_BS_PLAN = "CREATE_BS_PLAN";

interface intialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

const initialState: intialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

export const createBSPlan = createSlice({
  name: "createBSPlan",
  initialState,
  reducers: {
    createBSPlanStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    createBSPlanSucess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    createBSPlanError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetCreateBSPlanState: () => initialState,
  },
});

export const { createBSPlanStart, createBSPlanSucess, createBSPlanError, resetCreateBSPlanState } =
  createBSPlan.actions;

export const createBsPlanState = (state: RootState) => state.createBsPlan;

export default createBSPlan.reducer;
