import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  resetPasswordReducer,
  getPlans,
  getToken,
  getVerifyAccessToken,
  getBlockToken,
  getBlockVerifyState,
  getContactInformation,
  getReviewContactInformation,
  inviteEmailReducer,
  fetchUsersReducer,
  getEmail,
  getCreateProposalContactInfo,
  getCreateBuySellProposal,
  logoutResponseState,
  agentBrandingReducer,
  allAgentsReducer,
  allAgenciesReducer,
  pendingAgentsReducer,
  getRequestProposalInfo,
  TRIGGER_AGENT_BRANDING_PRIMARY_LOGO_UPLOAD,
  TRIGGER_AGENT_BRANDING_SECONDARY_LOGO_UPLOAD,
  postConfirmProfile,
  priotitesStepperReducer,
  requestProposalDesignFormReducer,
  proposalsReducer,
  resendInviteReducer,
  resendResetPassReducer,
  ContactProfileLookupReducer,
  contactBrandingReducer,
  allDashboardReducer,
  contactfinancialInfoReducer,
  sendFinancialInfoReducer,
  patchCSVUploadReducer,
  insuranceCompaniesReducer,
  loanPlanProjectionReducer,
  postCSVDataReducer,
  contactHealthInfoReducer,
  getPlanAdminSplitDollarList,
  getPDFReport,
  sendProposalReducer,
  createProposalReducer,
  adminProfileReducer,
  getPlanSplitDollarList,
  getAdminAgentsSliceList,
  getAgentBillingSliceList,
  getAgentCommisionState,
  revokeContactTimelineReducer,
  proposalReviewStore,
  getAgentProfileReducer,
  getAllPendingProposals,
  reqSignReducer,
  proposalActionReviewReducer,
  proposalsUploadSignedReportReducer,
  adminPendingApprovalReducer,
  duplicateProposalIdReducer,
  proposalsApprovedPendingReducer,
  allIsAgencyManagerActiveReducer,
  uploadDocumentReducer,
  deleteUploadDocumentReducer,
  getPlanFinalizationReducer,
  uploadAdditionalReqDocumentReducer,
  sendFileToContactReducer,
  updateDownloadFileStatusReducer,
  getAllPlansGraphViewData,
  planPast30DaysReducer,
  planPastDuesReducer,
  planNext30DaysReducer,
  planNext45DaysReducer,
  getConciergeServiceSliceList,
  getPolicySummaryReducer,
  getViewPolicyDetailsReducer,
  putViewPolicyDetailsReducer,
  navigatedFromReducer,
  getPlanSummary,
  pendingDocumentListReducer,
  getInsuranceCompanyState,
  planReviewReducer,
  getCommissionPendingApprovalReducer,
  getViewCommissionTrackingReducer,
  getAgencyCommissionState,
  getInsuranceProductState,
  subscriptionCancelReducer,
  saveViewCommissionTrackingReducer,
  getViewLoanDetailsReducer,
  putViewLoanDetailsReducer,
  agencyCommissionReportReducer,
  getLoanSummaryReducer,
  reportReducer,
  afrReducer,
  buySellReducer,
  fetchProvisionDetails,
  proposedPolicyLifeLedgerReducer,
  proposedPolicyDisabilityLedgerReducer,
  shareHolderLedgerReducer,
  disabilityInsuranceReducer,
  lifeInsuranceUnderwritingSliceReducer,
  createBsPlanReducer,
  getPolicyReview,
  valuationContributionReviewReducer,
  triggerEventsReducer,
  transactionLedgerReducer,
  getContacts,
  setLoanAFRReducer,
  agentListingReducer,
  agencyAgentsCountsReducer,
} from "@reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { inviteAgencyReducer } from "app/redux/features/agency-invite";
import { delAgentReducer } from "app/redux/features/users";
import revokeContactByIdReducer from "app/redux/features/revokeContactById";
import { allIsFormActiveReducer } from "app/redux/features/IsFormActive";
import storage from "redux-persist/lib/storage";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    "allIsFormActive",
    "storeIsAgencyManagerActiveData",
    "navigatedFromReducer",
    "conciergeServiceSliceList",
    "getBuySellPoposalInfo",
    "getPolicySummary",
  ],
};

const rootReducer = combineReducers({
  allIsFormActive: allIsFormActiveReducer,
  storeIsAgencyManagerActiveData: allIsAgencyManagerActiveReducer,
  agentContact: getContactInformation,
  token: getToken,
  generatedToken: getVerifyAccessToken,
  agentReviewContact: getReviewContactInformation,
  blockToken: getBlockToken,
  blockVerifyToken: getBlockVerifyState,
  getPlansList: getPlans,
  planSplitDollarList: getPlanSplitDollarList,
  resetPassword: resetPasswordReducer,
  inviteEmail: inviteEmailReducer,
  resendInvite: resendInviteReducer,
  fetchUsers: fetchUsersReducer,
  inviteAgency: inviteAgencyReducer,
  getEmailProfile: getEmail,
  postConfirmDetails: postConfirmProfile,
  getAgentBrandingDetails: agentBrandingReducer,
  logout: logoutResponseState,
  getAllAgents: allAgentsReducer,
  getAllAgencies: allAgenciesReducer,
  getAllPendingAgents: pendingAgentsReducer,
  prioritiesStepper: priotitesStepperReducer,
  requestProposalDesignForm: requestProposalDesignFormReducer,
  getRequestedProposalInfo: getRequestProposalInfo,
  delAgents: delAgentReducer,
  proposals: proposalsReducer,
  resetPasswordResend: resendResetPassReducer,
  contactProfileLookup: ContactProfileLookupReducer,
  contactBranding: contactBrandingReducer,
  storedashboardData: allDashboardReducer,
  storeIsFormActiveData: allIsFormActiveReducer,
  // storeIsAgencyManagerActiveData: allIsAgencyManagerActiveReducer,
  contactFinancialInfo: contactfinancialInfoReducer,
  sendFinancialInfo: sendFinancialInfoReducer,
  policyInputBaseCSVUpload: patchCSVUploadReducer,
  policyInputBaseCSVData: postCSVDataReducer,
  insuranceCompanies: insuranceCompaniesReducer,
  LoanPlanProjection: loanPlanProjectionReducer,
  contactHealthInfo: contactHealthInfoReducer,
  createBuySellProposalData: getCreateBuySellProposal,
  creatProposalData: getCreateProposalContactInfo,
  revokeContactById: revokeContactByIdReducer,
  revokeContactTimeline: revokeContactTimelineReducer,
  reviewDuplicateProposalId: duplicateProposalIdReducer,
  planAdminSplitDollarList: getPlanAdminSplitDollarList,
  getCreateProposalPdf: getPDFReport,
  createProposal: createProposalReducer,
  sendProposal: sendProposalReducer,
  adminPendingApproval: adminPendingApprovalReducer,
  getAdminProfile: adminProfileReducer,
  agentProfile: getAgentProfileReducer,
  adminAgentsSliceList: getAdminAgentsSliceList,
  agentBillingSliceList: getAgentBillingSliceList,
  conciergeServiceSliceList: getConciergeServiceSliceList,
  reviewProposalSaga: proposalReviewStore,
  agentCommission: getAgentCommisionState,
  getPendingProposals: getAllPendingProposals,
  reqSign: reqSignReducer,
  proposalActionReview: proposalActionReviewReducer,
  proposalsUploadSignedReport: proposalsUploadSignedReportReducer,
  proposalsApprovedPending: proposalsApprovedPendingReducer,
  uploadDocument: uploadDocumentReducer,
  uploadAdditionalReqDocument: uploadAdditionalReqDocumentReducer,
  deleteUploadDocument: deleteUploadDocumentReducer,
  getPlanFinalization: getPlanFinalizationReducer,
  sendFileToContact: sendFileToContactReducer,
  updateDownloadFileStatus: updateDownloadFileStatusReducer,
  allPlansGraphViewData: getAllPlansGraphViewData,
  planPast30Days: planPast30DaysReducer,
  planPastDues: planPastDuesReducer,
  planNext30Days: planNext30DaysReducer,
  planNext45Days: planNext45DaysReducer,
  getPolicySummary: getPolicySummaryReducer,
  getViewPolicyDetails: getViewPolicyDetailsReducer,
  putViewPolicyDetails: putViewPolicyDetailsReducer,
  getViewLoanDetails: getViewLoanDetailsReducer,
  putViewLoanDetails: putViewLoanDetailsReducer,
  navigatedFromReducer,
  planSummary: getPlanSummary,
  loanSummary: getLoanSummaryReducer,
  pendingDocumentList: pendingDocumentListReducer,
  insuranceCompany: getInsuranceCompanyState,
  planReview: planReviewReducer,
  commissionPendingApproval: getCommissionPendingApprovalReducer,
  getViewCommissionTracking: getViewCommissionTrackingReducer,
  agencyCommission: getAgencyCommissionState,
  insuranceProduct: getInsuranceProductState,
  subscriptionCancelDetails: subscriptionCancelReducer,
  saveViewCommissionTracking: saveViewCommissionTrackingReducer,
  agencyCommissionReport: agencyCommissionReportReducer,
  report: reportReducer,
  afrdetails: afrReducer,
  provisionDetails: fetchProvisionDetails,
  getBuySellPoposalInfo: buySellReducer,
  proposedLifePolicyLedger: proposedPolicyLifeLedgerReducer,
  porposedDisabilityPolicyLedger: proposedPolicyDisabilityLedgerReducer,
  shareholderLedgerSaga: shareHolderLedgerReducer,
  disabilityInsuranceData: disabilityInsuranceReducer,
  lifeInsuranceUnderwriting: lifeInsuranceUnderwritingSliceReducer,
  createBsPlan: createBsPlanReducer,
  policyReview: getPolicyReview,
  valuationContributionReview: valuationContributionReviewReducer,
  triggerEvents: triggerEventsReducer,
  setLoanAFR: setLoanAFRReducer,
  transactionLedgerSaga: transactionLedgerReducer,
  agentListing: agentListingReducer,
  agencyAgentsCounts: agencyAgentsCountsReducer,
  contacts: getContacts,
});

const sagaMiddleware = createSagaMiddleware();
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          TRIGGER_AGENT_BRANDING_PRIMARY_LOGO_UPLOAD,
          TRIGGER_AGENT_BRANDING_SECONDARY_LOGO_UPLOAD,
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
        ],
      },
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

// NOTE: Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
