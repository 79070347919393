import { TRIGGER_AGENT_CONTACT_REQUEST } from "@reducers";

const TriggerAgentContactDataRequest = (
  path: string,
  key: any,
  requestParams: boolean,
  queryParams: boolean,
) => ({
  type: TRIGGER_AGENT_CONTACT_REQUEST,
  payload: {
    address: path,
    key,
    requestParams,
    queryParams,
  },
});
export default TriggerAgentContactDataRequest;
