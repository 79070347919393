import { setResendInviteFailure, setResendInviteOngoing, setResendInviteSuccess } from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* sendResendInvite(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setResendInviteOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setResendInviteSuccess(response.data));
  } catch (error: any) {
    yield put(setResendInviteFailure(error));
  }
}

function* watchResendInvite() {
  yield takeLatest("TRIGGER_RESEND_INVITE", sendResendInvite);
}

export default function* resendInviteSaga() {
  yield all([watchResendInvite()]);
}
