import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_PLAN_SPLIT_DOLLAR_LIST_REQUEST = "TRIGGER_PLAN_SPLIT_DOLLAR_LIST_REQUEST";

interface PlanSplitDollarInterface {
  data: any;
  loading: boolean;
  error: Record<string, string | number | object> | null;
}

const initialState: PlanSplitDollarInterface = {
  data: [],
  loading: false,
  error: null,
};

export const planSplitDollarSlice = createSlice({
  name: "planSplitDollar",
  initialState,
  reducers: {
    fetchDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchDataFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetPlanSplitDollarState: () => initialState,
  },
});

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, resetPlanSplitDollarState } =
  planSplitDollarSlice.actions;

export const getPlanSplitDollarList = (state: RootState) => state.planSplitDollarList;

export default planSplitDollarSlice.reducer;
