import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_AGENT_REVIEW_CONTACT_REQUEST = "TRIGGER_AGENT_REVIEW_CONTACT_REQUEST";
export const TRIGGER_AGENT_REVIEW_BLOCK_REQUEST = "TRIGGER_AGENT_REVIEW_BLOCK_REQUEST";

interface Success {
  data: any;
  loading?: boolean;
}

interface SuccessData {
  data: {
    data: {
      basicDetails: {
        proposalType: string;
        contactName: string;
        yearlyIncome: string;
        netWorth: string;
        isSamePerson: string;
      };
      insuredDetails: {
        insurerName: string;
        dob: string;
        height: string;
        weight: string;
        medicalCondition: string;
        regularMedication: string;
        insuranceCompany: string;
        priorities: string;
        nicotine: string;
      };
      planDetails: {
        typeOfPlan: string;
        annualLoanAmt: string;
        loanFundDur: string;
        interestPayHandle: string;
        payOutOfPocket: string;
        paymentDura: string;
        minDeathBen: string;
        waiveSurCharge: string;
      };
      status: string;
      message: string;
      priorities: string;
    };
    state: boolean;
  };
}

interface ErrorType {
  error?: Error;
  loading?: boolean;
  message?: string | null;
}

interface responseType {
  agentReviewContactSuccess: SuccessData | null;
  agentReviewContactError: ErrorType;
  agentReviewContactBlockSuccess: Success;
  agentReviewContactBlockError: ErrorType;
  agentReviewContactBasicInfoEditSuccess: Success;
  agentReviewContactBasicInfoEditError: ErrorType;
}

const initialState: responseType = {
  agentReviewContactSuccess: null,
  agentReviewContactError: { message: "" },
  agentReviewContactBlockSuccess: { data: {} },
  agentReviewContactBlockError: { message: "" },
  agentReviewContactBasicInfoEditSuccess: { data: {} },
  agentReviewContactBasicInfoEditError: { message: "" },
};

export const agentReviewContactSlice = createSlice({
  name: "agentReviewContact",
  initialState,
  reducers: {
    agentReviewContactSuccess: (state, action: PayloadAction<any>) => {
      state.agentReviewContactSuccess = { data: action.payload };
    },
    reviewContactBlockSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentReviewContactBlockSuccess = { data: action.payload };
    },
    reviewContactBlockError: (state, action: PayloadAction<Error>) => {
      state.agentReviewContactBlockError = { error: action.payload };
    },
    agentReviewContactError: (state, action: PayloadAction<Error>) => {
      state.agentReviewContactError = { error: action.payload };
    },
    agentReviewContactBasicInfoEditSuccess: (state, action: PayloadAction<responseType>) => {
      state.agentReviewContactBasicInfoEditSuccess = { data: action.payload };
    },
    resetagentReviewContactState: () => initialState,
  },
});

export const {
  resetagentReviewContactState,
  agentReviewContactSuccess,
  reviewContactBlockSuccess,
  reviewContactBlockError,
  agentReviewContactError,
} = agentReviewContactSlice.actions;
export const getReviewContactInformation = (state: RootState) => state.agentReviewContact;

export default agentReviewContactSlice.reducer;
