import {
  TRIGGER_PLANS_LIST_REQUEST,
  fetchDataSuccess,
  fetchDataFailure,
  TRIGGER_PLAN_CHANGE_REQUEST,
  TRIGGER_PAYMENT_STATUS_UPDATE_REQUEST,
  fetchDataStart,
} from "../../redux";
import { PlansActionPayload } from "../../utils/types";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";

function* plansListRequest(actions: PlansActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;
    yield put(fetchDataStart());
    const response = yield call(() => makeRequest(path).get());
    yield put(fetchDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchDataFailure(error));
  }
}

function* changePlanRequest(actions: PlansActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(fetchDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchDataFailure(error.message));
  }
}

function* paymentUpdateRequest(actions: PlansActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(fetchDataStart());
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(fetchDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchDataFailure(error));
  }
}

function* watchPlans() {
  yield takeLatest(TRIGGER_PLANS_LIST_REQUEST, plansListRequest);
  yield takeLatest(TRIGGER_PLAN_CHANGE_REQUEST, changePlanRequest);
  yield takeLatest(TRIGGER_PAYMENT_STATUS_UPDATE_REQUEST, paymentUpdateRequest);
}

export default function* plansSaga() {
  yield all([watchPlans()]);
}
