import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_PUT_SUBSCRIPTION_CANCEL = "TRIGGER_PUT_SUBSCRIPTION_CANCEL";

interface SubscriptionCancelInterface {
  data: any;
  loading: boolean;
  error: Record<string, string | number | object> | null;
}

const initialState: SubscriptionCancelInterface = {
  data: [],
  loading: false,
  error: {},
};

export const putSubscriptionCancelSlice = createSlice({
  name: "putSubscriptionCancel",
  initialState,
  reducers: {
    fetchSubscriptionCancelStart: (state) => {
      state.loading = true;
      state.error = {};
    },
    fetchSubscriptionCancelSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchSubscriptionCancelFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetSubscriptionCancelState: () => initialState,
  },
});

export const {
  fetchSubscriptionCancelStart,
  fetchSubscriptionCancelSuccess,
  fetchSubscriptionCancelFailure,
  resetSubscriptionCancelState,
} = putSubscriptionCancelSlice.actions;

export const putSubscriptionCancel = (state: RootState) => state.subscriptionCancelDetails;

export default putSubscriptionCancelSlice.reducer;
