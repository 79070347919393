import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const CONTACT_PROFILE_LOOKUP = "CONTACT_PROFILE_LOOKUP";

interface contactProfileLookup {
  isLoading: boolean;
  data: any;
  error: any;
}

const initialState: contactProfileLookup = {
  isLoading: false,
  data: {},
  error: {},
};

const contactProfileLookupSlice = createSlice({
  name: "contactProfileLookup",
  initialState,
  reducers: {
    contactProfileLookupStart: (state: contactProfileLookup) => {
      state.isLoading = true;
      state.data = {};
      state.error = {};
    },
    contactProfileLookupSuccess: (state: contactProfileLookup, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    },
    contactProfileLookupFailure: (state: contactProfileLookup, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    },
    resetContactProfileLookup: (state: contactProfileLookup) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
});

export const {
  contactProfileLookupStart,
  contactProfileLookupSuccess,
  contactProfileLookupFailure,
  resetContactProfileLookup,
} = contactProfileLookupSlice.actions;

export const contactProfileLookUP = (state: RootState) => state.contactProfileLookup;

export default contactProfileLookupSlice.reducer;
