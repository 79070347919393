import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { RootState } from "app/store";

const initialState = {
  primaryColor: "",
  primaryLogo: "",
  secondaryColor: "",
  secondaryLogo: "",
  fontsize: "",
  theme: "",
};

const contactBrandingSlice = createSlice({
  name: "contactBranding",
  initialState,
  reducers: {
    setPrimaryColor: (state: any, action: PayloadAction<any>) => {
      state.primaryColor = action.payload;
    },
    setSecondaryColor: (state: any, action: PayloadAction<any>) => {
      state.secondaryLogo = action.payload;
    },
    setPrimaryLogo: (state: any, action: PayloadAction<any>) => {
      state.primaryLogo = action.payload;
    },
    setSecondaryLogo: (state: any, action: PayloadAction<any>) => {
      state.secondaryLogo = action.payload;
    },
    setFontSize: (state: any, action: PayloadAction<any>) => {
      state.fontSize = action.payload;
    },
    updateTheme: (state: any, action: PayloadAction<any>) => {
      state.theme = action.payload;
    },
  },
});

export const {
  setPrimaryColor,
  setSecondaryColor,
  setPrimaryLogo,
  setSecondaryLogo,
  setFontSize,
  updateTheme,
} = contactBrandingSlice.actions;

// export const contactBranding = (state: RootState) => state.contactBranding;
export default contactBrandingSlice.reducer;
