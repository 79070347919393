import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const BASICINFO_STORE_DATA = "BASICINFO_STORE_DATA";
export const INSUREDINFO_STORE_DATA = "INSUREDINFO_STORE_DATA";
export const NOT_INSURED_PERSON_STORE_DATA = "NOT_INSURED_PERSON_STORE_DATA";
export const PROPOSAL_TYPES_STORE_DATA = "PROPOSAL_TYPES_STORE_DATA";

interface BasicInfo {
  data: {
    proposalType: {
      id: string;
      name: string;
      proposalPlantypes: [
        {
          id: string;
          name: string;
        },
      ];
    };
    primaryContact: string;
  };
}

interface GetProposalTypes {
  data: any;
  error: object;
}
interface InsuredInfo {
  data: {
    isInsurerSamePerson: string;
    firstName: string;
    lastName: string;
    dob: string;
    height: {
      name: string;
    };
    inches: {
      name: string;
    };
    weight: {
      name: string;
    };
    medicalConditions: {
      name: string;
      code: string;
    };
    regularConditions: string;
    nicotinOrTobaccoUsage: {
      name: string;
      code: string;
    };
    insuranceCompany: {
      name: string;
      code: string;
    };
    insuranceCompanyNo: {
      name: string;
      code: string;
    };
  };
}

interface DesignInfo {
  data: {
    typeOfPlan: string;
    priorities: string;
    legalNameOfEmployer: string;
    employerPremium: string;
    employerLoanFundingDuration: string;
    employeePremium: string;
    employeeLoanFundingDuration: string;
    willEmployeeMakeInterestPaymentsToEmployer: string;
    whatLoanRateYouWantToFollow: string;
    whenDoesEmployerWantToGetRepaid: string;
    deathBenefit: string;
    waiveSurrenderCharges: string;
    whenDoesClientWantToSeeIncome: string;
    note: string;
  };
}

interface DataStored {
  basicInfo: BasicInfo;
  design: DesignInfo;
  notInsuredPerson: InsuredInfo;
  proposalType: GetProposalTypes;
}

const initialState: DataStored = {
  basicInfo: {
    data: {
      proposalType: {
        id: "",
        name: "",
        proposalPlantypes: [
          {
            id: "",
            name: "",
          },
        ],
      },
      primaryContact: "",
    },
  },
  notInsuredPerson: {
    data: {
      isInsurerSamePerson: "yes",
      firstName: "",
      lastName: "",
      dob: "",
      height: {
        name: "",
      },
      inches: {
        name: "",
      },
      weight: {
        name: "",
      },
      medicalConditions: {
        name: "",
        code: "",
      },
      regularConditions: "",
      nicotinOrTobaccoUsage: {
        name: "",
        code: "",
      },
      insuranceCompany: {
        name: "",
        code: "",
      },
      insuranceCompanyNo: {
        name: "",
        code: "",
      },
    },
  },
  design: {
    data: {
      typeOfPlan: "",
      priorities: "",
      legalNameOfEmployer: "",
      employerPremium: "",
      employerLoanFundingDuration: "",
      employeePremium: "",
      employeeLoanFundingDuration: "",
      willEmployeeMakeInterestPaymentsToEmployer: "",
      whatLoanRateYouWantToFollow: "",
      whenDoesEmployerWantToGetRepaid: "",
      deathBenefit: "",
      waiveSurrenderCharges: "",
      whenDoesClientWantToSeeIncome: "",
      note: "",
    },
  },
  proposalType: {
    data: [],
    error: {},
  },
};

export const requestProposalDataStore = createSlice({
  name: "requestProposalData",
  initialState,
  reducers: {
    getBasicInfo: (state, action: PayloadAction<any>) => {
      state.basicInfo = { data: action.payload };
    },
    getNotInsuredPersonInfo: (state, action: PayloadAction<any>) => {
      state.notInsuredPerson = { data: action.payload };
    },
    getProposalTypeSuccess: (state, action: PayloadAction<any>) => {
      state.proposalType = { data: action.payload, error: {} };
    },
    getProposalTypeFailure: (state, action: PayloadAction<any>) => {
      state.proposalType = { data: [], error: action.payload };
    },
    resetInsuredDefaultvalue: () => initialState,
  },
});

export const {
  getBasicInfo,
  getNotInsuredPersonInfo,
  resetInsuredDefaultvalue,
  getProposalTypeFailure,
  getProposalTypeSuccess,
} = requestProposalDataStore.actions;

export const getRequestProposalInfo = (state: RootState) => state.getRequestedProposalInfo;

export default requestProposalDataStore.reducer;
