import {
  setGetAdminProfileSuccess,
  setGetAdminProfileFailure,
  setAdminProfileOngoing,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getAdminProfile(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setAdminProfileOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setGetAdminProfileSuccess(response.data));
  } catch (e: any) {
    yield put(setGetAdminProfileFailure(e));
  }
}
function* watchGetAdminProfile() {
  yield takeLatest("TRIGGER_GET_ADMIN_PROFILE", getAdminProfile);
}
export default function* getAdminProfileSaga() {
  yield all([watchGetAdminProfile()]);
}
