import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const POLICY_INPUTBASE_GET_CSVDATA = "POLICY_INPUTBASE_GET_CSVDATA";

interface intialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

const initialState: intialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

export const postCSVData = createSlice({
  name: "postCSVData",
  initialState,
  reducers: {
    postCSVDataStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    postCSVDataSucess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    postCSVDataError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetpostCSVDataState: () => initialState,
  },
});

export const { postCSVDataStart, postCSVDataSucess, postCSVDataError, resetpostCSVDataState } =
  postCSVData.actions;

export const getPolicyInputBaseCSVData = (state: RootState) => state.policyInputBaseCSVData;

export default postCSVData.reducer;
