import {
  TRIGGER_ALL_PLANS_GRAPH_DATA,
  TRIGGER_PLANS_ASPER_TYPE,
  TRIGGER_PLANS_TYPE_STORAGE,
  TRIGGER_PLANS_AGGREGATE,
  TRIGGER_PLANSBYQUATER_STORAGE,
  TRIGGER_ALL_PLANS_TABLE_DATA,
  TRIGGER_PLANS_BY_AGGREGATE,
  planTypes,
  plansByQuater,
  plansAggregate,
  fetchAllPlansStart,
  fetchAllPlansSuccess,
  fetchAllPlansFailure,
  fetchPlansAsperTypeStart,
  fetchPlansAsperTypeSuccess,
  fetchPlansAsperTypeFailure,
  fetchPlansAggregateStart,
  fetchPlansAggregateSuccess,
  fetchPlansAggregateFailure,
  fetchPlansTableDataStart,
  fetchPlansTableDataSuccess,
  fetchPlansTableDataFailure,
} from "@reducers";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";

interface ActionPayload {
  type: string;
  payload: {
    address: string;
    key: string;
    requestParams?: boolean;
    queryParams?: boolean;
  };
}

function* getAllPlansGraphViewData(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;
    yield put(fetchAllPlansStart());
    const response = yield call(() => makeRequest(path).get());
    yield put(fetchAllPlansSuccess(response.data));
  } catch (error: any) {
    yield put(fetchAllPlansFailure(error));
  }
}

function* getPlansAsPerType(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, requestParams },
    } = actions;
    yield put(fetchPlansAsperTypeStart());
    const response = yield call(() => makeRequest(path).get(type, requestParams));
    yield put(fetchPlansAsperTypeSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPlansAsperTypeFailure(error));
  }
}

function* getPlansAggregate(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, requestParams },
    } = actions;
    yield put(fetchPlansAggregateStart());
    const response = yield call(() => makeRequest(path).get(type, requestParams));
    yield put(fetchPlansAggregateSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPlansAggregateFailure(error));
  }
}

function* getPlansTableView(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, requestParams, queryParams },
    } = actions;
    yield put(fetchPlansTableDataStart());
    const response = yield call(() => makeRequest(path).get(type, requestParams, queryParams));
    yield put(fetchPlansTableDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPlansTableDataFailure(error));
  }
}

function* storePlansType(action: any) {
  const { body } = action.payload;
  yield put(planTypes(body));
}

function* storePlansByQuater(action: any) {
  const { body } = action.payload;
  yield put(plansByQuater(body));
}

function* storePlanAggregate(action: any) {
  const { body } = action.payload;
  yield put(plansAggregate(body));
}

function* watchFetchAllPlansGraphView() {
  yield takeLatest(TRIGGER_ALL_PLANS_GRAPH_DATA, getAllPlansGraphViewData);
  yield takeLatest(TRIGGER_PLANS_ASPER_TYPE, getPlansAsPerType);
  yield takeLatest(TRIGGER_PLANS_AGGREGATE, getPlansAggregate);
  yield takeLatest(TRIGGER_PLANS_TYPE_STORAGE, storePlansType);
  yield takeLatest(TRIGGER_PLANSBYQUATER_STORAGE, storePlansByQuater);
  yield takeLatest(TRIGGER_ALL_PLANS_TABLE_DATA, getPlansTableView);
  yield takeLatest(TRIGGER_PLANS_BY_AGGREGATE, storePlanAggregate);
}

export default function* watchAllPlansGraphViewSaga() {
  yield all([watchFetchAllPlansGraphView()]);
}
