import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

interface ResendInviteInterface {
  data: any;
  error: any;
  loading: boolean;
}

const initialState: ResendInviteInterface = {
  data: {},
  error: {},
  loading: false,
};

export const resendInvite = createSlice({
  name: "resendInvite",
  initialState,
  reducers: {
    setResendInviteSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
      state.loading = false;
    },
    setResendInviteFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setResendInviteOngoing: (state) => {
      state.data = {};
      state.loading = true;
      state.error = {};
    },
    resetState: () => initialState,
  },
});

export const {
  setResendInviteSuccess,
  setResendInviteFailure,
  setResendInviteOngoing,
  resetState,
} = resendInvite.actions;
export const getResendInviteStatus = (state: RootState) => state.resendInvite;

export const resendInviteReducer = resendInvite.reducer;
