import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_AGENTS_LIST = "GET_AGENTS_LIST";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const agentListingSlice = createSlice({
  name: "agentListing",
  initialState,
  reducers: {
    agentListingStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    agentListingSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    agentListingError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetAgentListingState: () => initialState,
  },
});

export const { agentListingStart, agentListingSuccess, agentListingError, resetAgentListingState } =
  agentListingSlice.actions;

export const agentListing = (state: RootState) => state.agentListing;
export default agentListingSlice.reducer;
