import { blockToken, blockTokenError, TRIGGER_BLOCK_API_REQUEST } from "@reducers";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import { BlockTokenRequestBody } from "app/redux/features/block/action";

interface BlockTokenActionPayload {
  type: string;
  payload: {
    address: string;
    body: BlockTokenRequestBody;
  };
}

function* blockTokenRequest(actions: BlockTokenActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(blockToken(response.data));
  } catch (error: any) {
    yield put(blockTokenError(error));
  }
}
export default function* blockSaga() {
  yield all([takeLatest(TRIGGER_BLOCK_API_REQUEST, blockTokenRequest)]);
}
