import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const BS_BASICINFO_STORE_DATA = "BS_BASICINFO_STORE_DATA";
export const BS_SHAREHOLDER_STORE_DATA = "BS_SHAREHOLDER_STORE_DATA";
export const BS_DESIGN_DATA = "BS_DESIGN_STORE_DATA";
export const BS_PROPOSAL_TYPES_STORE_DATA = "BS_PROPOSAL_TYPES_STORE_DATA";
interface BasicInfo {
  data: any;
}
interface DesignInfo {
  data: any;
}
interface Shareholder {
  data: any;
}

interface DataStored {
  basicInfo: BasicInfo;
  design: DesignInfo;
  shareholder: Shareholder;
}

const initialState: DataStored = {
  basicInfo: {
    data: {},
  },
  design: {
    data: {},
  },
  shareholder: {
    data: {},
  },
};

export const buySellSlice = createSlice({
  name: "buySellSlice",
  initialState,
  reducers: {
    getBSBasicInfo: (state, action: PayloadAction<any>) => {
      state.basicInfo = { data: action.payload };
    },
    getShareholderInfo: (state, action: PayloadAction<any>) => {
      state.shareholder = { data: action.payload };
    },
    getDesignInfo: (state, action: PayloadAction<any>) => {
      state.design = { data: action.payload };
    },
    resetBuySellRequestProposalData: () => initialState,
  },
});

export const {
  getBSBasicInfo,
  getShareholderInfo,
  getDesignInfo,
  resetBuySellRequestProposalData,
} = buySellSlice.actions;
export const getBuySellProposalInfo = (state: RootState) => state.getBuySellPoposalInfo;

export default buySellSlice.reducer;
