import {
  postConfirmDetailsFailure,
  postConfirmDetailsStart,
  postConfirmDetailsSuccess,
  POST_CONFIRM_DETAILS,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* postConfirmDetails(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(postConfirmDetailsStart());
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(postConfirmDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(postConfirmDetailsFailure(error));
  }
}
function* watchPostProfile() {
  yield takeLatest(POST_CONFIRM_DETAILS, postConfirmDetails);
}

export default function* watchPostConfirmDetailsSaga() {
  yield all([watchPostProfile()]);
}
