import {
  getViewPolicyDetailsStart,
  getViewPolicyDetailsSuccess,
  getViewPolicyDetailsError,
  GET_VIEW_POLICY_DETAILS,
  putViewPolicyDetailsStart,
  putViewPolicyDetailsSuccess,
  putViewPolicyDetailsError,
  PUT_VIEW_POLICY_DETAILS,
  getViewCommissionTrackingStart,
  getViewCommissionTrackingSuccess,
  getViewCommissionTrackingError,
  GET_VIEW_COMMISSION_TRACKING,
  saveViewCommissionTrackingStart,
  saveViewCommissionTrackingSuccess,
  saveViewCommissionTrackingError,
  SAVE_VIEW_COMMISSION_TRACKING,
  getViewLoanDetailsStart,
  getViewLoanDetailsSuccess,
  getViewLoanDetailsError,
  GET_VIEW_LOAN_DETAILS,
  putViewLoanDetailsStart,
  putViewLoanDetailsSuccess,
  putViewLoanDetailsError,
  PUT_VIEW_LOAN_DETAILS,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* getViewPolicyDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(getViewPolicyDetailsStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(getViewPolicyDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(getViewPolicyDetailsError(error));
  }
}

function* putViewPolicyDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(putViewPolicyDetailsStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(putViewPolicyDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(putViewPolicyDetailsError(error));
  }
}
function* getViewCommissionTrackingRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(getViewCommissionTrackingStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(getViewCommissionTrackingSuccess(response.data));
  } catch (error: any) {
    yield put(getViewCommissionTrackingError(error));
  }
}

function* putSaveCommissionTrackingRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(saveViewCommissionTrackingStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(saveViewCommissionTrackingSuccess(response.data));
  } catch (error: any) {
    yield put(saveViewCommissionTrackingError(error));
  }
}

function* getViewLoanDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(getViewLoanDetailsStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(getViewLoanDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(getViewLoanDetailsError(error));
  }
}

function* putViewLoanDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(putViewLoanDetailsStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(putViewLoanDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(putViewLoanDetailsError(error));
  }
}
function* watchViewPolicyDetailsSagas() {
  yield takeLatest(GET_VIEW_POLICY_DETAILS, getViewPolicyDetailsRequest);
  yield takeLatest(PUT_VIEW_POLICY_DETAILS, putViewPolicyDetailsRequest);
  yield takeLatest(GET_VIEW_LOAN_DETAILS, getViewLoanDetailsRequest);
  yield takeLatest(PUT_VIEW_LOAN_DETAILS, putViewLoanDetailsRequest);
  yield takeLatest(GET_VIEW_COMMISSION_TRACKING, getViewCommissionTrackingRequest);
  yield takeLatest(SAVE_VIEW_COMMISSION_TRACKING, putSaveCommissionTrackingRequest);
}

export default function* viewPolicyDetailsSagas() {
  yield all([watchViewPolicyDetailsSagas()]);
}
