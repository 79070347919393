export function setLocalStore(key: string, value: any) {
  value = JSON.stringify(value);
  localStorage.setItem(key, value);
}

export function getLocalStore(key: string) {
  return JSON.parse(localStorage.getItem(key) || "{}");
}

export function removeLocalStore(key: string) {
  localStorage.removeItem(key);
}

export function clearLocalStore() {
  localStorage.clear();
}

export function setObjectInLocalStore(key: string, object: object) {
  if (object) {
    setLocalStore(key, object);
  }
}

export function getObjectFromLocalStore(key: string) {
  try {
    const objectString = getLocalStore(key);
    return objectString;
  } catch (e) {
    return {};
  }
}
