import { makeRequest } from "app/utils/makeRequest";
import {
  PROPOSED_DISABILITY_POLICY_LEDGER,
  POST_PROPOSED_DISABILITY_POLICY_LEDGER,
  PUT_PROPOSED_DISABILITY_POLICY_LEDGER,
  DELETE_PROPOSED_DISABILITY_POLICY_LEDGER,
  fetchProposedDisabilityPolicyLedgerStart,
  fetchProposedDisabilityPolicyLedgerSuccess,
  fetchProposedDisabilityPolicyLedgerFailure,
  postProposedDisabilityPolicyLedgerStart,
  postProposedDisabilityPolicyLedgerSuccess,
  postProposedDisabilityPolicyLedgerFailure,
  editProposedDisabilityPolicyLedgerStart,
  editProposedDisabilityPolicyLedgerSuccess,
  editProposedDisabilityPolicyLedgerFailure,
  deleteProposedDisabilityPolicyLedgerStart,
  deleteProposedDisabilityPolicyLedgerSuccess,
  deleteProposedDisabilityPolicyLedgerFailure,
} from "app/redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getProposedDisabilityPolicyLedgerData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams } = payload;
    yield put(fetchProposedDisabilityPolicyLedgerStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams));
    yield put(fetchProposedDisabilityPolicyLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(fetchProposedDisabilityPolicyLedgerFailure(error));
  }
}

function* postProposedDisabilityPolicyLedgerData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body, key, requestParams } = payload;
    yield put(postProposedDisabilityPolicyLedgerStart());
    const response = yield call(() => makeRequest(address).post(body, key, requestParams));
    yield put(postProposedDisabilityPolicyLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(postProposedDisabilityPolicyLedgerFailure(error));
  }
}

function* editProposedDisabilityPolicyLedgerData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body, key, requestParams } = payload;
    yield put(editProposedDisabilityPolicyLedgerStart());
    const response = yield call(() => makeRequest(address).put(key, body, requestParams));
    yield put(editProposedDisabilityPolicyLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(editProposedDisabilityPolicyLedgerFailure(error));
  }
}

function* deleteProposedDisabilityPolicyLedgerData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams } = payload;
    yield put(deleteProposedDisabilityPolicyLedgerStart());
    const response = yield call(() => makeRequest(address).delete(key, requestParams));
    yield put(deleteProposedDisabilityPolicyLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(deleteProposedDisabilityPolicyLedgerFailure(error));
  }
}

function* watchProposedDisabilityPolicyLedgerSaga() {
  yield takeLatest(PROPOSED_DISABILITY_POLICY_LEDGER, getProposedDisabilityPolicyLedgerData);
  yield takeLatest(POST_PROPOSED_DISABILITY_POLICY_LEDGER, postProposedDisabilityPolicyLedgerData);
  yield takeLatest(PUT_PROPOSED_DISABILITY_POLICY_LEDGER, editProposedDisabilityPolicyLedgerData);
  yield takeLatest(
    DELETE_PROPOSED_DISABILITY_POLICY_LEDGER,
    deleteProposedDisabilityPolicyLedgerData,
  );
}

export default function* proposedDisabilityLedgerSaga() {
  yield all([watchProposedDisabilityPolicyLedgerSaga()]);
}
