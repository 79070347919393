import {
  setInviteAgencyFailure,
  setInviteAgencyOngoing,
  setInviteAgencySuccess,
} from "app/redux/features/agency-invite";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* sendInvitesToAgency(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(setInviteAgencyOngoing());
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(setInviteAgencySuccess(response.data));
  } catch (e: any) {
    yield put(setInviteAgencyFailure(e));
  }
}

function* watchAgencyInvites() {
  yield takeLatest("TRIGGER_INVITE_AGENCY", sendInvitesToAgency);
}

export default function* inviteAgencySaga() {
  yield all([watchAgencyInvites()]);
}
