import { put, call, takeLatest } from "redux-saga/effects";

import { makeRequest } from "../../utils/makeRequest";
import {
  TRIGGER_REVOKE_CONTACT_TIMELINE_REQUEST,
  revokeProposalTimelineError,
  revokeProposalTimelineSuccess,
  revokeProposalTimelinesOngoing,
} from "app/redux/features/revokeContactTimeline";

function* revokeProposalTimelineSaga({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, isRequestParams } = payload;
    yield put(revokeProposalTimelinesOngoing());
    const response = yield call(() => makeRequest(address).get(key, isRequestParams));
    yield put(revokeProposalTimelineSuccess(response.data));
  } catch (error: any) {
    yield put(revokeProposalTimelineError(error));
  }
}

export default function* revokeContactTimelineSaga() {
  yield takeLatest(TRIGGER_REVOKE_CONTACT_TIMELINE_REQUEST, revokeProposalTimelineSaga);
}
