import { makeRequest } from "app/utils/makeRequest";
import {
  GET_SHAREHOLDER_LIST,
  fetchShareholderListStart,
  fetchShareholderListSuccess,
  fetchShareholderListFailure,
  GET_LIFE_INSURANCE_UPLOADED_LIST,
  fetchInsuranceListStart,
  fetchInsuranceListSuccess,
  fetchInsuranceListFailure,
  postforPendingIdStart,
  postforPendingIdSuccess,
  postforPendingIdFailure,
  postPendingDataFailure,
  postPendingDataStart,
  postPendingDataSuccess,
  postGenerateInvoiceFailure,
  postGenerateInvoiceSuccess,
  postGenerateInvoiceStart,
  ADD_NEW_SHAREHOLDER_PENDING,
  postNewShareholerStart,
  postNewShareholerSuccess,
  postNewShareholerFailure,
  GENERATE_INVOICE_LIFE_INSURANCE_PENDING_PROPOSALS,
  SAVE_LIFE_INSURANCE_PENDING_PROPOSALS,
  GET_LIFE_INSURANCE_PENDING_ID,
} from "app/redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getLifeInsuranceUnderwritingShareholdersListData({
  payload,
}: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams } = payload;
    yield put(fetchShareholderListStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams));
    yield put(fetchShareholderListSuccess(response.data));
  } catch (error: any) {
    yield put(fetchShareholderListFailure(error));
  }
}

function* getLifeInsuranceUnderwritingUploadedDocsListData({
  payload,
}: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(fetchInsuranceListStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(fetchInsuranceListSuccess(response.data));
  } catch (error: any) {
    yield put(fetchInsuranceListFailure(error));
  }
}

function* getLifeInsuranceUnderwritingPendingId({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body, key, requestParams, queryParams } = payload;
    yield put(postforPendingIdStart());
    const response = yield call(() =>
      makeRequest(address).post(body, key, requestParams, queryParams),
    );
    yield put(postforPendingIdSuccess(response.data));
  } catch (error: any) {
    yield put(postforPendingIdFailure(error));
  }
}

function* saveLifeInsuranceUnderwritingPendingData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body, key, requestParams } = payload;
    yield put(postPendingDataStart());
    const response = yield call(() => makeRequest(address).put(key, body, requestParams));
    yield put(postPendingDataSuccess(response.data));
  } catch (error: any) {
    yield put(postPendingDataFailure(error));
  }
}

function* generateEscrowInvoiceData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body, key, requestParams } = payload;
    yield put(postGenerateInvoiceStart());
    const response = yield call(() => makeRequest(address).put(key, body, requestParams));
    yield put(postGenerateInvoiceSuccess(response.data));
  } catch (error: any) {
    yield put(postGenerateInvoiceFailure(error));
  }
}

function* postNewShareholder({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body, key, requestParams, queryParams } = payload;
    yield put(postNewShareholerStart());
    const response = yield call(() =>
      makeRequest(address).post(body, key, requestParams, queryParams),
    );
    yield put(postNewShareholerSuccess(response.data));
  } catch (error: any) {
    yield put(postNewShareholerFailure(error));
  }
}

function* watchLifeInsuranceUnderwritingSaga() {
  yield takeLatest(GET_SHAREHOLDER_LIST, getLifeInsuranceUnderwritingShareholdersListData);
  yield takeLatest(
    GET_LIFE_INSURANCE_UPLOADED_LIST,
    getLifeInsuranceUnderwritingUploadedDocsListData,
  );
  yield takeLatest(GET_LIFE_INSURANCE_PENDING_ID, getLifeInsuranceUnderwritingPendingId);
  yield takeLatest(SAVE_LIFE_INSURANCE_PENDING_PROPOSALS, saveLifeInsuranceUnderwritingPendingData);
  yield takeLatest(GENERATE_INVOICE_LIFE_INSURANCE_PENDING_PROPOSALS, generateEscrowInvoiceData);
  yield takeLatest(ADD_NEW_SHAREHOLDER_PENDING, postNewShareholder);
}

export default function* lifeInsuranceUnderwritingSaga() {
  yield all([watchLifeInsuranceUnderwritingSaga()]);
}
