import {
  postCSVDataStart,
  postCSVDataSucess,
  postCSVDataError,
  POLICY_INPUTBASE_GET_CSVDATA,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* postPolicyInputBaseCSVData(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(postCSVDataStart());
    const response = yield call(() => makeRequest(path).post(requestBody));
    yield put(postCSVDataSucess(response.data));
  } catch (error: any) {
    yield put(postCSVDataError(error));
  }
}
function* watchPostPolicyInputBaseCSVData() {
  yield takeLatest(POLICY_INPUTBASE_GET_CSVDATA, postPolicyInputBaseCSVData);
}

export default function* watchPostPolicyInputBaseCSVDataSaga() {
  yield all([watchPostPolicyInputBaseCSVData()]);
}
