import {
  createBSPlanStart,
  createBSPlanSucess,
  createBSPlanError,
  //   resetCreateBSPlanState,
  CREATE_BS_PLAN,
  //   createBsPlanReducer,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* createBsPlanRequestRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(createBSPlanStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(createBSPlanSucess(response.data));
  } catch (error: any) {
    yield put(createBSPlanError(error));
  }
}

function* watchCreateBsPlansSaga() {
  yield takeLatest(CREATE_BS_PLAN, createBsPlanRequestRequest);
}

export default function* createBsPlanSaga() {
  yield all([watchCreateBsPlansSaga()]);
}
