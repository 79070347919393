import {
  proposalActionReviewStart,
  proposalActionReviewSuccess,
  proposalActionReviewError,
  PROPOSAL_ACTION_REVIEW,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* proposalActionReview(actions: FinancialInfoActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    const body = {};

    yield put(proposalActionReviewStart());
    const response = yield call(() => makeRequest(path).put(key, body, requestParams));
    yield put(proposalActionReviewSuccess(response.data));
  } catch (error: any) {
    yield put(proposalActionReviewError(error));
  }
}
export default function* proposalActionReviewSaga() {
  yield all([takeLatest(PROPOSAL_ACTION_REVIEW, proposalActionReview)]);
}
