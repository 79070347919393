import { setGetAllAgentsFailure, setGetAllAgentsOngoing, setGetAllAgentsSuccess } from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getAllAgents(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setGetAllAgentsOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setGetAllAgentsSuccess(response.data));
  } catch (e: any) {
    yield put(setGetAllAgentsFailure(e));
  }
}

function* watchGetAllAgents() {
  yield takeLatest("TRIGGER_GET_ALL_AGENTS", getAllAgents);
}

export default function* getAllAgentsSaga() {
  yield all([watchGetAllAgents()]);
}
