import {
  TRIGGER_PLANS_SUMMARY_REQUEST,
  TRIGGER_PLANS_SUMMARY_CARD_DATA,
  TRIGGER_PLANS_EFFECTIVE_DATE,
  TRIGGER_PLAN_DETAILS_EDIT,
  TRIGGER_POLICY_DETAILS_EDIT,
  fetchPlanSummaryDataStart,
  fetchPlanSummaryDataSuccess,
  fetchPlanSummaryDataFailure,
  fetchPlanSummaryCardDataStart,
  fetchPlanSummaryCardDataSuccess,
  fetchPlanSummaryCardDataFailure,
  updatePlanEffectiveDateStart,
  updatePlanEffectiveDateSuccess,
  updatePlanEffectiveDateFailure,
  postPlanInfoStart,
  postPlanInfoSuccess,
  postPlanInfoFailure,
  postPolicyInfoStart,
  postPolicyInfoSuccess,
  postPolicyInfoFailure,
  postAdditionBenefitsStart,
  postAdditionBenefitsSuccess,
  postAdditionBenefitsFailure,
  TRIGGER_REQUEST_ADDITION_BENEFITS,
  postRequestAdditionBenefitsStart,
  postRequestAdditionBenefitsSuccess,
  postRequestAdditionBenefitsFailure,
  TRIGGER_ADDITION_BENEFITS,
} from "@reducers";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";

interface ActionPayload {
  type: string;
  payload: {
    address: string;
    key: string;
    body?: any;
    requestParams?: boolean;
    queryParams?: boolean;
  };
}

function* getPlanSummary(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, requestParams, queryParams },
    } = actions;
    yield put(fetchPlanSummaryDataStart());
    const response = yield call(() => makeRequest(path).get(type, requestParams, queryParams));
    yield put(fetchPlanSummaryDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPlanSummaryDataFailure(error));
  }
}
function* getPlanSummaryCardInfo(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, requestParams, queryParams },
    } = actions;
    yield put(fetchPlanSummaryCardDataStart());
    const response = yield call(() => makeRequest(path).get(type, requestParams, queryParams));
    yield put(fetchPlanSummaryCardDataSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPlanSummaryCardDataFailure(error));
  }
}

function* postPlanUpdatedInfo(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, body, requestParams },
    } = actions;
    yield put(postPlanInfoStart());
    const response = yield call(() => makeRequest(path).put(type, body, requestParams));
    yield put(postPlanInfoSuccess(response.data));
  } catch (error: any) {
    yield put(postPlanInfoFailure(error));
  }
}

function* postPolicyUpdatedInfo(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, body, requestParams },
    } = actions;
    yield put(postPolicyInfoStart());
    const response = yield call(() => makeRequest(path).put(type, body, requestParams));
    yield put(postPolicyInfoSuccess(response.data));
  } catch (error: any) {
    yield put(postPolicyInfoFailure(error));
  }
}

function* updatePlanEffectiveDateRequest(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, body, requestParams },
    } = actions;
    yield put(updatePlanEffectiveDateStart());
    const response = yield call(() => makeRequest(path).put(type, body, requestParams));
    yield put(updatePlanEffectiveDateSuccess(response.data));
  } catch (error: any) {
    yield put(updatePlanEffectiveDateFailure(error));
  }
}

function* postAdditionalBenefitsRequest(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, body, requestParams },
    } = actions;
    yield put(postAdditionBenefitsStart());
    const response = yield call(() => makeRequest(path).put(type, body, requestParams));
    yield put(postAdditionBenefitsSuccess(response.data));
  } catch (error: any) {
    yield put(postAdditionBenefitsFailure(error));
  }
}

function* postRequestAdditionalBenefitsRequest(actions: ActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: type, body, requestParams },
    } = actions;
    yield put(postRequestAdditionBenefitsStart());
    const response = yield call(() => makeRequest(path).post(body, type, requestParams));
    yield put(postRequestAdditionBenefitsSuccess(response.data));
  } catch (error: any) {
    yield put(postRequestAdditionBenefitsFailure(error));
  }
}

function* watchFetchAllPlanSummary() {
  yield takeLatest(TRIGGER_PLANS_SUMMARY_REQUEST, getPlanSummary);
  yield takeLatest(TRIGGER_PLANS_SUMMARY_CARD_DATA, getPlanSummaryCardInfo);
  yield takeLatest(TRIGGER_PLAN_DETAILS_EDIT, postPlanUpdatedInfo);
  yield takeLatest(TRIGGER_POLICY_DETAILS_EDIT, postPolicyUpdatedInfo);
  yield takeLatest(TRIGGER_PLANS_EFFECTIVE_DATE, updatePlanEffectiveDateRequest);
  yield takeLatest(TRIGGER_ADDITION_BENEFITS, postAdditionalBenefitsRequest);
  yield takeLatest(TRIGGER_REQUEST_ADDITION_BENEFITS, postRequestAdditionalBenefitsRequest);
}

export default function* watchAllPlanSummarySaga() {
  yield all([watchFetchAllPlanSummary()]);
}
