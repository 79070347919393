import {
  getTriggerEventsStart,
  getTriggerEventsSuccess,
  getTriggerEventsError,
  // resetgetTriggerEventsState,
  putTriggerEventsStart,
  putTriggerEventsSuccess,
  putTriggerEventsError,
  //   resetputTriggerEventsState,
  postTriggerEventsStart,
  postTriggerEventsSuccess,
  postTriggerEventsError,
  //   resetPostTriggerEventsState,
  getShareHoldersAndTriggerEventsListStart,
  getShareHoldersAndTriggerEventsListSuccess,
  getShareHoldersAndTriggerEventsListError,
  //   resetGetShareHoldersAndTriggerEventsListState,
  GET_TRIGGER_EVENTS,
  PUT_TRIGGER_EVENTS,
  POST_TRIGGER_EVENTS,
  GET_SHAREHOLDERS_TRIGGER_EVENTS_LIST,
  //   triggerEventsReducer,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* valuationContributionReviewRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(getTriggerEventsStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(getTriggerEventsSuccess(response.data));
  } catch (error: any) {
    yield put(getTriggerEventsError(error));
  }
}

function* getShareHolderEventsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(getShareHoldersAndTriggerEventsListStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(getShareHoldersAndTriggerEventsListSuccess(response.data));
  } catch (error: any) {
    yield put(getShareHoldersAndTriggerEventsListError(error));
  }
}

function* postTriggerEvent({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(postTriggerEventsStart());
    const response = yield call(() =>
      makeRequest(address).post(body, key, requestParams, queryParams),
    );
    yield put(postTriggerEventsSuccess(response.data));
  } catch (error: any) {
    yield put(postTriggerEventsError(error));
  }
}
function* putViewPlanDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(putTriggerEventsStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(putTriggerEventsSuccess(response.data));
  } catch (error: any) {
    yield put(putTriggerEventsError(error));
  }
}

function* watchTriggerEventsSaga() {
  yield takeLatest(GET_TRIGGER_EVENTS, valuationContributionReviewRequest);
  yield takeLatest(PUT_TRIGGER_EVENTS, putViewPlanDetailsRequest);
  yield takeLatest(POST_TRIGGER_EVENTS, postTriggerEvent);
  yield takeLatest(GET_SHAREHOLDERS_TRIGGER_EVENTS_LIST, getShareHolderEventsRequest);
}

export default function* triggerEventsSaga() {
  yield all([watchTriggerEventsSaga()]);
}
