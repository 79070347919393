import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_POLICY_SUMMARY = "GET_POLICY_SUMMARY";
export const GET_POLICY_GRAPH = "GET_POLICY_GRAPH";
export const GET_BS_POLICY_SHAREHOLDERS_LIST = "GET_POLICY_SHAREHOLDERS_LIST";
export const GET_BS_POLICY_SUMMARY = "GET_BS_POLICY_SUMMARY";

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface pendingPolicySummaryResponseType {
  fetchPolicySummary?: InitialStateInterface;
  fetchPolicyGraph?: InitialStateInterface;
  fetchBSPolicyShareholdersList: InitialStateInterface;
  fetchBSPolicySummary: InitialStateInterface;
}

const initialState: pendingPolicySummaryResponseType = {
  fetchPolicySummary: { data: {}, error: {}, loading: false },
  fetchPolicyGraph: { data: {}, error: {}, loading: false },
  fetchBSPolicyShareholdersList: { data: {}, error: {}, loading: false },
  fetchBSPolicySummary: { data: {}, error: {}, loading: false },
};

export const policySummarySlice = createSlice({
  name: "policySummary",
  initialState,
  reducers: {
    fetchPolicySummaryStart: (state) => {
      state.fetchPolicySummary = { data: {}, error: {}, loading: true };
    },
    fetchPolicySummarySuccess: (state, action: PayloadAction<any>) => {
      state.fetchPolicySummary = { data: action.payload.data, error: {}, loading: false };
    },
    fetchPolicySummaryError: (state, action: PayloadAction<any>) => {
      state.fetchPolicySummary = { data: {}, error: action.payload, loading: false };
    },
    fetchPolicyGraphStart: (state) => {
      state.fetchPolicyGraph = { data: {}, error: {}, loading: true };
    },
    fetchPolicyGraphSuccess: (state, action: PayloadAction<any>) => {
      state.fetchPolicyGraph = { data: action.payload.data, error: {}, loading: false };
    },
    fetchPolicyGraphError: (state, action: PayloadAction<any>) => {
      state.fetchPolicyGraph = { data: {}, error: action.payload, loading: false };
    },
    fetchBSPolicyShareholdersListStart: (state) => {
      state.fetchBSPolicyShareholdersList = { data: {}, error: {}, loading: true };
    },
    fetchBSPolicyShareholdersListSuccess: (state, action: PayloadAction<any>) => {
      state.fetchBSPolicyShareholdersList = {
        data: action.payload.data,
        error: {},
        loading: false,
      };
    },
    fetchBSPolicyShareholdersListError: (state, action: PayloadAction<any>) => {
      state.fetchBSPolicyShareholdersList = { data: {}, error: action.payload, loading: false };
    },
    resetFetchBSPolicyShareholdersList: (state) => {
      state.fetchBSPolicyShareholdersList = { data: {}, error: {}, loading: false };
    },
    fetchBSPolicySummaryStart: (state) => {
      state.fetchBSPolicySummary = { data: {}, error: {}, loading: true };
    },
    fetchBSPolicySummarySuccess: (state, action: PayloadAction<any>) => {
      state.fetchBSPolicySummary = { data: action.payload.data, error: {}, loading: false };
    },
    fetchBSPolicySummaryError: (state, action: PayloadAction<any>) => {
      state.fetchBSPolicySummary = { data: {}, error: action.payload, loading: false };
    },
    resetFetchBSPolicySummary: (state) => {
      state.fetchBSPolicySummary = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchPolicySummaryStart,
  fetchPolicySummarySuccess,
  fetchPolicySummaryError,
  fetchPolicyGraphStart,
  fetchPolicyGraphSuccess,
  fetchPolicyGraphError,
  fetchBSPolicyShareholdersListStart,
  fetchBSPolicyShareholdersListSuccess,
  fetchBSPolicyShareholdersListError,
  resetFetchBSPolicyShareholdersList,
  fetchBSPolicySummaryStart,
  fetchBSPolicySummarySuccess,
  fetchBSPolicySummaryError,
  resetFetchBSPolicySummary,
} = policySummarySlice.actions;
export const getPolicySummaryInfo = (state: RootState) => state.getPolicySummary;

export default policySummarySlice.reducer;
