import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const SAVE_VIEW_COMMISSION_TRACKING = "SAVE_VIEW_COMMISSION_TRACKING";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const saveViewCommissionTrackingSlice = createSlice({
  name: "saveViewCommissionTracking",
  initialState,
  reducers: {
    saveViewCommissionTrackingStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    saveViewCommissionTrackingSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    saveViewCommissionTrackingError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetSaveViewCommissionTrackingState: () => initialState,
  },
});

export const {
  saveViewCommissionTrackingStart,
  saveViewCommissionTrackingSuccess,
  saveViewCommissionTrackingError,
  resetSaveViewCommissionTrackingState,
} = saveViewCommissionTrackingSlice.actions;

export const saveViewCommissionTracking = (state: RootState) => state.saveViewCommissionTracking;
export default saveViewCommissionTrackingSlice.reducer;
