import {
  setGetAllAgenciesFailure,
  setGetAllAgenciesOngoing,
  setGetAllAgenciesSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getAllAgencies(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setGetAllAgenciesOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setGetAllAgenciesSuccess(response.data));
  } catch (e: any) {
    yield put(setGetAllAgenciesFailure(e.message));
  }
}

function* watchGetAllAgencies() {
  yield takeLatest("TRIGGER_GET_ALL_AGENCIES", getAllAgencies);
}

export default function* getAllAgenciesSaga() {
  yield all([watchGetAllAgencies()]);
}
