export function setSessionStore(key: string, value: any) {
  value = JSON.stringify(value);
  sessionStorage.setItem(key, value);
}

export function getSessionStore(key: string) {
  return JSON.parse(sessionStorage.getItem(key) || "{}");
}

export function removeSessionStore(key: string) {
  sessionStorage.removeItem(key);
}

export function clearSessionStore() {
  sessionStorage.clear();
}

export function setObjectInSessionStore(key: string, object: object) {
  setSessionStore(key, object);
}

export function getObjectFromSessionStore(key: string) {
  try {
    const objectString = getSessionStore(key);
    return objectString;
  } catch (e) {
    return {};
  }
}
