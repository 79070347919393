import {
  planPast30DaysStart,
  planPast30DaysSuccess,
  planPast30DaysError,
  planPastDuesStart,
  planPastDuesSuccess,
  planPastDuesError,
  planNext30DaysStart,
  planNext30DaysSuccess,
  planNext30DaysError,
  planNext45DaysStart,
  planNext45DaysSuccess,
  planNext45DaysError,
  PAST_DUES_30_DAYS,
  PAST_DUES,
  NEXT_45_DAYS,
  NEXT_30_DAYS,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* planPast30DaysRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(planPast30DaysStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(planPast30DaysSuccess(response.data));
  } catch (error: any) {
    yield put(planPast30DaysError(error));
  }
}

function* planPastDuesRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(planPastDuesStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(planPastDuesSuccess(response.data));
  } catch (error: any) {
    yield put(planPastDuesError(error));
  }
}
function* planNext30DaysRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(planNext30DaysStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(planNext30DaysSuccess(response.data));
  } catch (error: any) {
    yield put(planNext30DaysError(error));
  }
}
function* planNext45DaysRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(planNext45DaysStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(planNext45DaysSuccess(response.data));
  } catch (error: any) {
    yield put(planNext45DaysError(error));
  }
}

function* watchUpcomingPlansSagas() {
  yield takeLatest(PAST_DUES_30_DAYS, planPast30DaysRequest);
  yield takeLatest(PAST_DUES, planPastDuesRequest);
  yield takeLatest(NEXT_30_DAYS, planNext30DaysRequest);
  yield takeLatest(NEXT_45_DAYS, planNext45DaysRequest);
}

export default function* upcomingPlansSaga() {
  yield all([watchUpcomingPlansSagas()]);
}
