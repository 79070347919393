import { setInviteFailure, setInviteOngoing, setInviteSuccess } from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* sendInviteEmail(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key, requestParams, queryParams },
    } = actions;
    yield put(setInviteOngoing());
    const response = yield call(() =>
      makeRequest(path).post(requestBody, key, requestParams, queryParams),
    );
    if (response?.data?.status === "success") {
      yield put(setInviteSuccess(response.data));
    } else if (response?.data?.status === "error" || response?.data?.status === "warn") {
      yield put(setInviteFailure(response.data));
    }
  } catch (error: any) {
    yield put(setInviteFailure(error));
  }
}

function* watchInviteEmail() {
  yield takeLatest("TRIGGER_INVITE_EMAIL", sendInviteEmail);
}

export default function* inviteEmailSaga() {
  yield all([watchInviteEmail()]);
}
