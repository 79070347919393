import {
  GET_TIMELINE,
  GET_PROPOSAL_PDF,
  POST_REQUESTED_CHANGES,
  GET_REQUESTED_CHANGES,
  pdtFetchStart,
  fetchRequestedChangesSuccess,
  fetchRequestedChangesError,
  submitRequestedChangesSuccess,
  submitRequestedChangesError,
  fetchPDFSuccess,
  fetchPDFFailure,
  fetchTimelineSuccess,
  fetchTimelineError,
  GET_PROPOSAL_HELLO_SIGN_PDF,
  helloSignPDFFetchStart,
  helloSignPDFFetchSuccess,
  helloSignPDFFetchFailure,
  CHECK_PROPOSAL_HELLO_SIGN_PDF_STATUS,
  helloSignPDFStatusFetchStart,
  helloSignPDFStatusFetchSuccess,
  helloSignPDFStatusFetchFailure,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
import { payload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* proposalReviewFetchPDFRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(pdtFetchStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(fetchPDFSuccess(response.data));
  } catch (error: any) {
    yield put(fetchPDFFailure(error));
  }
}

function* proposalReviewFetchTimelineRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(fetchTimelineSuccess(response.data));
  } catch (error: any) {
    yield put(fetchTimelineError(error));
  }
}

function* proposalReviewRequestedChangesRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody, key, requestParams));
    yield put(submitRequestedChangesSuccess(response.data));
  } catch (error: any) {
    yield put(submitRequestedChangesError(error));
  }
}

function* proposalReviewFetchChangesRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(fetchRequestedChangesSuccess(response.data));
  } catch (error: any) {
    yield put(fetchRequestedChangesError(error));
  }
}

function* proposalReviewFetchHelloSignPDFRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(helloSignPDFFetchStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(helloSignPDFFetchSuccess(response.data));
  } catch (error: any) {
    yield put(helloSignPDFFetchFailure(error));
  }
}

function* proposalReviewFetchHelloSignPDFStatus(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams },
    } = actions;
    yield put(helloSignPDFStatusFetchStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams));
    yield put(helloSignPDFStatusFetchSuccess(response.data));
  } catch (error: any) {
    yield put(helloSignPDFStatusFetchFailure(error));
  }
}

function* watchReviewSagas() {
  yield takeLatest(GET_TIMELINE, proposalReviewFetchTimelineRequest);
  yield takeLatest(GET_PROPOSAL_PDF, proposalReviewFetchPDFRequest);
  yield takeLatest(GET_PROPOSAL_HELLO_SIGN_PDF, proposalReviewFetchHelloSignPDFRequest);
  yield takeLatest(CHECK_PROPOSAL_HELLO_SIGN_PDF_STATUS, proposalReviewFetchHelloSignPDFStatus);
  yield takeLatest(POST_REQUESTED_CHANGES, proposalReviewRequestedChangesRequest);
  yield takeLatest(GET_REQUESTED_CHANGES, proposalReviewFetchChangesRequest);
}

export default function* proposalReviewSaga() {
  yield all([watchReviewSagas()]);
}
