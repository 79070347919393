import { payload } from "app/utils/types";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchContactListLoading,
  fetchContactListSuccess,
  fetchContactListError,
  GET_CONTACT_LIST,
} from "app/redux/features/contacts";

function* getClients(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchContactListLoading());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchContactListSuccess(response));
  } catch (error: any) {
    yield put(fetchContactListError(error));
  }
}

function* watchPendingSagas() {
  yield takeLatest(GET_CONTACT_LIST, getClients);
}

export default function* contactsSaga() {
  yield all([watchPendingSagas()]);
}
