import {
  fetchEmailStart,
  fetchEmailSuccess,
  fetchEmailFailure,
  postProfileStart,
  postProfileSuccess,
  postProfileFailure,
  postOTPStart,
  postOTPSuccess,
  postOTPFailure,
  GET_EMAIL_PROFILE,
  POST_EMAIL_PROFILE,
  POST_PROFILE_OTP,
} from "@reducers";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_FINANCIAL_INFO,
  financialApiPending,
  financialInfoError,
  setContactFinancialInfo,
} from "app/redux/features/contactFinancialInfo";
import {
  CREATE_HEALTH_INFO,
  UPDATE_HEALTH_INFO,
  healthApiPending,
  healthInfoError,
  setContactHealthInfo,
} from "app/redux/features/contactHealthInfo";
import {
  GET_PROFILE,
  PATCH_PROFILE,
  fetchProfileFailure,
  fetchProfileStart,
  fetchProfileSuccess,
} from "app/redux/features/profile";

function* getProfileEmail(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, id, requestParam, queryParam },
    } = actions;

    yield put(fetchEmailStart());
    const response = yield call(() => makeRequest(path).get(id, requestParam, queryParam));
    if (response.statusCode >= 400) yield put(fetchEmailFailure(response));
    else {
      yield put(fetchEmailSuccess(response.data));
    }
  } catch (error: any) {
    yield put(fetchEmailFailure(error));
  }
}

function* watchProfileEmail() {
  yield takeLatest(GET_EMAIL_PROFILE, getProfileEmail);
}

function* getProfile(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParam, queryParam },
    } = actions;

    yield put(fetchProfileStart());
    const response = yield call(() => makeRequest(path).get(key, requestParam, queryParam));
    if (response.statusCode >= 400) yield put(fetchProfileFailure(response));
    else {
      yield put(fetchProfileSuccess(response.data));
    }
  } catch (error: any) {
    yield put(fetchProfileFailure(error));
  }
}

function* watchProfile() {
  yield takeLatest(GET_PROFILE, getProfile);
}

function* patchProfile(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParam, queryParam, body },
    } = actions;

    yield put(postProfileStart());
    const response = yield call(() => makeRequest(path).patch(body, key, requestParam, queryParam));
    if (response.statusCode >= 400) yield put(postProfileFailure(response));
    else {
      yield put(postProfileSuccess(response.data));
    }
  } catch (error: any) {
    yield put(postProfileFailure(error));
  }
}

function* watchPatchProfile() {
  yield takeLatest(PATCH_PROFILE, patchProfile);
}

function* postProfileEmail(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, id, requestParam, queryParam },
    } = actions;
    yield put(postProfileStart());
    const response = yield call(() =>
      makeRequest(path).post(requestBody, id, requestParam, queryParam),
    );
    yield put(postProfileSuccess(response.data));
  } catch (error: any) {
    yield put(postProfileFailure(error));
  }
}
function* watchPostProfile() {
  yield takeLatest(POST_EMAIL_PROFILE, postProfileEmail);
}
function* postProfileOTP(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key, requestParam },
    } = actions;

    yield put(postOTPStart());
    const response = yield call(() => makeRequest(path).post(requestBody, key, requestParam));
    yield put(postOTPSuccess({ verifedTrue: !!requestBody?.verifyToken, ...response.data }));
  } catch (error: any) {
    yield put(postOTPFailure(error));
  }
}
function* watchPostProfileOTP() {
  yield takeLatest(POST_PROFILE_OTP, postProfileOTP);
}

function* createFinancialInfo(data: any): Generator<any, void, any> {
  try {
    yield put(financialApiPending());
    const response = yield call(() => makeRequest("contactFinancialInfo").post(data.payload));
    yield put(setContactFinancialInfo(response.data));
  } catch (error: any) {
    yield put(financialInfoError(error));
  }
}

function* createHealthInfo(data: any): Generator<any, void, any> {
  try {
    yield put(healthApiPending());
    const response = yield call(() => makeRequest("contactHealthInfo").post(data.payload));
    yield put(setContactHealthInfo(response.data));
  } catch (error: any) {
    yield put(healthInfoError(error));
  }
}

function* updateHealthInfo(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body: requestBody, requestParams, queryParams },
    } = actions;

    yield put(healthApiPending());
    const response = yield call(() =>
      makeRequest(path).put(key, requestBody, requestParams, queryParams),
    );
    yield put(setContactHealthInfo(response.data));
  } catch (error: any) {
    yield put(healthInfoError(error));
  }
}

export default function* watchProfileEmailSaga() {
  yield all([
    watchProfile(),
    watchPatchProfile(),
    watchProfileEmail(),
    watchPostProfile(),
    watchPostProfileOTP(),
    takeLatest(CREATE_FINANCIAL_INFO, createFinancialInfo),
    takeLatest(CREATE_HEALTH_INFO, createHealthInfo),
    takeLatest(UPDATE_HEALTH_INFO, updateHealthInfo),
  ]);
}
