import { makeRequest } from "app/utils/makeRequest";
import {
  PROPOSED_LIFE_POLICY_LEDGER,
  POST_PROPOSED_LIFE_POLICY_LEDGER,
  PUT_PROPOSED_LIFE_POLICY_LEDGER,
  DELETE_PROPOSED_LIFE_POLICY_LEDGER,
  fetchProposedPolicyLedgerSuccess,
  fetchProposedPolicyLedgerStart,
  fetchProposedPolicyLedgerFailure,
  postProposedPolicyLedgerStart,
  postProposedPolicyLedgerSuccess,
  postProposedPolicyLedgerFailure,
  editProposedPolicyLedgerStart,
  editProposedPolicyLedgerSuccess,
  editProposedPolicyLedgerFailure,
  deleteProposedPolicyLedgerStart,
  deleteProposedPolicyLedgerSuccess,
  deleteProposedPolicyLedgerFailure,
} from "app/redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getProposedLifePolicyLedgerData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams } = payload;
    yield put(fetchProposedPolicyLedgerStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams));
    yield put(fetchProposedPolicyLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(fetchProposedPolicyLedgerFailure(error));
  }
}

function* postProposedLifePolicyLedgerData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body, key, requestParams } = payload;
    yield put(postProposedPolicyLedgerStart());
    const response = yield call(() => makeRequest(address).post(body, key, requestParams));
    yield put(postProposedPolicyLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(postProposedPolicyLedgerFailure(error));
  }
}

function* editProposedLifePolicyLedgerData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, body, key, requestParams } = payload;
    yield put(editProposedPolicyLedgerStart());
    const response = yield call(() => makeRequest(address).put(key, body, requestParams));
    yield put(editProposedPolicyLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(editProposedPolicyLedgerFailure(error));
  }
}

function* deleteProposedLifePolicyLedgerData({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams } = payload;
    yield put(deleteProposedPolicyLedgerStart());
    const response = yield call(() => makeRequest(address).delete(key, requestParams));
    yield put(deleteProposedPolicyLedgerSuccess(response.data));
  } catch (error: any) {
    yield put(deleteProposedPolicyLedgerFailure(error));
  }
}

function* watchProposedLifePolicyLedgerSaga() {
  yield takeLatest(PROPOSED_LIFE_POLICY_LEDGER, getProposedLifePolicyLedgerData);
  yield takeLatest(POST_PROPOSED_LIFE_POLICY_LEDGER, postProposedLifePolicyLedgerData);
  yield takeLatest(PUT_PROPOSED_LIFE_POLICY_LEDGER, editProposedLifePolicyLedgerData);
  yield takeLatest(DELETE_PROPOSED_LIFE_POLICY_LEDGER, deleteProposedLifePolicyLedgerData);
}

export default function* proposedLifeLedgerSaga() {
  yield all([watchProposedLifePolicyLedgerSaga()]);
}
