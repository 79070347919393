import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";

interface initialStateInterface {
  loading?: boolean;
  error?: any;
  data?: any;
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const uploadDocumentSlice = createSlice({
  name: "uploadDocument",
  initialState,
  reducers: {
    uploadDocumentStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    uploadDocumentSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    uploadDocumentError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetuploadDocumentState: () => initialState,
  },
});

export const {
  uploadDocumentStart,
  uploadDocumentSuccess,
  uploadDocumentError,
  resetuploadDocumentState,
} = uploadDocumentSlice.actions;

export const uploadDocument = (state: RootState) => state.uploadDocument;

export default uploadDocumentSlice.reducer;
