import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_REQ_SIGN = "GET_REQ_SIGN";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const reqSignSlice = createSlice({
  name: "reqSign",
  initialState,
  reducers: {
    reqSignStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    reqSignSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    reqSignError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetReqSignState: () => initialState,
  },
});

export const { reqSignStart, reqSignSuccess, reqSignError, resetReqSignState } =
  reqSignSlice.actions;

export const reqSign = (state: RootState) => state.reqSign;
export default reqSignSlice.reducer;
