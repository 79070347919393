import {
  proposalsUploadSignedReportStart,
  proposalsUploadSignedReportSuccess,
  proposalsUploadSignedReportError,
  PROPOSALS_UPLOAD_SIGNED_REPORT,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* postProposalsUploadSignedReport(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody },
    } = actions;
    yield put(proposalsUploadSignedReportStart());
    const response = yield call(() =>
      makeRequest(path, { "Content-Type": "multipart/form-data" }).post(requestBody),
    );
    yield put(proposalsUploadSignedReportSuccess(response.data));
  } catch (error: any) {
    yield put(proposalsUploadSignedReportError(error));
  }
}
function* watchPostProposalsUploadSignedReport() {
  yield takeLatest(PROPOSALS_UPLOAD_SIGNED_REPORT, postProposalsUploadSignedReport);
}

export default function* watchPostProposalsUploadSignedReportSaga() {
  yield all([watchPostProposalsUploadSignedReport()]);
}
