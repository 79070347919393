import {
  proposalsApprovedPendingStart,
  proposalsApprovedPendingSuccess,
  proposalsApprovedPendingError,
  PROPOSALS_APPROVED_PENDING,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* proposalsApprovedPendingRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { path, key, isRequestParams, isQueryParams } = payload;
    yield put(proposalsApprovedPendingStart());
    const response = yield call(() => makeRequest(path).get(key, isRequestParams, isQueryParams));
    yield put(proposalsApprovedPendingSuccess(response.data));
  } catch (error: any) {
    yield put(proposalsApprovedPendingError(error));
  }
}
export default function* proposalsApprovedPendingSaga() {
  yield all([takeLatest(PROPOSALS_APPROVED_PENDING, proposalsApprovedPendingRequest)]);
}
