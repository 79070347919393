import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_INSURANCE_COMPANIES = "GET_INSURANCE_COMPANIES";

const initialState = {
  loading: false,
  data: [],
  error: {},
};

export const insuranceCompaniesSlice = createSlice({
  name: "insuranceCompanies",
  initialState,
  reducers: {
    getInsuranceCompaniesStart: (state) => {
      state.data = [];
      state.error = {};
      state.loading = true;
    },
    getInsuranceCompaniesSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    getInsuranceCompaniesError: (state, action: PayloadAction<any>) => {
      state.data = [];
      state.error = action.payload;
      state.loading = false;
    },
    resetgetInsuranceCompaniesState: () => initialState,
  },
});

export const {
  getInsuranceCompaniesStart,
  getInsuranceCompaniesSuccess,
  getInsuranceCompaniesError,
  resetgetInsuranceCompaniesState,
} = insuranceCompaniesSlice.actions;

export const getInsuranceCompaniesData = (state: RootState) => state.insuranceCompanies;

export default insuranceCompaniesSlice.reducer;
