import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_GET_ADMIN_AGENTS = "TRIGGER_GET_ADMIN_AGENTS";

interface AdminAgentsInterface {
  data: any;
  loading: boolean;
  error: Record<string, string | number | object> | null;
}

const initialState: AdminAgentsInterface = {
  data: [],
  loading: false,
  error: {},
};

export const getAdminAgentsSlice = createSlice({
  name: "getAdminAgents",
  initialState,
  reducers: {
    fetchAdminAgentsStart: (state) => {
      state.loading = true;
      state.error = {};
    },
    fetchAdminAgentsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchAdminAgentsFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchAdminAgentsStart, fetchAdminAgentsSuccess, fetchAdminAgentsFailure } =
  getAdminAgentsSlice.actions;

export const getAdminAgentsSliceList = (state: RootState) => state.adminAgentsSliceList;

export default getAdminAgentsSlice.reducer;
