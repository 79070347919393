import {
  getInsuranceCompaniesStart,
  getInsuranceCompaniesSuccess,
  getInsuranceCompaniesError,
  GET_INSURANCE_COMPANIES,
} from "@reducers";
import { makeRequest } from "app/utils/makeRequest";
import { call, put, takeLatest } from "redux-saga/effects";

function* getInsuranceCompanies(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;

    yield put(getInsuranceCompaniesStart());
    const response = yield call(() => makeRequest(path).get());
    if (response.statusCode >= 400) yield put(getInsuranceCompaniesError(response));
    else {
      yield put(getInsuranceCompaniesSuccess(response.data));
    }
  } catch (error: any) {
    yield put(getInsuranceCompaniesError(error));
  }
}

export default function* watchInsuranceCompanies() {
  yield takeLatest(GET_INSURANCE_COMPANIES, getInsuranceCompanies);
}
