import { ProgressSpinner } from "primereact/progressspinner";

const ScreenLoader = () => (
  <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-slate-300 z-[100]">
    <ProgressSpinner
      className="w-20 h-20"
      strokeWidth="6"
      fill="var(--surface-ground)"
      animationDuration=".5s"
    />
  </div>
);

export default ScreenLoader;
