import { SET_LOAN_AFR, setLoanAFRError, setLoanAFRStart, setLoanAFRSuccess } from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* putSetLoanAFRRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(setLoanAFRStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(setLoanAFRSuccess(response.data));
  } catch (error: any) {
    yield put(setLoanAFRError(error));
  }
}

function* watchSetAFRLoanSaga() {
  yield takeLatest(SET_LOAN_AFR, putSetLoanAFRRequest);
}

export default function* setAFRLoanSaga() {
  yield all([watchSetAFRLoanSaga()]);
}
