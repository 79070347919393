import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
// import { GET_SHAREHOLDER_LIST } from '../lifeInsuranceUnderwriting/index';

export const GET_TRIGGER_EVENTS = "GET_TRIGGER_EVENTS";
export const PUT_TRIGGER_EVENTS = "PUT_TRIGGER_EVENTS";
export const POST_TRIGGER_EVENTS = "POST_TRIGGER_EVENTS";
export const GET_SHAREHOLDERS_TRIGGER_EVENTS_LIST = "GET_SHAREHOLDERS_TRIGGER_EVENTS_LIST";

interface BASESTATEINTERFACE {
  loading: boolean;
  data: any;
  error: any;
}
interface INITIALSTATEINTERFACE {
  getTriggerEvents: BASESTATEINTERFACE;
  putTriggerEvents: BASESTATEINTERFACE;
  postTriggerEvents: BASESTATEINTERFACE;
  getShareHoldersAndTriggerEventsList: BASESTATEINTERFACE;
}

const initialState = {
  getTriggerEvents: { loading: false, data: {} as any, error: {} as any },
  putTriggerEvents: { loading: false, data: {} as any, error: {} as any },
  postTriggerEvents: { loading: false, data: {} as any, error: {} as any },
  getShareHoldersAndTriggerEventsList: { loading: false, data: {} as any, error: {} as any },
};

export const triggerEventsSlice = createSlice({
  name: "PlanReviewDetails",
  initialState,
  reducers: {
    getTriggerEventsStart: (state: INITIALSTATEINTERFACE) => {
      state.getTriggerEvents.data = {};
      state.getTriggerEvents.error = {};
      state.getTriggerEvents.loading = true;
    },
    getTriggerEventsSuccess: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.getTriggerEvents.data = action.payload;
      state.getTriggerEvents.error = {};
      state.getTriggerEvents.loading = false;
    },
    getTriggerEventsError: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.getTriggerEvents.data = {};
      state.getTriggerEvents.error = action.payload;
      state.getTriggerEvents.loading = false;
    },
    resetgetTriggerEventsState: (state: INITIALSTATEINTERFACE) => ({
      ...state,
      getPlanReviewDetails: initialState.getTriggerEvents,
    }),

    putTriggerEventsStart: (state: INITIALSTATEINTERFACE) => {
      state.putTriggerEvents.data = {};
      state.putTriggerEvents.error = {};
      state.putTriggerEvents.loading = true;
    },
    putTriggerEventsSuccess: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.putTriggerEvents.data = action.payload;
      state.putTriggerEvents.error = {};
      state.putTriggerEvents.loading = false;
    },
    putTriggerEventsError: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.putTriggerEvents.data = {};
      state.putTriggerEvents.error = action.payload;
      state.putTriggerEvents.loading = false;
    },
    resetputTriggerEventsState: (state: INITIALSTATEINTERFACE) => ({
      ...state,
      putTriggerEvents: initialState.putTriggerEvents,
    }),
    postTriggerEventsStart: (state: INITIALSTATEINTERFACE) => {
      state.postTriggerEvents.data = {};
      state.postTriggerEvents.error = {};
      state.postTriggerEvents.loading = true;
    },
    postTriggerEventsSuccess: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.postTriggerEvents.data = action.payload;
      state.postTriggerEvents.error = {};
      state.postTriggerEvents.loading = false;
    },
    postTriggerEventsError: (state: INITIALSTATEINTERFACE, action: PayloadAction<any>) => {
      state.postTriggerEvents.data = {};
      state.postTriggerEvents.error = action.payload;
      state.postTriggerEvents.loading = false;
    },
    resetPostTriggerEventsState: (state: INITIALSTATEINTERFACE) => ({
      ...state,
      postTriggerEvents: initialState.postTriggerEvents,
    }),
    getShareHoldersAndTriggerEventsListStart: (state: INITIALSTATEINTERFACE) => {
      state.getShareHoldersAndTriggerEventsList.data = {};
      state.getShareHoldersAndTriggerEventsList.error = {};
      state.getShareHoldersAndTriggerEventsList.loading = true;
    },
    getShareHoldersAndTriggerEventsListSuccess: (
      state: INITIALSTATEINTERFACE,
      action: PayloadAction<any>,
    ) => {
      state.getShareHoldersAndTriggerEventsList.data = action.payload;
      state.getShareHoldersAndTriggerEventsList.error = {};
      state.getShareHoldersAndTriggerEventsList.loading = false;
    },
    getShareHoldersAndTriggerEventsListError: (
      state: INITIALSTATEINTERFACE,
      action: PayloadAction<any>,
    ) => {
      state.getShareHoldersAndTriggerEventsList.data = {};
      state.getShareHoldersAndTriggerEventsList.error = action.payload;
      state.getShareHoldersAndTriggerEventsList.loading = false;
    },
    resetGetShareHoldersAndTriggerEventsListState: (state: INITIALSTATEINTERFACE) => ({
      ...state,
      getShareHoldersAndTriggerEventsList: initialState.getShareHoldersAndTriggerEventsList,
    }),
  },
});

export const {
  getTriggerEventsStart,
  getTriggerEventsSuccess,
  getTriggerEventsError,
  resetgetTriggerEventsState,
  putTriggerEventsStart,
  putTriggerEventsSuccess,
  putTriggerEventsError,
  resetputTriggerEventsState,
  postTriggerEventsStart,
  postTriggerEventsSuccess,
  postTriggerEventsError,
  resetPostTriggerEventsState,
  getShareHoldersAndTriggerEventsListStart,
  getShareHoldersAndTriggerEventsListSuccess,
  getShareHoldersAndTriggerEventsListError,
  resetGetShareHoldersAndTriggerEventsListState,
} = triggerEventsSlice.actions;

// export const valuationContributionReviewState = (state: RootState) =>
//   state.valuationContributionReview;
export const triggerEventsState = (state: RootState) => state.triggerEvents;

export default triggerEventsSlice.reducer;
