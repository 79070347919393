import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_SHAREHOLDER_LIST = "GET_SHAREHOLDER_LIST";
export const GET_LIFE_INSURANCE_UPLOADED_LIST = "GET_LIFE_INSURANCE_UPLOADED_LIST";
export const GET_LIFE_INSURANCE_PENDING_ID = "GET_LIFE_INSURANCE_PENDING_ID";
export const SAVE_LIFE_INSURANCE_PENDING_PROPOSALS = "SAVE_LIFE_INSURANCE_PENDING_PROPOSALS";
export const GENERATE_INVOICE_LIFE_INSURANCE_PENDING_PROPOSALS =
  "GENERATE_INVOICE_LIFE_INSURANCE_PENDING_PROPOSALS";
export const ADD_NEW_SHAREHOLDER_PENDING = "ADD_NEW_SHAREHOLDER_PENDING";

interface ResponseType {
  data?: any;
  error?: any;
  loading?: boolean;
}

interface ProposedPolicyLedger {
  fetchShareholderListStart: ResponseType;
  fetchShareholderListSuccess: ResponseType;
  fetchShareholderListFailure: ResponseType;
  fetchInsuranceListStart: ResponseType;
  fetchInsuranceListSuccess: ResponseType;
  fetchInsuranceListFailure: ResponseType;
  postforPendingIdStart: ResponseType;
  postforPendingIdSuccess: ResponseType;
  postforPendingIdFailure: ResponseType;
  postPendingDataStart: ResponseType;
  postPendingDataSuccess: ResponseType;
  postPendingDataFailure: ResponseType;
  postGenerateInvoiceStart: ResponseType;
  postGenerateInvoiceSuccess: any;
  postGenerateInvoiceFailure: ResponseType;
  postNewShareholerStart: ResponseType;
  postNewShareholerSuccess: ResponseType;
  postNewShareholerFailure: ResponseType;
}

const initialState: ProposedPolicyLedger = {
  fetchShareholderListStart: { data: {}, error: {}, loading: false },
  fetchShareholderListSuccess: { data: {}, loading: false },
  fetchShareholderListFailure: { error: {}, loading: false },
  fetchInsuranceListStart: { data: {}, error: {}, loading: false },
  fetchInsuranceListSuccess: { data: {}, loading: false },
  fetchInsuranceListFailure: { error: {}, loading: false },
  postforPendingIdStart: { data: {}, error: {}, loading: false },
  postforPendingIdSuccess: { data: {}, loading: false },
  postforPendingIdFailure: { error: {}, loading: false },
  postPendingDataStart: { data: {}, error: {}, loading: false },
  postPendingDataSuccess: { data: {}, loading: false },
  postPendingDataFailure: { error: {}, loading: false },
  postGenerateInvoiceStart: { data: {}, error: {}, loading: false },
  postGenerateInvoiceSuccess: { data: {}, loading: false },
  postGenerateInvoiceFailure: { error: {}, loading: false },
  postNewShareholerStart: { data: {}, error: {}, loading: false },
  postNewShareholerSuccess: { data: {}, loading: false },
  postNewShareholerFailure: { error: {}, loading: false },
};

export const lifeInsuranceUnderwritingSlice = createSlice({
  name: "lifeInsuranceUnderwriting",
  initialState,
  reducers: {
    fetchShareholderListStart: (state) => {
      state.fetchShareholderListStart = { data: {}, error: {}, loading: true };
    },
    fetchShareholderListSuccess: (state, action: PayloadAction<any>) => {
      state.fetchShareholderListSuccess = { data: action.payload, loading: false };
    },
    fetchShareholderListFailure: (state, action: PayloadAction<any>) => {
      state.fetchShareholderListFailure = { error: action.payload, loading: false };
    },
    fetchInsuranceListStart: (state) => {
      state.fetchInsuranceListStart = { data: {}, error: {}, loading: true };
    },
    fetchInsuranceListSuccess: (state, action: PayloadAction<any>) => {
      state.fetchInsuranceListSuccess = { data: action.payload, loading: false };
    },
    fetchInsuranceListFailure: (state, action: PayloadAction<any>) => {
      state.fetchInsuranceListFailure = { error: action.payload, loading: false };
    },
    postforPendingIdStart: (state) => {
      state.postforPendingIdStart = { data: {}, error: {}, loading: true };
    },
    postforPendingIdSuccess: (state, action: PayloadAction<any>) => {
      state.postforPendingIdSuccess = { data: action.payload, loading: false };
    },
    postforPendingIdFailure: (state, action: PayloadAction<any>) => {
      state.postforPendingIdFailure = { error: action.payload, loading: false };
    },
    postPendingDataStart: (state) => {
      state.postPendingDataStart = { data: {}, error: {}, loading: true };
    },
    postPendingDataSuccess: (state, action: PayloadAction<any>) => {
      state.postPendingDataSuccess = { data: action.payload, loading: false };
    },
    postPendingDataFailure: (state, action: PayloadAction<any>) => {
      state.postPendingDataFailure = { error: action.payload, loading: false };
    },
    postGenerateInvoiceStart: (state) => {
      state.postGenerateInvoiceStart = { data: {}, error: {}, loading: true };
    },
    postGenerateInvoiceSuccess: (state, action: PayloadAction<any>) => {
      state.postGenerateInvoiceSuccess = { data: action.payload, loading: false };
    },
    postGenerateInvoiceFailure: (state, action: PayloadAction<any>) => {
      state.postGenerateInvoiceFailure = { error: action.payload, loading: false };
    },
    postNewShareholerStart: (state) => {
      state.postNewShareholerStart = { data: {}, error: {}, loading: true };
    },
    postNewShareholerSuccess: (state, action: PayloadAction<any>) => {
      state.postNewShareholerSuccess = { data: action.payload, loading: false };
    },
    postNewShareholerFailure: (state, action: PayloadAction<any>) => {
      state.postNewShareholerFailure = { error: action.payload, loading: false };
    },
    resetLifeInsuranceCompleteState: (state) => {
      state.fetchInsuranceListStart = { data: {}, error: {}, loading: false };
      state.fetchInsuranceListSuccess = { data: {}, error: {}, loading: false };
      state.fetchInsuranceListFailure = { data: {}, error: {}, loading: false };
      state.fetchShareholderListStart = { data: {}, error: {}, loading: false };
      state.fetchShareholderListSuccess = { data: {}, error: {}, loading: false };
      state.postNewShareholerStart = { data: {}, error: {}, loading: false };
      state.postNewShareholerSuccess = { data: {}, error: {}, loading: false };
      state.postNewShareholerFailure = { data: {}, error: {}, loading: false };
    },
    resetLifeInsuranceUnderwritingPendingIdState: (state) => {
      state.postforPendingIdStart = { data: {}, error: {}, loading: false };
      state.postforPendingIdSuccess = { data: {}, error: {}, loading: false };
      state.postforPendingIdFailure = { data: {}, error: {}, loading: false };
    },
    resetUploadSetLifeunderwritingState: (state) => {
      state.postPendingDataFailure = { data: {}, error: {}, loading: false };
      state.postPendingDataStart = { data: {}, error: {}, loading: false };
      state.postPendingDataSuccess = { data: {}, error: {}, loading: false };
    },
    resetGenerateInvoiceState: (state) => {
      state.postGenerateInvoiceStart = { data: {}, error: {}, loading: false };
      state.postGenerateInvoiceSuccess = { data: {}, error: {}, loading: false };
      state.postGenerateInvoiceFailure = { data: {}, error: {}, loading: false };
    },
  },
});

export const {
  fetchShareholderListStart,
  fetchShareholderListSuccess,
  fetchShareholderListFailure,
  fetchInsuranceListStart,
  fetchInsuranceListSuccess,
  fetchInsuranceListFailure,
  postforPendingIdStart,
  postforPendingIdSuccess,
  postforPendingIdFailure,
  postPendingDataFailure,
  postPendingDataStart,
  postPendingDataSuccess,
  postGenerateInvoiceFailure,
  postGenerateInvoiceSuccess,
  postGenerateInvoiceStart,
  postNewShareholerStart,
  postNewShareholerSuccess,
  postNewShareholerFailure,
  resetLifeInsuranceCompleteState,
  resetGenerateInvoiceState,
  resetLifeInsuranceUnderwritingPendingIdState,
  resetUploadSetLifeunderwritingState,
} = lifeInsuranceUnderwritingSlice.actions;

export const lifeInsuranceUnderwritingSliceReducer = (state: RootState) =>
  state.lifeInsuranceUnderwriting;

export default lifeInsuranceUnderwritingSlice.reducer;
