import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const UPDATE_DOWNLOAD_FILE_STATUS = "UPDATE_DOWNLOAD_FILE_STATUS";

interface initialStateInterface {
  loading?: boolean;
  error?: any;
  data?: any;
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const updateDownloadFileStatusSlice = createSlice({
  name: "updateDownloadFileStatus",
  initialState,
  reducers: {
    updateDownloadFileStatusStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    updateDownloadFileStatusSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    updateDownloadFileStatusError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetupdateDownloadFileStatusState: () => initialState,
  },
});

export const {
  updateDownloadFileStatusStart,
  updateDownloadFileStatusSuccess,
  updateDownloadFileStatusError,
  resetupdateDownloadFileStatusState,
} = updateDownloadFileStatusSlice.actions;

export const updateDownloadFileStatus = (state: RootState) => state.updateDownloadFileStatus;

export default updateDownloadFileStatusSlice.reducer;
