import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_STORE_IS_AGENCY_MANAGER_ACTIVE_DATA =
  "TRIGGER_STORE_IS_AGENCY_MANAGER_ACTIVE_DATA";

interface State {
  isAgencyManagerActive: any;
}

const initialState: State = {
  isAgencyManagerActive: false,
};

export const getAllIsAgencyManagerActive = createSlice({
  name: "setIsAgencyManagerActiveData",
  initialState,
  reducers: {
    setIsAgencyManagerActiveSuccess: (state, action: PayloadAction<any[]>) => {
      state.isAgencyManagerActive = action.payload;
    },
  },
});

export const { setIsAgencyManagerActiveSuccess } = getAllIsAgencyManagerActive.actions;
export const getIsAgencyManagerActiveData = (state: RootState) =>
  state.storeIsAgencyManagerActiveData;

export const allIsAgencyManagerActiveReducer = getAllIsAgencyManagerActive.reducer;
