import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_CREATE_PROPOSAL_CONTACT_FETCH_REQUEST =
  "TRIGGER_CREATE_PROPOSAL_CONTACT_FETCH_REQUEST";
export const TRIGGER_CREATE_PROPOSAL_CONTACT_PUT_REQUEST =
  "TRIGGER_CREATE_PROPOSAL_CONTACT_PUT_REQUEST";
export const TRIGGER_CREATE_PROPOSAL_OTHER_DETAILS_FETCH_REQUEST =
  "TRIGGER_CREATE_PROPOSAL_OTHER_DETAILS_FETCH_REQUEST";
export const TRIGGER_CREATE_PROPOSAL_OTHER_DETAILS_SAVE_REQUEST =
  "TRIGGER_CREATE_PROPOSAL_OTHER_DETAILS_SAVE_REQUEST";

interface ResponseType {
  data: any;
  error?: any;
  loading?: boolean;
}

interface SuccessResponseProposalInfo {
  response: {
    basicDetails: {
      proposalType: string;
      contactName: string;
      yearlyIncome: string;
      netWorth: string;
      isSamePerson: string;
    };
    insuredDetails: {
      insurerName: string;
      dob: string;
      height: string;
      weight: string;
      medicalCondition: string;
      regularMedication: string;
      insuranceCompany: string;
      priorities: string;
      nicotine: string;
    };
    planDetails: {
      typeOfPlan: string;
      annualLoanAmt: string;
      loanFundDur: string;
      interestPayHandle: string;
      payOutOfPocket: string;
      paymentDura: string;
      minDeathBen: string;
      waiveSurCharge: string;
    };
  };
}

interface OtherDetailsTypeDefault {
  data: {
    data: {
      accrueInterest: string;
      planStartYear: string;
    };
  };
}

interface responseType {
  createProposalContactInfoFetchSucess: SuccessResponseProposalInfo;
  createProposalContactInfoFetchError: ResponseType;
  createProposalContactInformationPutSuccess: ResponseType;
  createProposalContactInformationPutError: ResponseType;
  createProposalOtherDetailsSuccess: OtherDetailsTypeDefault;
  createProposalOtherDetailsError: ResponseType;
  createProposalOtherDetailsSaveSuccess: ResponseType;
  createProposalOtherDetailsSaveError: ResponseType;
}

const initialState: any = {
  createProposalContactInfoFetchSucess: {
    response: {
      basicDetails: {
        proposalType: "",
        contactName: "",
        yearlyIncome: "",
        netWorth: "",
        isSamePerson: "",
      },
      insuredDetails: {
        insurerName: "",
        dob: "",
        height: "",
        weight: "",
        medicalCondition: "",
        regularMedication: "",
        insuranceCompany: "",
        priorities: "",
        nicotine: "",
      },
      planDetails: {
        typeOfPlan: "",
        annualLoanAmt: "",
        loanFundDur: "",
        interestPayHandle: "",
        payOutOfPocket: "",
        paymentDura: "",
        minDeathBen: "",
        waiveSurCharge: "",
      },
    },
  },
  createProposalContactInfoFetchError: { data: {}, error: {} },
  createProposalContactInformationPutSuccess: { data: {}, error: {} },
  createProposalContactInformationPutError: { data: {}, error: {} },
  createProposalOtherDetailsSuccess: {
    data: {
      data: {
        accrueInterest: "",
        planStartYear: "",
      },
    },
  },
  createProposalOtherDetailsError: { data: {}, error: {} },
  createProposalOtherDetailsSaveSuccess: { data: {}, error: {} },
  createProposalOtherDetailsSaveError: { data: {}, error: {} },
};

export const createProposalSlice = createSlice({
  name: "createProposal",
  initialState,
  reducers: {
    createProposalContactInfoFetchSucess: (state, action: PayloadAction<any>) => {
      state.createProposalContactInfoFetchSucess = { response: action.payload };
    },
    createProposalContactInfoFetchError: (state, action: PayloadAction<responseType>) => {
      state.createProposalContactInfoFetchError = { data: {}, error: action.payload };
    },
    createProposalContactInfoPutSuccess: (state, action: PayloadAction<responseType>) => {
      state.createProposalContactInformationPutSuccess = { data: action.payload, error: {} };
    },
    createProposalContactInfoPutError: (state, action: PayloadAction<responseType>) => {
      state.createProposalContactInformationPutError = { data: {}, error: action.payload };
    },
    createProposalOtherDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.createProposalOtherDetailsSuccess = { data: action.payload, error: {} };
    },
    createProposalOtherDetailsError: (state, action: PayloadAction<responseType>) => {
      state.createProposalOtherDetailsError = { data: {}, error: action.payload };
    },
    createProposalOtherDetailsSaveSuccess: (state, action: PayloadAction<any>) => {
      state.createProposalOtherDetailsSaveSuccess = { data: action.payload, error: {} };
    },
    createProposalOtherDetailsSaveError: (state, action: PayloadAction<responseType>) => {
      state.createProposalOtherDetailsSaveError = { data: {}, error: action.payload };
    },
    resetStateCreateProposal: () => initialState,
    resetStateCreateProposalErrors: (state) => {
      state.createProposalOtherDetailsSaveError = { data: {}, error: {} };
      state.createProposalContactInformationPutError = { data: {}, error: {} };
    },
  },
});

export const {
  createProposalContactInfoFetchSucess,
  createProposalContactInfoFetchError,
  createProposalContactInfoPutSuccess,
  createProposalContactInfoPutError,
  resetStateCreateProposal,
  createProposalOtherDetailsSuccess,
  createProposalOtherDetailsError,
  createProposalOtherDetailsSaveSuccess,
  createProposalOtherDetailsSaveError,
  resetStateCreateProposalErrors,
} = createProposalSlice.actions;
export const getCreateProposalContactInfo = (state: RootState) => state.creatProposalData;

export default createProposalSlice.reducer;
