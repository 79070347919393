import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const UPLOAD_ADDITIONAL_REQ_DOCUMENT = "UPLOAD_ADDITIONAL_REQ_DOCUMENT";

interface initialStateInterface {
  loading?: boolean;
  error?: any;
  data?: any;
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const uploadAdditionalReqDocumentSlice = createSlice({
  name: "uploadAdditionalDocument",
  initialState,
  reducers: {
    uploadAdditionalReqDocumentStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    uploadAdditionalReqDocumentSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    uploadAdditionalReqDocumentError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetuploadAddReqDocumentState: () => initialState,
  },
});

export const {
  uploadAdditionalReqDocumentStart,
  uploadAdditionalReqDocumentSuccess,
  uploadAdditionalReqDocumentError,
  resetuploadAddReqDocumentState,
} = uploadAdditionalReqDocumentSlice.actions;

export const uploadAdditionalReqDocument = (state: RootState) => state.uploadAdditionalReqDocument;

export default uploadAdditionalReqDocumentSlice.reducer;
