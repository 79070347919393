import {
  TRIGGER_GET_AGENT_BILLING,
  fetchAgentBillingStart,
  fetchAgentBillingSuccess,
  fetchAgentBillingFailure,
  GET_BILLING_INVOICE_PDF,
  fetchBillingInvoicePdfStart,
  fetchBillingInvoicePdfSuccess,
  fetchBillingInvoicePdfFailure,
} from "app/redux/features/agent-billing-list";
import { makeRequest } from "app/utils/makeRequest";
import { payload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* agentBillingListRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(fetchAgentBillingStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(fetchAgentBillingSuccess(response.data));
  } catch (error: any) {
    yield put(fetchAgentBillingFailure(error));
  }
}

function* agentBillingInvoicePdfRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(fetchBillingInvoicePdfStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(fetchBillingInvoicePdfSuccess(response.data));
  } catch (error: any) {
    yield put(fetchBillingInvoicePdfFailure(error));
  }
}

export default function* agentBillingSaga() {
  yield all([takeLatest(TRIGGER_GET_AGENT_BILLING, agentBillingListRequest)]);
  yield all([takeLatest(GET_BILLING_INVOICE_PDF, agentBillingInvoicePdfRequest)]);
}
