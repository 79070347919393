import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_VERIFY_TOKEN_REQUEST = "TRIGGER_VERIFY_TOKEN_REQUEST";

interface VerifyInterface {
  tokens: {
    error?: string;
    message?: string;
    access_token?: string;
    verify_token?: string;
  };
  verifyError: any;
}

const initialState: VerifyInterface = {
  tokens: {},
  verifyError: {},
};

export const verifyTokenSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    getTokens: (state, action: PayloadAction<any>) => {
      state.tokens = action.payload;
    },
    verifyError: (state, action: PayloadAction<string>) => {
      state.verifyError = action.payload;
    },
    resetLoginVerifyState: (state) => {
      state.tokens = {};
      state.verifyError = {};
    },
  },
});

export const { getTokens, verifyError, resetLoginVerifyState } = verifyTokenSlice.actions;
export const getVerifyAccessToken = (state: RootState) => state.generatedToken;

export default verifyTokenSlice.reducer;
