import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const PROPOSALS_UPLOAD_SIGNED_REPORT = "PROPOSALS_UPLOAD_SIGNED_REPORT";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const proposalsUploadSignedReportSlice = createSlice({
  name: "proposalsUploadSignedReport",
  initialState,
  reducers: {
    proposalsUploadSignedReportStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    proposalsUploadSignedReportSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    proposalsUploadSignedReportError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetProposalsUploadSignedReportState: () => initialState,
  },
});

export const {
  proposalsUploadSignedReportStart,
  proposalsUploadSignedReportSuccess,
  proposalsUploadSignedReportError,
  resetProposalsUploadSignedReportState,
} = proposalsUploadSignedReportSlice.actions;

export const proposalsUploadSignedReport = (state: RootState) => state.proposalsUploadSignedReport;
export default proposalsUploadSignedReportSlice.reducer;
