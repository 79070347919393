import {
  contactProfileLookupFailure,
  contactProfileLookupStart,
  contactProfileLookupSuccess,
  CONTACT_PROFILE_LOOKUP,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* contractProfileLookUp(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;

    yield put(contactProfileLookupStart());
    const response = yield call(() => makeRequest(path).get());
    if (response.statusCode >= 400) yield put(contactProfileLookupFailure(response));
    else {
      yield put(contactProfileLookupSuccess(response.data));
    }
  } catch (error: any) {
    yield put(contactProfileLookupFailure(error));
  }
}

function* watchContactProfileLookup() {
  yield takeLatest(CONTACT_PROFILE_LOOKUP, contractProfileLookUp);
}

export default function* watchContactProfileLookupSaga() {
  yield all([watchContactProfileLookup()]);
}
