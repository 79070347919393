import {
  setGetAllPendingAgentsFailure,
  setGetAllPendingAgentsOngoing,
  setGetAllPendingAgentsSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* getAllPendingAgents(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setGetAllPendingAgentsOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setGetAllPendingAgentsSuccess(response.data));
  } catch (e: any) {
    yield put(setGetAllPendingAgentsFailure(e.message));
  }
}

function* watchGetAllPendingAgents() {
  yield takeLatest("TRIGGER_GET_ALL_PENDING_AGENTS", getAllPendingAgents);
}

export default function* getAllPendingAgentsSaga() {
  yield all([watchGetAllPendingAgents()]);
}
