import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const REQUEST_NEW_SIGNATURE = "REQUEST_NEW_SIGNATURE";
export const APPROVE_PROPOSAL = "APPROVE_PROPOSAL";

interface initialStateInterface {
  loading?: boolean;
  error?: { status?: string; message?: string; error?: string };
  data?: { status?: string; message?: string };
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const adminPendingApprovalSlice = createSlice({
  name: "adminPendingApproval",
  initialState,
  reducers: {
    requestNewSignatureStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    requestNewSignatureSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    requestNewSignatureError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetAdminPendingApprovalState: () => initialState,
    approveProposalStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    approveProposalSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    approveProposalError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  requestNewSignatureStart,
  requestNewSignatureSuccess,
  requestNewSignatureError,
  resetAdminPendingApprovalState,
  approveProposalStart,
  approveProposalSuccess,
  approveProposalError,
} = adminPendingApprovalSlice.actions;

export const adminPendingApproval = (state: RootState) => state.adminPendingApproval;

export default adminPendingApprovalSlice.reducer;
