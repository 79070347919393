import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_AGENT_PROFILE = "GET_AGENT_PROFILE";

const initialState = {
  loading: false,
  data: {} as any,
  error: {},
};

export const agentProfileSlice = createSlice({
  name: "agentProfile",
  initialState,
  reducers: {
    getAgentProfileStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    getAgentProfileSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    getAgentProfileError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetGetAgentProfileState: () => initialState,
  },
});

export const {
  getAgentProfileStart,
  getAgentProfileSuccess,
  getAgentProfileError,
  resetGetAgentProfileState,
} = agentProfileSlice.actions;

export const agentProfileState = (state: RootState) => state.agentProfile;
export default agentProfileSlice.reducer;
