import { setSendFinancialInfoFailure, setSendFinancialInfoSuccess } from "../../redux";
import { FinancialInfoActionPayload } from "../../utils/types";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import { TRIGGER_SEND_FINANCIAL_INFO } from "app/redux/features/financial-info";

function* sendFinancialInfo(actions: FinancialInfoActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body: requestBody, requestParams, queryParams },
    } = actions;

    const response = yield call(() =>
      makeRequest(path).put(key, requestBody, requestParams, queryParams),
    );
    yield put(setSendFinancialInfoSuccess(response.data));
  } catch (error: any) {
    yield put(setSendFinancialInfoFailure(error.message));
  }
}

function* watchFinancialInfo() {
  yield takeLatest(TRIGGER_SEND_FINANCIAL_INFO, sendFinancialInfo);
}

export default function* financialInfoSaga() {
  yield all([watchFinancialInfo()]);
}
