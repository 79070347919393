import {
  TRIGGER_PUT_INSURANCE_PRODUCT,
  TRIGGER_POST_INSURANCE_PRODUCT,
  insuranceProductPutStart,
  insuranceProductPutSuccess,
  insuranceProductPutError,
  insuranceProductPostStart,
  insuranceProductPostSuccess,
  insuranceProductPostError,
} from "app/redux/features/insurance-product";
import { makeRequest } from "app/utils/makeRequest";
import { InsuranceProductActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* putInsuranceProduct(actions: InsuranceProductActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams, body },
    } = actions;
    yield put(insuranceProductPutStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, body, requestParams, queryParams),
    );
    yield put(insuranceProductPutSuccess(response.data));
  } catch (error: any) {
    yield put(insuranceProductPutError(error));
  }
}
function* postInsuranceProduct(actions: InsuranceProductActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body: requestBody, requestParams, queryParams },
    } = actions;
    yield put(insuranceProductPostStart());

    const response = yield call(() =>
      makeRequest(path).post(requestBody, key, requestParams, queryParams),
    );

    yield put(insuranceProductPostSuccess(response.data));
  } catch (error: any) {
    yield put(insuranceProductPostError(error));
  }
}

export default function* insuranceProductSaga() {
  yield all([takeLatest(TRIGGER_PUT_INSURANCE_PRODUCT, putInsuranceProduct)]);
  yield all([takeLatest(TRIGGER_POST_INSURANCE_PRODUCT, postInsuranceProduct)]);
}
