import {
  sendFileToContactStart,
  sendFileToContactSuccess,
  sendFileToContactError,
  SEND_FILE_TO_CONTACT,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* sendFileToContactRequest(actions: FinancialInfoActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body, requestParams },
    } = actions;

    yield put(sendFileToContactStart());
    const response = yield call(() => makeRequest(path).put(key, body, requestParams));
    yield put(sendFileToContactSuccess(response.data));
  } catch (error: any) {
    yield put(sendFileToContactError(error));
  }
}
export default function* sendFileToContactSaga() {
  yield all([takeLatest(SEND_FILE_TO_CONTACT, sendFileToContactRequest)]);
}
