import {
  getValuationContributionReviewStart,
  getValuationContributionReviewSuccess,
  getValuationContributionReviewError,
  putValuationContributionReviewStart,
  putValuationContributionReviewSuccess,
  putValuationContributionReviewError,
  GET_VALUATION_CONTRIBUTION_REVIEW,
  PUT_VALUATION_CONTRIBUTION_REVIEW,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* valuationContributionReviewRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(getValuationContributionReviewStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(getValuationContributionReviewSuccess(response.data));
  } catch (error: any) {
    yield put(getValuationContributionReviewError(error));
  }
}

function* putViewPlanDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(putValuationContributionReviewStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(putValuationContributionReviewSuccess(response.data));
  } catch (error: any) {
    yield put(putValuationContributionReviewError(error));
  }
}

function* watchValuationContributionReviewSaga() {
  yield takeLatest(GET_VALUATION_CONTRIBUTION_REVIEW, valuationContributionReviewRequest);
  yield takeLatest(PUT_VALUATION_CONTRIBUTION_REVIEW, putViewPlanDetailsRequest);
}

export default function* valuationContributionReviewSaga() {
  yield all([watchValuationContributionReviewSaga()]);
}
