import {
  GET_AGENCY_AGENT_COUNT,
  GET_AGENTS_LIST,
  agencyAgentsCountsError,
  agencyAgentsCountsStart,
  agencyAgentsCountsSuccess,
  agentListingError,
  agentListingStart,
  agentListingSuccess,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* agentsListingRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(agentListingStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));

    yield put(agentListingSuccess(response.data));
  } catch (error: any) {
    yield put(agentListingError(error));
  }
}
function* agencyAgentsCountRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(agencyAgentsCountsStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));

    yield put(agencyAgentsCountsSuccess(response.data));
  } catch (error: any) {
    yield put(agencyAgentsCountsError(error));
  }
}

function* watchAgentListsSagas() {
  yield takeLatest(GET_AGENTS_LIST, agentsListingRequest);
  yield takeLatest(GET_AGENCY_AGENT_COUNT, agencyAgentsCountRequest);
}

export default function* agentListingSaga() {
  yield all([watchAgentListsSagas()]);
}
