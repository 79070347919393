import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_GET_AGENT_BILLING = "TRIGGER_GET_AGENT_BILLING";
export const GET_BILLING_INVOICE_PDF = "GET_BILLING_INVOICE_PDF";

interface AgentBillingInterface {
  data: any;
  invoiceData: any;
  loading: boolean;
  error: Record<string, string | number | object> | null;
}

const initialState: AgentBillingInterface = {
  data: [],
  invoiceData: [],
  loading: false,
  error: {},
};

export const getAgentBillingSlice = createSlice({
  name: "getAgentBilling",
  initialState,
  reducers: {
    fetchAgentBillingStart: (state) => {
      state.loading = true;
      state.error = {};
    },
    fetchAgentBillingSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchAgentBillingFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchBillingInvoicePdfStart: (state) => {
      state.loading = true;
      state.error = {};
    },
    fetchBillingInvoicePdfSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.invoiceData = action.payload;
    },
    fetchBillingInvoicePdfFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchAgentBillingStart,
  fetchAgentBillingSuccess,
  fetchAgentBillingFailure,
  fetchBillingInvoicePdfStart,
  fetchBillingInvoicePdfSuccess,
  fetchBillingInvoicePdfFailure,
} = getAgentBillingSlice.actions;

export const getAgentBillingSliceList = (state: RootState) => state.agentBillingSliceList;

export default getAgentBillingSlice.reducer;
