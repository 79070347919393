import { Navigate } from "react-router-dom";
import { getObjectFromLocalStore } from "../app/utils/localStore";
import { isEmpty } from "ramda";
// NOTE call this auth from redux store

const PrivateRoute = (props: { children: JSX.Element }) => {
  const { children } = props;
  const userAccess = getObjectFromLocalStore("useraccess");
  if (isEmpty(userAccess)) {
    // NOTE not logged in so redirect to login page with the return url
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateRoute;
