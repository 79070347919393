import { TRIGGER_VERIFY_TOKEN_REQUEST } from "@reducers";

export interface VerifyTokenRequestBody {
  email: string;
  password: string;
  verifyToken: string;
}

// Action creators
const TriggerVerifyTokenRequest = (path: string, requestBody: VerifyTokenRequestBody) => ({
  type: TRIGGER_VERIFY_TOKEN_REQUEST,
  payload: {
    address: path,
    body: requestBody,
  },
});

export default TriggerVerifyTokenRequest;
