import {
  TRIGGER_PLANS_LIST_REQUEST,
  TRIGGER_PLAN_CHANGE_REQUEST,
  TRIGGER_PAYMENT_STATUS_UPDATE_REQUEST,
} from "@reducers";
import { PaymentUpdateStatusRequestBody, PlanChangeRequestBody } from "app/utils/types";

// Action creators
const triggerPlansListRequest = (path: string) => ({
  type: TRIGGER_PLANS_LIST_REQUEST,
  payload: {
    address: path,
  },
});

export const triggerPlanChangeRequest = (path: string, requestBody: PlanChangeRequestBody) => ({
  type: TRIGGER_PLAN_CHANGE_REQUEST,
  payload: {
    address: path,
    body: requestBody,
  },
});

export const triggerUpdatePaymentStatus = (
  path: string,
  requestBody: PaymentUpdateStatusRequestBody,
) => ({
  type: TRIGGER_PAYMENT_STATUS_UPDATE_REQUEST,
  payload: {
    address: path,
    body: requestBody,
  },
});

export default triggerPlansListRequest;
