import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const SET_LOAN_AFR = "SET_LOAN_AFR";

interface initialStateInterface {
  loading?: boolean;
  error?: { status?: string; message?: string; error?: string };
  data?: { status?: string; message?: string };
}

const initialState: initialStateInterface = {
  loading: false,
  data: {},
  error: {},
};

const setLoanAFRSlice = createSlice({
  name: "setLoanAFR",
  initialState,
  reducers: {
    setLoanAFRStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    setLoanAFRSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    setLoanAFRError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetSetLoanAFRState: () => initialState,
  },
});

export const { setLoanAFRStart, setLoanAFRSuccess, setLoanAFRError, resetSetLoanAFRState } =
  setLoanAFRSlice.actions;

export const setLoanAFR = (state: RootState) => state.setLoanAFR;

export default setLoanAFRSlice.reducer;
