import {
  getCommissionPendingApprovalStart,
  getCommissionPendingApprovalSuccess,
  getCommissionPendingApprovalError,
  GET_COMMISSION_PENDING_APPROVAL,
} from "@reducers";
import { makeRequest } from "app/utils/makeRequest";
import { call, put, takeLatest } from "redux-saga/effects";

function* getCommissionPendingApproval(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(getCommissionPendingApprovalStart());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(getCommissionPendingApprovalSuccess(response.data));
  } catch (error: any) {
    yield put(getCommissionPendingApprovalError(error));
  }
}

export default function* watchCommissionPendingApprovalSaga() {
  yield takeLatest(GET_COMMISSION_PENDING_APPROVAL, getCommissionPendingApproval);
}
