import {
  TRIGGER_AGENT_CONTACT_REQUEST,
  TRIGGER_AGENT_PHONENUMBER_VERIFY_OTP,
  TRIGGER_AGENT_PHONENUMBER_SUBMIT_OTP,
  TRIGGER_AGENT_PERSONAL_DETAILS_SAVE,
  TRIGGER_AGENT_PROFILE_PICTURE_UPLOAD,
  TRIGGER_AGENT_PERSONAL_DETAILS_GET,
  TRIGGER_AGENT_PROFILE_PICTURE_DELETE,
  TRIGGER_AGENT_BLOCK_REQUEST,
  TRIGGER_AGENT_CONTACT_EMAIL_VERIFY,
  agentContactBasicInfoEditError,
  agentContactBasicInfoEditSuccess,
  agentContactPhoneOtpSuccess,
  agentContactPhoneOtpEditError,
  agentContactProfilePictureUploadSuccess,
  agentContactProfilePictureUploadError,
  agentContactOTPSubmitSuccess,
  agentContactOtpSubmitError,
  agentContactSuccess,
  contactBlockSuccess,
  contactBlockError,
  agentContactError,
  agentContactProfileInfoError,
  agentContactProfileInfoSucess,
  agentContactProfilePictureDeleteSucess,
  agentContactProfilePictureDeleteError,
  agentContactEmailOtpSuccess,
  agentContactEmailOtpEditError,
} from "@reducers";
import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";
import {
  TRIGGER_AGENT_CONTACT_EMAIL_SUBMIT_OTP,
  TRIGGER_USER_PROFILE_REQUEST,
  agentContactEmailOTPSubmitSuccess,
  agentContactEmailOtpSubmitError,
} from "app/redux/features/contactById";

interface VerifyTokenActionPayload {
  type: string;
  payload: {
    address: string;
    key: string;
    requestParams: boolean;
    queryParams: boolean;
  };
}

function* agentContactRequest(actions: VerifyTokenActionPayload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, queryParams, key, requestParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(agentContactSuccess(response.data));
  } catch (error: any) {
    yield put(agentContactError(error));
  }
}

function* agentContactRequestWithFeature(
  actions: VerifyTokenActionPayload,
): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, queryParams, requestParams));
    yield put(agentContactProfileInfoSucess(response.data));
  } catch (error: any) {
    yield put(agentContactProfileInfoError(error));
  }
}

function* agentContactBlockRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(contactBlockSuccess(response.data));
  } catch (error: any) {
    yield put(contactBlockError(error));
  }
}

function* userProfileRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path },
    } = actions;
    const response = yield call(() => makeRequest(path).get());
    yield put(agentContactSuccess(response.data));
  } catch (error: any) {
    yield put(agentContactError(error));
  }
}

function* agentContactPersonalInfoRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key, requestParam },
    } = actions;
    const response = yield call(() => makeRequest(path).put(key, requestBody, requestParam));
    yield put(agentContactBasicInfoEditSuccess(response.data));
  } catch (error: any) {
    yield put(agentContactBasicInfoEditError(error));
  }
}

function* agentContactOtpSubmitRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key: id, requestParam },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody, id, requestParam));
    yield put(agentContactOTPSubmitSuccess(response.data));
  } catch (error: any) {
    yield put(agentContactOtpSubmitError(error));
  }
}

function* agentContactPhoneOtpRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key: id, requestParam },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody, id, requestParam));
    yield put(agentContactPhoneOtpSuccess(response.data));
  } catch (error: any) {
    yield put(agentContactPhoneOtpEditError(error));
  }
}

function* agentContactEmailOtpRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key: id, requestParam },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody, id, requestParam));
    yield put(agentContactEmailOtpSuccess(response.data));
  } catch (error: any) {
    yield put(agentContactEmailOtpEditError(error));
  }
}

function* agentContactEmailOtpSubmitRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key: id, requestParam },
    } = actions;
    const response = yield call(() => makeRequest(path).post(requestBody, id, requestParam));
    yield put(agentContactEmailOTPSubmitSuccess(response.data));
  } catch (error: any) {
    yield put(agentContactEmailOtpSubmitError(error));
  }
}

function* agentContactProfilePictureUploadRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body: requestBody, key, requestParam },
    } = actions;
    const response = yield call(() =>
      makeRequest(path, { "Content-Type": "multipart/form-data" }).post(
        requestBody,
        key,
        requestParam,
      ),
    );
    yield put(agentContactProfilePictureUploadSuccess(response.data));
  } catch (error: any) {
    yield put(agentContactProfilePictureUploadError(error));
  }
}

function* agentContactProfilePictureDeleteRequest(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParam },
    } = actions;
    const response = yield call(() => makeRequest(path).delete(key, requestParam));
    yield put(agentContactProfilePictureDeleteSucess(response.data));
  } catch (error: any) {
    yield put(agentContactProfilePictureDeleteError(error));
  }
}

export default function* agentContactSaga() {
  yield all([
    takeLatest(TRIGGER_AGENT_CONTACT_REQUEST, agentContactRequest),
    takeLatest(TRIGGER_AGENT_PERSONAL_DETAILS_GET, agentContactRequestWithFeature),
    takeLatest(TRIGGER_AGENT_BLOCK_REQUEST, agentContactBlockRequest),
    takeLatest(TRIGGER_USER_PROFILE_REQUEST, userProfileRequest),
    takeLatest(TRIGGER_AGENT_PHONENUMBER_VERIFY_OTP, agentContactPhoneOtpRequest),
    takeLatest(TRIGGER_AGENT_PHONENUMBER_SUBMIT_OTP, agentContactOtpSubmitRequest),
    takeLatest(TRIGGER_AGENT_PERSONAL_DETAILS_SAVE, agentContactPersonalInfoRequest),
    takeLatest(TRIGGER_AGENT_PROFILE_PICTURE_UPLOAD, agentContactProfilePictureUploadRequest),
    takeLatest(TRIGGER_AGENT_PROFILE_PICTURE_DELETE, agentContactProfilePictureDeleteRequest),
    takeLatest(TRIGGER_AGENT_CONTACT_EMAIL_VERIFY, agentContactEmailOtpRequest),
    takeLatest(TRIGGER_AGENT_CONTACT_EMAIL_SUBMIT_OTP, agentContactEmailOtpSubmitRequest),
  ]);
}
