import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const GET_CONTACT_LIST = "GET_CONTACT_LIST";

interface InitialStateInterface {
  data?: any;
  loading?: boolean;
  error?: any;
}

interface contactsResponseType {
  fetchContactList?: InitialStateInterface;
}

const initialState: contactsResponseType = {
  fetchContactList: { data: {}, error: {}, loading: false },
};

export const contactsSlice = createSlice({
  name: "contactList",
  initialState,
  reducers: {
    fetchContactListLoading: (state) => {
      state.fetchContactList = { data: {}, error: {}, loading: true };
    },
    fetchContactListSuccess: (state, action: PayloadAction<any>) => {
      state.fetchContactList = { data: action.payload.data, error: {}, loading: false };
    },
    fetchContactListError: (state, action: PayloadAction<any>) => {
      state.fetchContactList = { data: {}, error: action.payload, loading: false };
    },
  },
});

export const { fetchContactListLoading, fetchContactListSuccess, fetchContactListError } =
  contactsSlice.actions;
export const getContacts = (state: RootState) => state.contacts;

export default contactsSlice.reducer;
