import {
  getPlanReviewDetailsStart,
  getPlanReviewDetailsSuccess,
  getPlanReviewDetailsError,
  putPlanReviewDetailsStart,
  putPlanReviewDetailsSuccess,
  putPlanReviewDetailsError,
  PUT_PLAN_REVIEW_DETAILS,
  GET_PLAN_REVIEW_DETAILS,
} from "app/redux";

import { makeRequest } from "app/utils/makeRequest";

import { all, call, put, takeLatest } from "redux-saga/effects";

function* getViewPlanDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(getPlanReviewDetailsStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(getPlanReviewDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(getPlanReviewDetailsError(error));
  }
}

function* putViewPlanDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(putPlanReviewDetailsStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(putPlanReviewDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(putPlanReviewDetailsError(error));
  }
}
function* watchViewPlanDetailsSagas() {
  yield takeLatest(GET_PLAN_REVIEW_DETAILS, getViewPlanDetailsRequest);
  yield takeLatest(PUT_PLAN_REVIEW_DETAILS, putViewPlanDetailsRequest);
}

export default function* viewPlanDetailsSagas() {
  yield all([watchViewPlanDetailsSagas()]);
}
