import {
  updateDownloadFileStatusStart,
  updateDownloadFileStatusSuccess,
  updateDownloadFileStatusError,
  UPDATE_DOWNLOAD_FILE_STATUS,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { FinancialInfoActionPayload } from "app/utils/types";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* updateDownloadFileStatusRequest(
  actions: FinancialInfoActionPayload,
): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, body, requestParams },
    } = actions;
    yield put(updateDownloadFileStatusStart());
    const response = yield call(() => makeRequest(path).put(key, body, requestParams));
    yield put(updateDownloadFileStatusSuccess(response.data));
  } catch (error: any) {
    yield put(updateDownloadFileStatusError(error));
  }
}
export default function* updateDownloadFileStatusSaga() {
  yield all([takeLatest(UPDATE_DOWNLOAD_FILE_STATUS, updateDownloadFileStatusRequest)]);
}
