import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_VALUATION_CONTRIBUTION_REVIEW = "GET_VALUATION_CONTRIBUTION_REVIEW";
export const PUT_VALUATION_CONTRIBUTION_REVIEW = "PUT_VALUATION_CONTRIBUTION_REVIEW";

interface BASESTATEINTERFACE {
  loading: boolean;
  data: any;
  error: any;
}
interface INITIALSTATEINTERFACE {
  getValuationContributionReview: BASESTATEINTERFACE;
  putValuationContributionReview: BASESTATEINTERFACE;
}

const initialState = {
  getValuationContributionReview: { loading: false, data: {} as any, error: {} as any },
  putValuationContributionReview: { loading: false, data: {} as any, error: {} as any },
};

export const valuationContributionReview = createSlice({
  name: "PlanReviewDetails",
  initialState,
  reducers: {
    getValuationContributionReviewStart: (state: INITIALSTATEINTERFACE) => {
      state.getValuationContributionReview.data = {};
      state.getValuationContributionReview.error = {};
      state.getValuationContributionReview.loading = true;
    },
    getValuationContributionReviewSuccess: (
      state: INITIALSTATEINTERFACE,
      action: PayloadAction<any>,
    ) => {
      state.getValuationContributionReview.data = action.payload;
      state.getValuationContributionReview.error = {};
      state.getValuationContributionReview.loading = false;
    },
    getValuationContributionReviewError: (
      state: INITIALSTATEINTERFACE,
      action: PayloadAction<any>,
    ) => {
      state.getValuationContributionReview.data = {};
      state.getValuationContributionReview.error = action.payload;
      state.getValuationContributionReview.loading = false;
    },
    resetGetValuationContributionReviewState: (state: INITIALSTATEINTERFACE) => ({
      ...state,
      getPlanReviewDetails: initialState.getValuationContributionReview,
    }),

    putValuationContributionReviewStart: (state: INITIALSTATEINTERFACE) => {
      state.putValuationContributionReview.data = {};
      state.putValuationContributionReview.error = {};
      state.putValuationContributionReview.loading = true;
    },
    putValuationContributionReviewSuccess: (
      state: INITIALSTATEINTERFACE,
      action: PayloadAction<any>,
    ) => {
      state.putValuationContributionReview.data = action.payload;
      state.putValuationContributionReview.error = {};
      state.putValuationContributionReview.loading = false;
    },
    putValuationContributionReviewError: (
      state: INITIALSTATEINTERFACE,
      action: PayloadAction<any>,
    ) => {
      state.putValuationContributionReview.data = {};
      state.putValuationContributionReview.error = action.payload;
      state.putValuationContributionReview.loading = false;
    },
    resetPutValuationContributionReviewState: (state: INITIALSTATEINTERFACE) => ({
      ...state,
      putValuationContributionReview: initialState.putValuationContributionReview,
    }),
  },
});

export const {
  getValuationContributionReviewStart,
  getValuationContributionReviewSuccess,
  getValuationContributionReviewError,
  resetGetValuationContributionReviewState,
  putValuationContributionReviewStart,
  putValuationContributionReviewSuccess,
  putValuationContributionReviewError,
  resetPutValuationContributionReviewState,
} = valuationContributionReview.actions;

export const valuationContributionReviewState = (state: RootState) =>
  state.valuationContributionReview;

export default valuationContributionReview.reducer;
