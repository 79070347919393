import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const PROPOSAL_ACTION_REVIEW = "PROPOSAL_ACTION_REVIEW";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const proposalActionReviewSlice = createSlice({
  name: "proposalActionReview",
  initialState,
  reducers: {
    proposalActionReviewStart: (state) => {
      state.data = {};
      state.error = {};
      state.loading = true;
    },
    proposalActionReviewSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    proposalActionReviewError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    resetProposalActionReviewState: () => initialState,
  },
});

export const {
  proposalActionReviewStart,
  proposalActionReviewSuccess,
  proposalActionReviewError,
  resetProposalActionReviewState,
} = proposalActionReviewSlice.actions;

export const proposalActionReview = (state: RootState) => state.proposalActionReview;
export default proposalActionReviewSlice.reducer;
