import { takeLatest, all, call, put } from "redux-saga/effects";
import { makeRequest } from "../../utils/makeRequest";

import { payload } from "app/utils/types";
import {
  TRIGGER_GET_PROVISION_DETAILS,
  TRIGGER_SAVE_PROVISION_DETAILS,
  getProvisionError,
  getProvisionOngoing,
  getProvisionSuccess,
  saveProvisionError,
  saveProvisionOngoing,
  saveProvisionSuccess,
} from "app/redux";

function* getProvisionRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(getProvisionOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(getProvisionSuccess(response.data));
  } catch (error: any) {
    yield put(getProvisionError(error));
  }
}

function* saveProvisionRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, body },
    } = actions;
    yield put(saveProvisionOngoing());
    const response = yield call(() => makeRequest(path).put("", body));
    yield put(saveProvisionSuccess(response.data));
  } catch (error: any) {
    yield put(saveProvisionError(error));
  }
}

function* watchProvisionRequest() {
  yield takeLatest(TRIGGER_GET_PROVISION_DETAILS, getProvisionRequest);
  yield takeLatest(TRIGGER_SAVE_PROVISION_DETAILS, saveProvisionRequest);
}

export default function* provisionSaga() {
  yield all([watchProvisionRequest()]);
}
