import {
  setFetchAgentsOngoing,
  setFetchAgentsSuccess,
  setFetchUsersFailure,
  setFetchUsersOngoing,
  setFetchUsersSuccess,
} from "app/redux";
import { setDelAgentError, setDelAgentOngoing, setDelAgentSuccess } from "app/redux/features/users";
import { TRIGGER_DELETE_AGENTS } from "app/redux/features/users/action";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";

function* fetchAdmins(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setFetchUsersOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setFetchUsersSuccess(response.data));
  } catch (error: any) {
    yield put(setFetchUsersFailure(error));
  }
}

function* fetchAgents(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setFetchAgentsOngoing());
    const response = yield call(() => makeRequest(path).get(key, requestParams, queryParams));
    yield put(setFetchAgentsSuccess(response.data));
  } catch (error: any) {
    yield put(setFetchUsersFailure(error));
  }
}

function* deleteAgents(actions: any): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key, requestParams, queryParams },
    } = actions;
    yield put(setDelAgentOngoing());
    const response = yield call(() => makeRequest(path).delete(key, requestParams, queryParams));
    yield put(setDelAgentSuccess(response.data));
  } catch (error: any) {
    yield put(setDelAgentError(error));
  }
}

function* watchFetchUsers() {
  yield takeLatest("TRIGGER_FETCH_ADMINS", fetchAdmins);
  yield takeLatest("TRIGGER_FETCH_AGENTS", fetchAgents);
  yield takeLatest(TRIGGER_DELETE_AGENTS, deleteAgents);
}

export default function* fetchUsersSaga() {
  yield all([watchFetchUsers()]);
}
