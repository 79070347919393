import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_GET_AGENCY_COMMISSION = "TRIGGER_GET_AGENCY_COMMISSION";
export const TRIGGER_APPROVE_AGENCY_COMMISSION = "TRIGGER_APPROVE_AGENCY_COMMISSION";
export const TRIGGER_GET_AGENCY_COMMISSION_USER = "TRIGGER_GET_AGENCY_COMMISSION_USER";
export const TRIGGER_DELETE_AGENCY_COMMISSION = "TRIGGER_DELETE_AGENCY_COMMISSION";
export const TRIGGER_POST_AGENCY_COMMISSION = "TRIGGER_POST_AGENCY_COMMISSION";

interface agencyCommissionInterface {
  agencyCommissionData: any;
  agencyCommissionLoading: boolean;
  agencyCommissionError: any;
  agencyApproveCommissionData: any;
  agencyApproveCommissionError: any;
  agencyApproveCommissionLoading: boolean;
  agencyCommissionUserData: any;
  agencyCommissionUserError: any;
  agencyCommissionUserLoading: boolean;
  agencyDeleteCommissionData: any;
  agencyDeleteCommissionError: any;
  agencyDeleteCommissionLoading: boolean;
  agencyPostCommissionData: any;
  agencyPostCommissionError: any;
  agencyPostCommissionLoading: boolean;
}

const initialState: agencyCommissionInterface = {
  agencyCommissionData: {},
  agencyCommissionLoading: false,
  agencyCommissionError: "",
  agencyApproveCommissionData: {},
  agencyApproveCommissionError: "",
  agencyApproveCommissionLoading: false,
  agencyCommissionUserData: {},
  agencyCommissionUserError: "",
  agencyCommissionUserLoading: false,
  agencyDeleteCommissionData: {},
  agencyDeleteCommissionError: "",
  agencyDeleteCommissionLoading: false,
  agencyPostCommissionData: {},
  agencyPostCommissionError: "",
  agencyPostCommissionLoading: false,
};

export const agencyCommissionSlice = createSlice({
  name: "agencyCommission",
  initialState,
  reducers: {
    agencyCommissionSuccess: (state, action: PayloadAction<any[]>) => {
      state.agencyCommissionData = action.payload;
      state.agencyCommissionError = "";
      state.agencyCommissionLoading = false;
    },
    agencyCommissionError: (state, action: PayloadAction<any[]>) => {
      state.agencyCommissionData = {};
      state.agencyCommissionError = action.payload;
      state.agencyCommissionLoading = false;
    },
    agencyCommissionLoading: (state) => {
      state.agencyCommissionData = {};
      state.agencyCommissionError = {};
      state.agencyCommissionLoading = true;
    },
    agencyApproveCommissionSuccess: (state, action: PayloadAction<any[]>) => {
      state.agencyApproveCommissionData = action.payload;
      state.agencyApproveCommissionError = {};
      state.agencyApproveCommissionLoading = false;
    },
    agencyApproveCommissionError: (state, action: PayloadAction<any[]>) => {
      state.agencyApproveCommissionData = {};
      state.agencyApproveCommissionError = action.payload;
      state.agencyApproveCommissionLoading = false;
    },
    agencyApproveCommissionLoading: (state) => {
      state.agencyApproveCommissionData = {};
      state.agencyApproveCommissionError = {};
      state.agencyApproveCommissionLoading = true;
    },
    agencyPostCommissionSuccess: (state, action: PayloadAction<any[]>) => {
      state.agencyPostCommissionData = action.payload;
      state.agencyPostCommissionError = {};
      state.agencyPostCommissionLoading = false;
    },
    agencyPostCommissionError: (state, action: PayloadAction<any[]>) => {
      state.agencyPostCommissionData = {};
      state.agencyPostCommissionError = action.payload;
      state.agencyPostCommissionLoading = false;
    },
    agencyPostCommissionLoading: (state) => {
      state.agencyPostCommissionData = {};
      state.agencyPostCommissionError = {};
      state.agencyPostCommissionLoading = true;
    },
    agencyCommissionUserSuccess: (state, action: PayloadAction<any>) => {
      state.agencyCommissionUserData = action.payload;
      state.agencyCommissionUserError = {};
      state.agencyCommissionUserLoading = false;
    },
    agencyCommissionUserError: (state, action: PayloadAction<any[]>) => {
      state.agencyCommissionUserData = {};
      state.agencyCommissionUserError = action.payload;
      state.agencyCommissionUserLoading = false;
    },
    agencyCommissionUserLoading: (state) => {
      state.agencyCommissionUserData = {};
      state.agencyCommissionUserError = {};
      state.agencyCommissionUserLoading = true;
    },
    agencyDeleteCommissionSuccess: (state, action: PayloadAction<any[]>) => {
      state.agencyDeleteCommissionData = action.payload;
      state.agencyDeleteCommissionError = {};
      state.agencyDeleteCommissionLoading = false;
    },
    agencyDeleteCommissionError: (state, action: PayloadAction<any[]>) => {
      state.agencyDeleteCommissionData = {};
      state.agencyDeleteCommissionError = action.payload;
      state.agencyDeleteCommissionLoading = false;
    },
    agencyDeleteCommissionLoading: (state) => {
      state.agencyDeleteCommissionData = {};
      state.agencyDeleteCommissionError = {};
      state.agencyDeleteCommissionLoading = true;
    },
    resetAgencyCommission: (state) => {
      state.agencyApproveCommissionData = {};
      state.agencyApproveCommissionError = "";
      state.agencyApproveCommissionLoading = false;
      state.agencyCommissionUserData = {};
      state.agencyCommissionUserError = "";
      state.agencyCommissionUserLoading = false;
      state.agencyDeleteCommissionData = {};
      state.agencyDeleteCommissionError = "";
      state.agencyDeleteCommissionLoading = false;
      state.agencyPostCommissionData = {};
      state.agencyPostCommissionError = "";
      state.agencyPostCommissionLoading = false;
    },
  },
});

export const {
  agencyCommissionSuccess,
  agencyCommissionError,
  agencyCommissionLoading,
  agencyApproveCommissionSuccess,
  agencyApproveCommissionError,
  agencyApproveCommissionLoading,
  agencyCommissionUserError,
  agencyCommissionUserSuccess,
  agencyCommissionUserLoading,
  resetAgencyCommission,
  agencyDeleteCommissionError,
  agencyDeleteCommissionLoading,
  agencyDeleteCommissionSuccess,
  agencyPostCommissionError,
  agencyPostCommissionLoading,
  agencyPostCommissionSuccess,
} = agencyCommissionSlice.actions;
export const getAgencyCommissionState = (state: RootState) => state.agencyCommission;

export default agencyCommissionSlice.reducer;
