import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@store";

export const TRIGGER_STORE_IS_FORM_ACTIVE_DATA = "TRIGGER_STORE_IS_FORM_ACTIVE_DATA";

interface State {
  isFormActive: any;
}

const initialState: State = {
  isFormActive: false,
};

export const getAllIsFormActive = createSlice({
  name: "setIsFormActiveData",
  initialState,
  reducers: {
    setIsFormActiveSuccess: (state, action: PayloadAction<any[]>) => {
      state.isFormActive = action.payload;
    },
  },
});

export const { setIsFormActiveSuccess } = getAllIsFormActive.actions;
export const getIsFormActiveData = (state: RootState) => state.storeIsFormActiveData;

export const allIsFormActiveReducer = getAllIsFormActive.reducer;
